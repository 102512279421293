import { Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../InstanceAxios/InstanceAxios';
import SpecificCustomerOrder from '../SpecificCustomerOrder/SpecificCustomerOrder';

const CustomerDetail = () => {
    const { cuid } = useParams()
    const [customers, setCustomers] = useState({})


    useEffect(() => {
        const url = `/customers/getSingleCustomer/${cuid}`
        const getData = async () => {
            const res = await axios.get(url)
            setCustomers(res.data);
        }
        getData()
    }, [])




    return (
        <Box
            className='scroll'
            sx={{
                overflowY: 'auto',
                height: '84vh'
            }}>
            <Box

                sx={{
                    margin: '28px',
                }}
            >
                <Box sx={{ backgroundColor: '#FFFFFF', padding: '24px' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#79747E', textAlign: 'left', fontFamily: 'Figtree', marginBottom: '16px' }}>Customer Information</Typography>

                    <hr style={{ border: '.5px solid #E6E6E6', margin: '8px 0 16px 0' }} />
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ width: '462px', textAlign: 'left', marginTop: '16px' }}>
                            <Typography sx={{ fontSize: '16px', color: '#95989A', fontFamily: 'Figtree', marginBottom: '4px' }}>Name</Typography>
                            <Typography sx={{ fontSize: '18px', color: '#021F3F', fontFamily: 'Figtree', fontWeight: 500, marginBottom: '16px' }}>{customers.customer_name}</Typography>
                            <Typography sx={{ fontSize: '16px', color: '#95989A', fontFamily: 'Figtree', marginBottom: '4px' }}>Phone</Typography>
                            <Typography sx={{ fontSize: '18px', color: '#021F3F', fontFamily: 'Figtree', fontWeight: 500, }}>{customers.customer_phone_number}</Typography>
                        </Box>
                        <Box sx={{ textAlign: 'left', marginTop: '16px' }}>
                            <Typography sx={{ fontSize: '16px', color: '#95989A', fontFamily: 'Figtree', marginBottom: '4px' }}>Email</Typography>
                            <Typography sx={{ fontSize: '18px', color: '#021F3F', fontFamily: 'Figtree', fontWeight: 500, marginBottom: '16px' }}>{customers.customer_email}</Typography>
                            <Typography sx={{ fontSize: '16px', color: '#95989A', fontFamily: 'Figtree', marginBottom: '4px' }}>Address</Typography>
                            <Typography sx={{ fontSize: '18px', color: '#021F3F', fontFamily: 'Figtree', fontWeight: 500, }}>{customers.customer_address}</Typography>
                        </Box>
                    </Box>

                </Box>
                <Box sx={{ padding: '24px 24px 16px', backgroundColor: '#FFFFFF', marginTop: '24px' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#79747E', textAlign: 'left', fontFamily: 'Figtree' }}>Customer Order History</Typography>
                </Box>
            </Box>
            <SpecificCustomerOrder cuid={cuid} />
        </Box>
    );
};

export default CustomerDetail;