import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cross from '../../Images/cross.png';
import { useState } from 'react';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import axios from '../InstanceAxios/InstanceAxios'
import { toast, ToastContainer } from 'react-toastify';
import { styled, Typography } from '@mui/material';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#021F3F',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#021F3F',
    },
  },
});

export default function SslDialouge({ isOpenedDialouge, setIsOpenedDialouge, data }) {
  const [formData, setFormData] = useState({
    store_id: "",
    store_password: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (e) => {
    const oldData = { ...formData };
    oldData[e.target.name] = e.target.value;
    setFormData({ ...oldData });
  }

  const handleClose = () => {
    setIsOpenedDialouge(false);
  };

  const handleSubmit = async () => {
    try {
      setIsDisabled(true);
      const decoded = jwtDecode(localStorage.getItem('token'));
      const url = `/sslcommerz/upsertSslCommerzCredentials`;
      const response = await axios.put(url, {
        id: formData.id ? formData.id : null,
        store_id: formData.store_id,
        store_password: formData.store_password,
        shop_id: decoded.shop_id
      });
      if (response.data.status === 'S134') {
        const url = `/paymentMethod/addPaymentMethodToShop/${decoded.shop_id}`;
        const response = await axios.post(url, {
          "payment_method_id": 3
        });
        toast.success('SSLCOMMERZ credentials saved', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
        setIsOpenedDialouge(false);
      } else {
        toast.error('Failed to save SSLCOMMERZ credentials', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Failed to save SSLCOMMERZ credentials', {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setFormData({
        store_id: "",
        store_password: "",
      });
    }
  }

  const getSslData = async () => {
    const { shop_id } = jwtDecode(localStorage.getItem('token'));
    const url = `/sslcommerz/getSslCommerzCredentials/${shop_id}`;
    const response = await axios.get(url);
    if (response.data.sslcommerz_credentials.length) {
      setFormData({
        id: response.data.sslcommerz_credentials[0].id,
        store_id: response.data.sslcommerz_credentials[0].store_id,
        store_password: response.data.sslcommerz_credentials[0].store_password
      });
    }
  }

  useEffect(() => {
    if (formData.store_id.length && formData.store_password.length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formData.store_id, formData.store_password]);

  useEffect(() => {
    getSslData();
  }, [isOpenedDialouge]);


  return (
    <div>
      <ToastContainer
        autoClose={5000}
        closeOnClick />
      <Dialog open={isOpenedDialouge} onClose={handleClose}>
        <DialogTitle className='ssl-dialogue-header'>
          <span>
            Connect with SSLCOMMERZ
          </span>
          <img src={Cross} alt='' height='20px' width='20px' onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          <CssTextField
            autoFocus
            margin="dense"
            id="store_id"
            name='store_id'
            label="Store ID"
            placeholder='Enter your Store ID'
            type="text"
            fullWidth
            variant="outlined"
            value={formData.store_id}
            onChange={(e) => handleChange(e)}
          />
          <CssTextField
            autoFocus
            margin="dense"
            id="store_password"
            name='store_password'
            label="Password"
            placeholder='Paste your payment link here'
            type="text"
            fullWidth
            variant="outlined"
            value={formData.store_password}
            onChange={(e) => handleChange(e)}
          />
          <Typography
            sx={{
              fontSize: '18px',
              color: '#6E6E6E',
              marginTop: '10px'
            }}
          >
            Ipn url: <span style={{ color: '#FF6550' }}>https://api.bonikapp.com/sslcommerz/payment-ipn</span>
          </Typography>
        </DialogContent>
        <div className='ssl-dialogue-bottom'>
          <Button
            disableElevation
            variant="contained"
            sx={{
              padding: "8px 16px",
              backgroundColor: '#FF6550',
              borderRadius: "6px",
              textTransform: 'none',
              fontFamily: 'figtree',
              '&:hover': {
                backgroundColor: '#ED5E4A',

              }
            }}
            onClick={handleSubmit}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Dialog>
    </div>
  );
}