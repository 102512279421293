import { Grid } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Item1 from '../../Images/item_1.png';
import Item2 from '../../Images/item_2.png';
import Item3 from '../../Images/item_4.png';
import Item4 from '../../Images/item_5.png';
import Item5 from '../../Images/item_6.png';
import SelectedItem1 from '../../Images/selected_item_1.png';
import SelectedItem2 from '../../Images/selected_item_2.png';
import SelectedItem3 from '../../Images/selected_item_4.png';
import SelectedItem4 from '../../Images/selected_item_5.png';
import SelectedItem5 from '../../Images/selected_item_6.png';
import './SettingLayout.css';


const SettingLayout = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const settingMenu = [
        {
            id: 1,
            title: "Subscription",
            icon: Item1,
            selectedIcon: SelectedItem1,
            link: "/settings/subscription"
        },
        {
            id: 2,
            title: "Store Information",
            icon: Item2,
            selectedIcon: SelectedItem2,
            link: "/settings/store-information"
        },
        {
            id: 3,
            title: "Theme Settings",
            icon: Item1,
            selectedIcon: SelectedItem1,
            link: "/settings/theme-settings"
        },
        {
            id: 4,
            title: "Delivery Settings",
            icon: Item3,
            selectedIcon: SelectedItem3,
            link: "/settings/delivery-settings"
        },
        {
            id: 5,
            title: "Payment Settings",
            icon: Item4,
            selectedIcon: SelectedItem4,
            link: "/settings/payment-settings"
        },
        {
            id: 6,
            title: "Domain Settings ",
            icon: Item5,
            selectedIcon: SelectedItem5,
            link: "/settings/domain-settings"
        }
    ];


    return (
        <>
            <Grid container>
                <Grid item lg={3}>
                    <ul className='cl-ul'>
                        {settingMenu.map((setting) => (
                            <li key={setting.id}
                                className={`cl-li ${location.pathname == setting.link ? 'selected' : ''} `}
                                onClick={() => navigate(setting.link)}
                            >
                                <img src={location.pathname == setting.link ? setting.selectedIcon : setting.icon} alt="" />
                                <span>{setting.title}</span>
                            </li>
                        ))}
                    </ul>
                </Grid>
                <Grid item lg={9}>
                    <div className="main">
                        {children}
                    </div>
                </Grid>
            </Grid>

        </>
    );
};

export default SettingLayout;