import { Button } from '@mui/material';
import React from 'react';

const OrangeOutlinedButton = ({ title, handleOnClick, sx, icon, isChip = false, isDisabled = false }) => {
    return (
        <Button
            variant="outlined"
            disabled={isDisabled}
            disableElevation={isChip}
            sx={{
                backgroundColor: '#FFFFFF',
                color: '#FF6550',
                borderRadius: '6px',
                padding: '5px 16px',
                fontSize: '14px',
                lineHeight: '24px',
                fontWeight: '400',
                textTransform: 'none',
                border: '1px solid #FF6550',
                '&:hover': {
                    backgroundColor: '#E8D9D7',
                    color: '#FF6550',
                    border: '1px solid #FF6550',
                },
                ...sx
            }}
            onClick={handleOnClick}
        >
            {icon}
            {title}
        </Button>
    );
};

export default OrangeOutlinedButton;