import React from 'react';

const MessengerFeature = () => {
    return (
        <>
            <p>Communicate with customers on your website with Messenger-powered chat. Chat Plugin is a chat widget maintained by the Meta Business that enables live chat on your website. <br /> <br /> Whether they’re on their computer or their phone, website visitors will be able to message you anytime by clicking on a small Messenger chat bubble in the lower right corner of your site.
            </p>
            <p className='single-plugin-details-title'>Key features:</p>
            <ul className='single-plugin-ul'>
                <li className='single-plugin-li'>
                    Website visitors can message you while browsing your site.
                </li>
                <li className='single-plugin-li'>
                    Set up auto-replies and answers to common questions to serve customers when you’re not available.
                </li>
                <li className='single-plugin-li'>
                    Continue the conversation with customers on Messenger even after they leave your website.
                </li>
                <li className='single-plugin-li'>
                    Visitors without a Facebook Messenger account can ask you questions anonymously in Guest mode.
                </li>
                <li className='single-plugin-li'>
                    [Messenger](https://www.facebook.com/business/messenger/get-started)’s familiar interface builds trust.
                </li>
                <li className='single-plugin-li'>
                    No need to switch between apps to answer questions you get on the website.
                </li>
            </ul>
        </>
    );
};

export default MessengerFeature;