import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cross from '../../Images/cross.png';
import { useState } from 'react';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import axios from '../InstanceAxios/InstanceAxios'
import { toast, ToastContainer } from 'react-toastify';
import { Label } from '@mui/icons-material';
import { Divider, styled, Typography } from '@mui/material';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#021F3F',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#021F3F',
    },
  },
});

export default function SslDialouge({ isOpenedDialouge, setIsOpenedDialouge, data }) {
  const [formData, setFormData] = useState({
    publishable_key: "",
    secret_key: "",
    signing_secret: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (e) => {
    const oldData = { ...formData };
    oldData[e.target.name] = e.target.value;
    setFormData({ ...oldData });
  }

  const handleClose = () => {
    setIsOpenedDialouge(false);
  };

  const handleSubmit = async () => {
    try {
      setIsDisabled(true);
      const decoded = jwtDecode(localStorage.getItem('token'));
      const url = `/stripe/upsertStripeCredentials`;
      const response = await axios.put(url, {
        id: formData.id ? formData.id : null,
        publishable_key: formData.publishable_key,
        secret_key: formData.secret_key,
        signing_secret: formData.signing_secret,
        shop_id: decoded.shop_id
      });
      if (response.data.status === 'S143') {
        const url = `/paymentMethod/addPaymentMethodToShop/${decoded.shop_id}`;
        const response = await axios.post(url, {
          "payment_method_id": 4
        });
        toast.success('Stripe credentials saved', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
        setIsOpenedDialouge(false);
      } else {
        toast.error('Failed to save Stripe credentials', {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Failed to save Stripe credentials', {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setFormData({
        publishable_key: "",
        secret_key: "",
        signing_secret: "",
      });
    }
  }

  const getStripeData = async () => {
    const { shop_id } = jwtDecode(localStorage.getItem('token'));
    const url = `/stripe/getStripeCredentials/${shop_id}`;
    const response = await axios.get(url);
    if (response.data.stripe_credentials.length) {
      setFormData({
        id: response.data.stripe_credentials[0].id,
        publishable_key: response.data.stripe_credentials[0].publishable_key,
        secret_key: response.data.stripe_credentials[0].secret_key,
        signing_secret: response.data.stripe_credentials[0].signing_secret,
        shop_id: response.data.stripe_credentials[0].shop_id
      });
    }
  }

  useEffect(() => {
    if (formData.publishable_key && formData.secret_key && formData.signing_secret) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formData]);

  useEffect(() => {
    getStripeData();
  }, [isOpenedDialouge]);


  return (
    <div>
      <ToastContainer
        autoClose={5000}
        closeOnClick />
      <Dialog open={isOpenedDialouge} onClose={handleClose}>
        <DialogTitle className='ssl-dialogue-header'>
          <span>
            Connect with Stripe
          </span>
          <img src={Cross} alt='' height='20px' width='20px' onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          <CssTextField
            autoFocus
            margin="dense"
            id="publishable_key"
            name='publishable_key'
            label="Publishable Key"
            placeholder='Enter your publishable key'
            type="text"
            fullWidth
            variant="outlined"
            value={formData.publishable_key}
            onChange={(e) => handleChange(e)}
          />
          <CssTextField
            autoFocus
            margin="dense"
            id="secret_key"
            name='secret_key'
            label="Secret Key"
            placeholder='Enter your secret key'
            type="text"
            fullWidth
            variant="outlined"
            value={formData.secret_key}
            onChange={(e) => handleChange(e)}
          />
          <CssTextField
            autoFocus
            margin="dense"
            id="signing_secret"
            name='signing_secret'
            label="Signing Secret"
            placeholder='Enter your signing secret'
            type="text"
            fullWidth
            variant="outlined"
            value={formData.signing_secret}
            onChange={(e) => handleChange(e)}
          />
          <Typography
            sx={{
              fontSize: '18px',
              color: '#6E6E6E',
              marginTop: '10px'
            }}
          >
            Webhook url: <span style={{ color: '#FF6550' }}>https://api.bonikapp.com/stripe/customer-webhook</span>
          </Typography>
        </DialogContent>
        <div className='ssl-dialogue-bottom'>
          <Button
            disableElevation
            variant="contained"
            sx={{
              padding: "8px 16px",
              backgroundColor: '#FF6550',
              fontFamily: 'figtree',
              borderRadius: "6px",
              textTransform: 'none',
              '&:hover': {
                // borderColor: '#FF6550',
                backgroundColor: '#ED5E4A'
              }
            }}
            onClick={handleSubmit}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Dialog>
    </div>
  );
}