import { Route, Routes } from 'react-router-dom';
import './App.css';
import Campaign from './Pages/Campaign/Campaign';

import CustomerDetail from './Pages/Customers/CustomerDetail/CustomerDetail';
import Customers from './Pages/Customers/Customers/Customers';

import CreateStorePage from './Pages/CreateStorePage/CreateStorePage';


import Dashboard from './Pages/Dashboard/Dashboard';
import ForgotPincode from './Pages/ForgotPincode/ForgotPincode';
import ForgotPincodeOtp from './Pages/ForgotPincodeOtp/ForgotPincodeOtp';
import AddCollection from './Pages/Inventory/InventoryCollections/AddCollection/AddCollection';
import InventoryCollections from './Pages/Inventory/InventoryCollections/InventoryCollections/InventoryCollections';
import AddProducts from './Pages/Inventory/InventoryProducts/AddProducts/AddProducts';
import EditProducts from './Pages/Inventory/InventoryProducts/EditProducts/EditProducts';
import InventoryProducts from './Pages/Inventory/InventoryProducts/InventoryProducts/InventoryProducts';
import ProductDetail from './Pages/Inventory/InventoryProducts/ProductDetail/ProductDetail';
import Login from './Pages/Login/Login';
import MobileVerification from './Pages/MobileVerification/MobileVerification';
import NotFound from './Pages/NotFound/NotFound';
import OnlineOrder from './Pages/OnlineOrder/OnlineOrder/OnlineOrder';
import OrderDetail from './Pages/OnlineOrder/OrderDetail/OrderDetail';
import Plugins from './Pages/Plugins/Plugins';
import Report from './Pages/Report/Report';
import ResetPincode from './Pages/ResetPincode/ResetPincode';
import Navbar from './Pages/Shared/Navbar/Navbar';
import SideNav from './Pages/SideNav/SideNav/SideNav';
import SignUp from './Pages/SignUp/SignUp';
import PrivateRoute from './utilities/PrivateRoute';
import PaperflySetup from './Pages/PaperflySetup/PaperflySetup';
import Subscription from './Pages/Subscription/Subscription';
import SettingLayout from './components/SettingLayout/SettingLayout';
import StoreInformation from './Pages/StoreInformation/StoreInformation';
import ThemeSettings from './Pages/ThemeSettings/ThemeSettings';
import DeliverySettings from './Pages/DeliverySettings/DeliverySettings';
import PaymentSettings from './Pages/PaymentSettings/PaymentSettings';
import DomainSettings from './Pages/DomainSettings/DomainSettings';
import PluginPixel from './Pages/PluginPixel/PluginPixel';
import PluginAnalytics from './Pages/PluginAnalytics/PluginAnalytics';
import PluginMessenger from './Pages/PluginMessenger/PluginMessenger';
import PluginSsl from './Pages/PluginSsl/PluginSsl';
import PluginPaperfly from './Pages/PluginPaperfly/PluginPaperfly';
import PaymentSuccess from './Pages/PaymentSuccess/PaymentSuccess';
import PaymentFailed from './Pages/PaymentFailed/PaymentFailed';
import { useEffect } from 'react';
import { gapi } from 'gapi-script';
import Profile from './Pages/Profile/Profile';
import CreateCampaign from './Pages/CreateCampaign/CreateCampaign';
import ThemeFeaturedProducts from './Pages/ThemeFeaturedProducts/ThemeFeaturedProducts';
import SoloThemeSelectProduct from './Pages/SoloThemeSelectProduct/SoloThemeSelectProduct';
import SoloThemeProductDetails from './Pages/SoloThemeProductDetails/SoloThemeProductDetails';
import PluginStripe from './Pages/PluginStripe/PluginStripe';
import { ToastContainer } from 'react-toastify';
import PluginWhatsapp from './Pages/PluginWhatsapp/PluginWhatsapp';
const clientId = '93192965205-rdq2ht42j8b346qemt5g1rp4sq2qikqc.apps.googleusercontent.com';


function App() {

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: ''
      });
    };
    gapi.load('client:auth2', initClient);
  });

  const PrivateLayout = ({ children }) => {
    return (
      <PrivateRoute>
        <div className="App">
          <Navbar />
          <SideNav>
            {children}
          </SideNav>
        </div>
      </PrivateRoute>
    )
  }

  return (
    <>
      <ToastContainer
        autoClose={5000}
        closeOnClick />
      <Routes>
        {/* home route  */}
        <Route path='/' element={<PrivateLayout><Dashboard /></PrivateLayout>} />
        {/* public routes */}
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/mobileVerification' element={<MobileVerification />} />
        <Route path='/forgotPincode' element={<ForgotPincode />} />
        <Route path='/forgotPincodeOtp' element={<ForgotPincodeOtp />} />
        <Route path='/resetPincode' element={<ResetPincode />} />
        <Route path='/createStore' element={<CreateStorePage />} />
        {/* private routes  */}
        <Route path='/profile' element={<PrivateLayout><Profile /></PrivateLayout>} />
        <Route path='/dashboard' element={<PrivateLayout><Dashboard /></PrivateLayout>} />

        <Route path='/inventory/products' element={<PrivateLayout><InventoryProducts /></PrivateLayout>} />
        <Route path='/inventory/collections' element={<PrivateLayout><InventoryCollections /></PrivateLayout>} />

        <Route path='/orders' element={<PrivateLayout><OnlineOrder /></PrivateLayout>} />
        <Route path='/orders/:oid' element={<PrivateLayout><OrderDetail /></PrivateLayout>} />

        <Route path='/productDetail/:pid' element={<PrivateLayout><ProductDetail /></PrivateLayout>} />

        <Route path='/inventory/add' element={<PrivateLayout><AddProducts /></PrivateLayout>} />
        <Route path='/inventory/:pid' element={<PrivateLayout><EditProducts /></PrivateLayout>} />
        <Route path='/inventory/collection/add' element={<PrivateLayout><AddCollection /></PrivateLayout>} />
        <Route path='/inventory/collection/:cid' element={<PrivateLayout><AddCollection /></PrivateLayout>} />

        <Route path='/customers' element={<PrivateLayout><Customers /></PrivateLayout>} />
        <Route path='/customer/:cuid' element={<PrivateLayout><CustomerDetail /></PrivateLayout>} />

        <Route path='/campaigns' element={<PrivateLayout><Campaign /></PrivateLayout>} />
        <Route path='/campaigns/create' element={<PrivateLayout><CreateCampaign /></PrivateLayout>} />
        <Route path='/report' element={<PrivateLayout><Report /></PrivateLayout>} />
        <Route path='/plugins' element={<PrivateLayout><Plugins /></PrivateLayout>} />
        <Route path='/plugins/pixel' element={<PrivateLayout><PluginPixel /></PrivateLayout>} />
        <Route path='/plugins/analytics' element={<PrivateLayout><PluginAnalytics /></PrivateLayout>} />
        <Route path='/plugins/messenger' element={<PrivateLayout><PluginMessenger /></PrivateLayout>} />
        <Route path='/plugins/ssl' element={<PrivateLayout><PluginSsl /></PrivateLayout>} />
        <Route path='/plugins/paperfly' element={<PrivateLayout><PluginPaperfly /></PrivateLayout>} />      <Route path='/plugins/install/paperfly/setup' element={<PrivateLayout><PaperflySetup /></PrivateLayout>} />
        <Route path='/plugins/stripe' element={<PrivateLayout><PluginStripe /></PrivateLayout>} />
        <Route path='/plugins/whatsapp' element={<PrivateLayout><PluginWhatsapp /></PrivateLayout>} />
        <Route path='/plugins/install/paperfly/setup' element={<PrivateLayout><PaperflySetup /></PrivateLayout>} />
        <Route path='/settings/subscription'
          element={<PrivateLayout><SettingLayout><Subscription /></SettingLayout></PrivateLayout>}
        />
        <Route path='/settings/subscription/payment-successful'
          element={<PrivateLayout><SettingLayout><PaymentSuccess /></SettingLayout></PrivateLayout>}
        />
        <Route path='/settings/subscription/payment-failed'
          element={<PrivateLayout><SettingLayout><PaymentFailed /></SettingLayout></PrivateLayout>}
        />
        <Route path='/settings/subscription/payment-cancelled'
          element={<PrivateLayout><SettingLayout><PaymentFailed /></SettingLayout></PrivateLayout>}
        />
        <Route path='/settings/store-information'
          element={<PrivateLayout><SettingLayout><StoreInformation /></SettingLayout></PrivateLayout>}
        />
        <Route path='/settings/theme-settings'
          element={<PrivateLayout><SettingLayout><ThemeSettings /></SettingLayout></PrivateLayout>}
        />
        <Route path='/settings/theme-settings/featured-products'
          element={<PrivateLayout><SettingLayout><ThemeFeaturedProducts /></SettingLayout></PrivateLayout>}
        />
        <Route path='/settings/theme-settings/solo-theme-select-product'
          element={<PrivateLayout><SettingLayout><SoloThemeSelectProduct /></SettingLayout></PrivateLayout>}
        />
        <Route path='/settings/theme-settings/solo-theme-product-details'
          element={<PrivateLayout><SettingLayout><SoloThemeProductDetails /></SettingLayout></PrivateLayout>}
        />
        <Route path='/settings/delivery-settings'
          element={<PrivateLayout><SettingLayout><DeliverySettings /></SettingLayout></PrivateLayout>}
        />
        <Route path='/settings/payment-settings'
          element={<PrivateLayout><SettingLayout><PaymentSettings /></SettingLayout></PrivateLayout>}
        />
        <Route path='/settings/domain-settings'
          element={<PrivateLayout><SettingLayout><DomainSettings /></SettingLayout></PrivateLayout>}
        />
        {/* Not Found route  */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
