import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputBase, InputLabel, MenuItem, Paper, Radio, Select, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import Checkbox from '@mui/material/Checkbox';
import CancelIcon from '@mui/icons-material/Cancel';
import { v4 as uuidv4 } from 'uuid';
import VariantModal from '../VariantModal/VariantModal';
import axios from '../../../InstanceAxios/InstanceAxios'
import SelectCollection from '../SelectCollection/SelectCollection';
import { useNavigate, useParams } from 'react-router-dom';
import { getShopInfo } from '../../../../utilities/StoredData';
import EditVariantModal from '../EditVariantModal/EditVariantModal';
import { NotificationContext } from '../../../../Contexts/NotificationContext';
import { AuthContext } from '../../../../Contexts/AuthContext';
import CrossIcon from '../../../../Images/cross.png';

const getFormValues = () => {
    return {
        product_name: '',
        product_description: '',
        product_collection: 0,
        unit_name: '',
        price: 0,
        quantity: 0,
    }
};

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#021F3F',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#021F3F',
        },
    },
});

const CssInputLabel = styled(Select)({

    '& label.Mui-focused': {
        color: '#021F3F',
    },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#021F3F',
    },

});

const EditProducts = () => {
    const { showNotification } = useContext(NotificationContext);
    const { shopDetails } = useContext(AuthContext);
    const navigate = useNavigate()
    const info = getShopInfo()
    const { pid } = useParams()
    const [click, setClick] = useState(false)
    const [values, setValues] = useState(getFormValues);
    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [openAddColor, setOpenAddColor] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [images, setImages] = useState([]);
    const [collections, setCollections] = useState([])
    const [colorInputField, setColorInputField] = useState([])
    const [openAddSize, setOpenAddSize] = useState(false);
    const [sizeInputField, setSizeInputField] = useState([])
    const [openAddCustom, setOpenAddCustom] = useState(false);
    const [customInputField, setCustomInputField] = useState([])
    const [data, setData] = useState({})
    const [deletedImage, setDeletedImage] = useState([])
    const [variantProducts, setVariantProducts] = useState([])
    const [variants, setVariants] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [currencyList, setCurrencyList] = useState([]);
    const [inputError, setInputError] = useState({
        status: false,
        message: ''
    });

    let color = '';
    let size = '';
    let custom = '';

    useEffect(() => {
        const url = `/products/getSingleProductV3/${pid}`
        const getInfo = async () => {
            const res = await axios.get(url)
            const data = res.data;
            setData(data)
            let temp = values;
            temp.product_name = data.product_name;
            temp.product_collection_name = data.product_category.category;
            temp.product_collection = data.product_category.category_id;
            temp.product_description = data.product_description;
            temp.price = data.unit_selling_price;
            temp.quantity = data.stock[0].number_of_stocks;
            temp.unit_name = data.unit_name;
            setValues(temp);
            setSelectedImages(data.product_images);
            data.publish_status === 'true' ? setChecked(true) : setChecked(false);
            let color = []
            let size = []
            let custom = []
            data.variant_names.map(r => {
                if (r.title === 'color') {
                    r.variant_values.map(t => color.push(t.title))
                }
                else if (r.title === 'size') {
                    r.variant_values.map(t => size.push(t.title))
                }
                else {
                    let value = []
                    r.variant_values.map(t => value.push(t.title))
                    custom.push({ 'name': r.title, 'values': value })
                }
            });
            setCustomInputField(custom);
            setColorInputField(color);
            setSizeInputField(size);
            updateVariantProducts();
        }
        getInfo()
    }, [pid])

    const handleChange = (e) => {
        setValues((previousValues) => ({
            ...previousValues,
            [e.target.name]: e.target.value,
        }));
    }

    const onSelectFile = e => {
        const selectedFiles = e.target.files;
        setImages(e.target.files)
        const selectedFilesArray = Array.from(selectedFiles);
        const imagesArray = selectedFilesArray?.map((file) => {
            return URL.createObjectURL(file);
        });
        setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    }

    const handleClickChecked = (event) => {
        setChecked(!checked);
    };

    const handleAddColorClick = () => {
        setOpenAddColor(!openAddColor);
        setInputError({
            status: false,
            message: ''
        });
    }

    const handleSubmit = () => {
        if (colorInputField.includes(color)) {
            setInputError({
                status: true,
                message: `Color ${color} already exists`
            })
            return;
        } else {
            setInputError({
                status: false,
                message: ''
            });
        }
        if (color.length) {
            setColorInputField(current => [...current, color]);
            setOpenAddColor(!openAddColor);
        }
    }

    const handleDelete = (f) => () => {
        const indexF = colorInputField.indexOf(f);
        setColorInputField((colorInputField) => colorInputField.filter((field, index) => index !== indexF))
    };

    const handleAddSizeClick = () => {
        setOpenAddSize(!openAddSize);
        setInputError({
            status: false,
            message: ''
        });
    }

    const handleSubmitSize = () => {
        if (sizeInputField.includes(size)) {
            setInputError({
                status: true,
                message: `Size ${size} already exists`
            });
            return;
        } else {
            setInputError({
                status: false,
                message: ''
            });
        }
        if (size.length) {
            setSizeInputField(current => [...current, size]);
            setOpenAddSize(!openAddSize);
        }
    }

    const handleDeleteSize = (f) => () => {
        const indexF = sizeInputField.indexOf(f);
        setSizeInputField((sizeInputField) => sizeInputField.filter((field, index) => index !== indexF))
    };

    const handleAddCustomClick = () => {
        setOpenAddCustom(!openAddCustom);
        setInputError({
            status: false,
            message: ''
        });
    }

    const handleSubmitCustom = () => {
        if (customInputField.find(a => a.name === custom)) {
            setInputError({
                status: true,
                message: `Custom variant ${custom} already exists`
            });
            return;
        } else {
            setInputError({
                status: false,
                message: ''
            });
        }
        if (custom.length) {
            setCustomInputField(current => [
                ...current,
                { id: uuidv4(), name: custom, values: [] }
            ]);
            setOpenAddCustom(!openAddCustom);
        }
    }

    const handleDeleteCustom = (f, indexField, id) => () => {
        const indexF = customInputField[indexField].values.indexOf(f);
        const updateField = customInputField[indexField].values.filter((field, index) => index !== indexF)
        const newField = [...customInputField];
        const field = newField.find(a => a.id === id)
        field.values = updateField
        setCustomInputField(newField)
    };

    const handleSubmitCustomVariant = (index, id) => {
        const newVariant = customInputField[index].values
        newVariant.push(custom)
        const newField = [...customInputField];
        const field = newField.find(a => a.id === id);
        field.values = newVariant;
        setCustomInputField(newField)
    }

    const handleVariantDialog = (index) => {
        const newField = [...customInputField];
        const field = newField.find(a => a.id === index);
        field.isOpen = !field.isOpen;
        setCustomInputField(newField);
    }

    //collection modal
    const handleClickOpen = () => {
        setOpen(true);
        getCollections()
    };

    const handleClose = () => {
        setOpen(false);
    };

    const updateVariantProducts = () => {
        const allVariants = [
            ...customInputField,
            { name: 'color', values: colorInputField },
            { name: 'size', values: sizeInputField }
        ];

        let tempVariants = allVariants.filter(r => r.values.length !== 0)
        setVariants(tempVariants);

        let result = [];
        tempVariants.forEach((variant, i) => {
            let varTemp = [];
            variant.values.forEach((val) => {
                let valTemp = [];
                if (i === 0) {
                    let variants = []
                    variants.push({ 'name': variant.name, 'value': val })
                    result.push({ 'title': val, 'variants': variants });
                } else {
                    result.forEach((res, idx) => {
                        let variants = [...result[idx].variants];
                        variants.push({ 'name': variant.name, 'value': val })
                        valTemp.push({ 'title': `${res.title} - ${val}`, 'variants': variants });
                    });
                    varTemp.push(...valTemp);
                }
            });
            if (i !== 0) {
                result = [...varTemp];
            }
        });
        const tempVariantProducts = [...result];
        // setVariantProducts(result);

        if (pid && data.variant_products?.length) {
            for (let i = 0; i < data.variant_products?.length; i++) {
                const foundVariantProductIndex = tempVariantProducts.findIndex(r => r.title === data.variant_products[i]?.title)
                if (foundVariantProductIndex !== -1) {
                    tempVariantProducts[foundVariantProductIndex].in_stock = data.variant_products[i]?.in_stock;
                    tempVariantProducts[foundVariantProductIndex].selling_price = data.variant_products[i]?.selling_price;
                    tempVariantProducts[foundVariantProductIndex].image_id = data.variant_products[i]?.product_image_id
                    tempVariantProducts[foundVariantProductIndex].image_index = data.variant_products[i]?.product_image_index
                }
            }
            setVariantProducts((previousValues) => [...tempVariantProducts]);
        } else {
            setVariantProducts(result);
        }
    }


    const handleVariantPrice = (index, e) => {
        const temp = [...variantProducts];
        temp[index].selling_price = e.target.value;
        setVariantProducts(temp);
    }
    const handleVariantStock = (index, e) => {
        const temp = [...variantProducts];
        temp[index].in_stock = e.target.value;
        setVariantProducts(temp);
    }

    const handleVariantImage = (id, index) => {
        const temp = [...variantProducts];
        temp[index].image_index = id
        temp[index].image_id = null
        setVariantProducts(temp);
    }

    const handleVariantImageId = (id, index) => {
        const temp = [...variantProducts];
        temp[index].image_id = id
        temp[index].image_index = null
        setVariantProducts(temp);
    }

    const getCollections = async () => {
        const res = await axios.get(`productCategories/allProductCategories?shop_id=${info.shop_id}`)
        setCollections(res.data.Product_Categories);
    }

    const handleCancelImage = (id, image) => {
        const im = deletedImage
        data.product_images.map(f => f.image_url === image?.image_url && im.push(f.image_id))
        setDeletedImage(im);
        setSelectedImages((img) => img.filter((field, index) => index !== id))
        for (let i = 0; i < variantProducts.length; i++) {
            for (let j = 0; j < deletedImage.length; j++) {
                if (variantProducts[i].image_id === deletedImage[j]) {
                    const temp = [...variantProducts];
                    temp[i].image_id = null;
                    temp[i].image_index = -1;
                    setVariantProducts(temp);
                }
            }
        }
    }

    const handleClickCollections = (e, t) => {
        const temp = values;
        temp.product_collection = e;
        temp.product_collection_name = t;
        setValues(temp);
    }

    const handleUpdate = async (e) => {
        try {
            setIsLoading(true);
            let indx
            selectedImages.map((f, index) => {
                if (f.image_id) {
                }
                else {
                    indx = index
                    return indx
                }
            })
            for (let i = 0; i < variantProducts.length; i++) {
                if (variantProducts[i].image_index >= indx) {
                    const temp = [...variantProducts];
                    temp[i].image_index = variantProducts[i].image_index - indx;
                    setVariantProducts(temp);
                }
            }
            for (let i = 0; i < variantProducts.length; i++) {
                for (let j = 0; j < deletedImage.length; j++) {
                    if (variantProducts[i].image_id === deletedImage[j]) {
                        const temp = [...variantProducts];
                        temp[i].image_id = null;
                        temp[i].image_index = -1;
                        setVariantProducts(temp);
                    }
                }
            }
            setClick(true)
            const formData = new FormData();
            for (const file of images) {
                formData.append('images', file);
            }
            formData.append('product_name', values.product_name)
            formData.append('unit_name', values.unit_name)
            formData.append('category_id', values.product_collection)
            formData.append('unit_selling_price', parseInt(values.price))
            formData.append('product_description', values.product_description)
            formData.append('publish_status', checked)
            formData.append('quantity', parseInt(values.quantity))
            formData.append('variants', JSON.stringify(variants))
            formData.append('variant_products', JSON.stringify(variantProducts))
            formData.append('deleted_images', JSON.stringify(deletedImage))

            const url = `/products/updateProductV3/${pid}`
            const res = await axios.put(url, formData);
            if (res.data.status === 'S122') {
                showNotification('success', 'Product Updated Successfully');
                navigate('/inventory/products');
            } else {
                showNotification('error', 'Failed to update product');
            }
        } catch (err) {
            showNotification('error', 'Failed to update product');
        } finally {
            setIsLoading(false);
            setClick(false);
        }
    }

    // const CssInputLabel = styled(Select)({
    //     '& label.Mui-focused': {
    //         color: '#021F3F',
    //     },
    //     '& .MuiInputLabel-root': {
    //         '&.Mui-focused ': {
    //             borderColor: '#021F3F',
    //         },
    //     },
    // });

    const getAllCurrencies = async () => {
        try {
            const currencyList = localStorage.getItem('currencyList');
            if (currencyList) {
                setCurrencyList(JSON.parse(currencyList));
            } else {
                const url = `/currencies/getAllCurrencies`;
                const res = await axios.get(url);
                setCurrencyList(res.data);
                localStorage.setItem('currencyList', JSON.stringify(res.data));
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        updateVariantProducts();
    }, [customInputField, colorInputField, sizeInputField]);


    useEffect(() => {
        getAllCurrencies();
    }, []);

    return (
        <Box>
            <Grid container sx={{ marginX: '32px' }}>
                <Grid item md={6} sx={{ textAlign: 'left' }}>
                    <Box sx={{ marginRight: '48px', backgroundColor: '#FFFFFF', padding: '24px' }}>
                        <Typography sx={{ marginBottom: '24px', fontSize: '22px', color: '#021F3F' }}>About Product</Typography>
                        <CssTextField
                            required
                            fullWidth
                            id="outlined-required"
                            label="Product Name"
                            name='product_name'
                            type="text"
                            placeholder="Enter Product Name"
                            sx={{ marginBottom: '16px' }}
                            value={values?.product_name}
                            onChange={handleChange}
                            InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                            InputProps={{ sx: { fontFamily: 'Figtree' } }}
                        />
                        {/* collection selection */}
                        <Box sx={{ marginBottom: '16px' }}>
                            <CssTextField
                                required
                                disabled
                                fullWidth
                                id="outlined-required"
                                label=""
                                name='product_collection'
                                type="text"
                                placeholder="Select a collection"
                                sx={{ marginBottom: '16px' }}
                                value={values?.product_collection_name}
                                onClick={handleClickOpen}
                                InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                InputProps={{ sx: { fontFamily: 'Figtree' } }}
                            />
                            <Dialog
                                fullWidth
                                open={open}
                                onClose={handleClose}
                            >
                                <DialogTitle>Select Collection</DialogTitle>
                                <DialogContent className='scroll' sx={{ paddingX: '0px', height: '50%' }}>
                                    <DialogContentText sx={{ paddingLeft: '24px' }} onClick={() => navigate('/inventory/collection/add')}>
                                        + Add Collection
                                    </DialogContentText>
                                    <Box onClick={handleClose}>
                                        <SelectCollection collections={collections} handleClickCollections={handleClickCollections} />
                                    </Box>
                                </DialogContent>
                            </Dialog>
                        </Box>

                        <CssTextField

                            required
                            id="outlined-multiline-static-required"
                            label="Product Description"
                            name="product_description"
                            placeholder='Product Description'
                            multiline
                            fullWidth
                            type="text"
                            rows={5}
                            value={values?.product_description}
                            onChange={handleChange}
                            sx={{ marginBottom: '8px' }}
                            InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                            InputProps={{ sx: { fontFamily: 'Figtree' } }}
                        />
                        <Typography sx={{ fontSize: '14px', color: '#95989A', fontFamily: 'Figtree', marginBottom: '16px' }}>*Description should not exceed 2000 characters</Typography>
                        <Grid container spacing={2}>

                            <Grid item md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-required-label"
                                        sx={{
                                            "&.Mui-focused": {
                                                color: "#021F3F"
                                            }
                                        }}
                                    >Currency</InputLabel>
                                    <CssInputLabel
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        value={shopDetails?.currencies?.id}
                                        autoWidth
                                        label="Currency"
                                        disabled={true}
                                    >
                                        {
                                            currencyList.map((item, index) =>
                                                <MenuItem key={index} value={item.id}>{item.title}</MenuItem>
                                            )
                                        }
                                    </CssInputLabel>
                                </FormControl>
                            </Grid>
                            <Grid item md={3}>
                                <CssTextField

                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Price"
                                    name='price'
                                    type='number'
                                    placeholder="Enter Price"
                                    sx={{ marginBottom: '16px' }}
                                    value={values?.price}
                                    onChange={handleChange}
                                    InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                    InputProps={{ sx: { fontFamily: 'Figtree' } }}
                                />
                            </Grid>

                            <Grid item md={3}>
                                <CssTextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Total In Stock Quantity"
                                    name='quantity'
                                    type='number'
                                    placeholder="Enter quantity"
                                    sx={{ marginBottom: '16px' }}
                                    value={variantProducts.length ? variantProducts.reduce((a, b) => a + Number(b.in_stock), 0) : values?.quantity}
                                    onChange={handleChange}
                                    InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                    InputProps={{ sx: { fontFamily: 'Figtree' } }}
                                    disabled={variantProducts.length ? true : false}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-required-label"
                                        sx={{
                                            "&.Mui-focused": {
                                                color: "#021F3F"
                                            }
                                        }}
                                    >Unit</InputLabel>
                                    <CssInputLabel
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        value={values?.unit_name}
                                        onChange={handleChange}
                                        autoWidth
                                        label="Unit *"
                                        name='unit_name'
                                    >
                                        <MenuItem value=''></MenuItem>
                                        <MenuItem value='Pcs'>Pcs</MenuItem>
                                        <MenuItem value='KG'>KG</MenuItem>
                                        <MenuItem value='gram'>Gram</MenuItem>
                                        <MenuItem value='Liter'>Liter</MenuItem>
                                        <MenuItem value='ml'>ml</MenuItem>
                                        <MenuItem value='Meter'>Meter</MenuItem>
                                    </CssInputLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Typography sx={{ fontSize: '14px', color: '#95989A', fontFamily: 'Figtree' }}>*Change stock in variant to calculate the total</Typography>
                    </Box>
                    <Box sx={{ marginRight: '48px', backgroundColor: '#FFFFFF', padding: '24px', marginTop: '28px' }}>
                        <Typography sx={{ marginBottom: '24px', fontSize: '22px', color: '#021F3F' }}>Product Image</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            {
                                selectedImages &&
                                selectedImages?.map((image, index) => {
                                    return (
                                        <Box>
                                            <dix key={image}>
                                                {
                                                    image.image_url ? <img src={image.image_url} height='92px' width='92px' style={{ borderRadius: '4px', marginRight: '24px' }} alt='upload' /> :
                                                        <img src={image} height='92px' width='92px' style={{ borderRadius: '4px', marginRight: '24px' }} alt='upload' />

                                                }
                                            </dix>
                                            <CancelIcon onClick={() => { handleCancelImage(index, image) }} sx={{ color: '#ED1C24', position: 'absolute', zIndex: '2', marginLeft: '-38px', marginTop: '-10px', cursor: 'pointer' }} />
                                        </Box>
                                    )
                                })
                            }
                            <div>
                                <label className='addImage'>
                                    <UploadFileOutlinedIcon sx={{ width: '20px', height: '23px', marginBottom: '5px' }} />
                                    Add Image
                                    <br />
                                    <input
                                        type="file"
                                        name="images"
                                        onChange={(e) => onSelectFile(e)}
                                        multiple
                                        accept="image/png , image/jpeg, image/webp"
                                    />
                                </label>
                                <br />
                                <input type="file" multiple />
                            </div>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                checked={checked}
                                onClick={handleClickChecked}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography sx={{ fontSize: '18px', color: '#95989A', fontFamily: 'Figtree' }}>Publish to Online Shop</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={6} sx={{ textAlign: 'left' }}>
                    <Box sx={{ backgroundColor: '#FFFFFF', marginRight: '48px', padding: '24px' }}>
                        <Typography sx={{ marginBottom: '24px', fontSize: '22px', color: '#021F3F' }}>Add Variant</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
                            <Typography sx={{ fontSize: '14px', color: '#021F3F', width: '100px' }}>Color</Typography>
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', height: '32px' }}>
                                    <Paper
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            listStyle: 'none',
                                            boxShadow: 'none',
                                            paddingLeft: '0px',
                                            // marginRight: '16px'
                                        }}
                                        component="ul"
                                    >
                                        {colorInputField?.length !== 0 && (
                                            colorInputField?.map((field) =>
                                                <ListItem key={field} sx={{ padding: '0px', margin: '0px' }}>
                                                    <Chip
                                                        label={field}
                                                        onDelete={handleDelete(field)}
                                                        sx={{
                                                            border: '1px solid #D1D1D1',
                                                            backgroundColor: '#FFFFFF',
                                                            color: '#021F3F',
                                                            fontSize: '14px',
                                                            borderRadius: '8px',
                                                            textUnderlineOffset: 'none',
                                                            margin: '0px 8px 0px 0px'
                                                        }}
                                                    />
                                                </ListItem>
                                            ))}
                                    </Paper>
                                    <Button
                                        variant="outlined"
                                        onClick={handleAddColorClick}
                                        sx={{
                                            border: '1px solid #FF6550',
                                            height: '32px',
                                            borderRadius: '8px',
                                            color: '#FF6550',
                                            textTransform: 'none',
                                            '&:hover': {
                                                border: '1px solid #FF6550',
                                                color: '#FF6550',
                                                backgroundColor: '#E8D9D7'
                                            },
                                        }}
                                    >
                                        <AddIcon sx={{ marginRight: '5px', fontSize: '14px' }} />
                                        Add Color
                                    </Button>
                                </Box>
                                <Dialog
                                    fullWidth
                                    open={openAddColor}
                                    onClose={handleAddColorClick}
                                >

                                    <DialogTitle
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '22px',
                                                color: '#021F3F',
                                            }}
                                        >
                                            Add Color
                                        </Typography>
                                        <Box
                                            component="img"
                                            src={CrossIcon}
                                            sx={{
                                                width: '18px',
                                                height: '18px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleAddColorClick}
                                        />
                                    </DialogTitle>
                                    <DialogContent>
                                        <Box sx={{ marginTop: '5px' }}>

                                            <CssTextField
                                                fullWidth
                                                id="outlined-required"
                                                name='color'
                                                type="text"
                                                label='Color'
                                                placeholder="Ex- Black"
                                                sx={{ marginBottom: '16px' }}
                                                onChange={e => color = e.target.value}
                                                InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                                InputProps={{ sx: { fontFamily: 'Figtree' } }}
                                            />
                                            {
                                                inputError.status &&
                                                <Box
                                                    sx={{
                                                        backgroundColor: 'rgba(237, 28, 36, 0.1)',
                                                        padding: '14px 16px',
                                                        borderRadius: '8px',
                                                        marginBottom: '16px',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: '#ED1C24',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {inputError.message}
                                                    </Typography>
                                                </Box>
                                            }
                                            <Button
                                                variant="outlined"
                                                type='submit'
                                                onClick={handleSubmit}
                                                sx={{
                                                    border: '1px solid #FF6550',
                                                    borderRadius: '8px',
                                                    color: '#FF6550',
                                                    textTransform: 'none',
                                                    '&:hover': {
                                                        border: '1px solid #FF6550',
                                                        color: '#FF6550',
                                                        backgroundColor: '#E8D9D7'
                                                    },
                                                }}
                                            >
                                                Add Color
                                            </Button>
                                        </Box>
                                    </DialogContent>
                                </Dialog>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
                            <Typography sx={{ fontSize: '14px', color: '#021F3F', width: '100px' }}>Size</Typography>
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', height: '32px' }}>
                                    <Paper
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            paddingLeft: '0px',
                                            flexWrap: 'wrap',
                                            listStyle: 'none',
                                            boxShadow: 'none',
                                            // marginRight: '16px'

                                        }}
                                        component="ul"
                                    >
                                        {sizeInputField?.length !== 0 && (
                                            sizeInputField?.map((field) =>
                                                <ListItem key={field} sx={{ padding: '0px', margin: '0px' }}>
                                                    <Chip
                                                        label={field}
                                                        onDelete={handleDeleteSize(field)}
                                                        sx={{
                                                            border: '1px solid #D1D1D1',
                                                            backgroundColor: '#FFFFFF',
                                                            color: '#021F3F',
                                                            fontSize: '14px',
                                                            borderRadius: '8px',
                                                            textUnderlineOffset: 'none',
                                                            margin: '0px 8px 0px 0px'
                                                        }}
                                                    />
                                                </ListItem>
                                            ))}
                                    </Paper>
                                    <Button
                                        variant="outlined"
                                        onClick={handleAddSizeClick}
                                        sx={{
                                            border: '1px solid #FF6550',
                                            borderRadius: '8px',
                                            color: '#FF6550',
                                            textTransform: 'none',
                                            '&:hover': {
                                                border: '1px solid #FF6550',
                                                color: '#FF6550',
                                                backgroundColor: '#E8D9D7'
                                            },
                                        }}
                                    >
                                        <AddIcon sx={{ marginRight: '5px', fontSize: '14px' }} />
                                        Add Size
                                    </Button>
                                </Box>
                                <Dialog
                                    fullWidth
                                    open={openAddSize}
                                    onClose={handleAddSizeClick}
                                >
                                    <DialogTitle
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '22px', color: '#021F3F' }}>
                                            Add Size
                                        </Typography>
                                        <Box
                                            component="img"
                                            src={CrossIcon}
                                            sx={{
                                                width: '18px',
                                                height: '18px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleAddSizeClick}
                                        />
                                    </DialogTitle>
                                    <DialogContent>
                                        <Box sx={{ marginTop: '5px' }}>

                                            <CssTextField
                                                fullWidth
                                                id="outlined-required"
                                                name='color'
                                                type="text"
                                                label='Size'
                                                placeholder="Ex- 5 or S or small"
                                                sx={{ marginBottom: '16px' }}
                                                onChange={e => size = e.target.value}
                                                InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                                InputProps={{ sx: { fontFamily: 'Figtree' } }}
                                            />
                                            {
                                                inputError.status &&
                                                <Box
                                                    sx={{
                                                        backgroundColor: 'rgba(237, 28, 36, 0.1)',
                                                        padding: '14px 16px',
                                                        borderRadius: '8px',
                                                        marginBottom: '16px',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: '#ED1C24',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {inputError.message}
                                                    </Typography>
                                                </Box>
                                            }
                                            <Button
                                                variant="outlined"
                                                type='submit'
                                                onClick={handleSubmitSize}
                                                sx={{
                                                    border: '1px solid #FF6550',
                                                    borderRadius: '8px',
                                                    color: '#FF6550',
                                                    textTransform: 'none',
                                                    '&:hover': {
                                                        border: '1px solid #FF6550',
                                                        color: '#FF6550',
                                                        backgroundColor: '#E8D9D7'
                                                    },
                                                }}
                                            >
                                                Add Size
                                            </Button>
                                        </Box>
                                    </DialogContent>
                                </Dialog>
                            </Box>
                        </Box>
                        <Box>
                            {
                                customInputField?.length !== 0 && (
                                    customInputField?.map((field, index) => <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
                                        <Typography sx={{ fontSize: '14px', color: '#021F3F', width: '100px' }}>{field.name}</Typography>
                                        <Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', height: '32px' }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        paddingLeft: '0px',
                                                        flexWrap: 'wrap',
                                                        listStyle: 'none',
                                                        boxShadow: 'none',
                                                        textAlign: 'left',
                                                        // marginRight: '16px'
                                                    }}
                                                    component="ul"
                                                >
                                                    {field?.values?.length !== 0 && (
                                                        field?.values?.map((item) =>
                                                            <ListItem key={item} sx={{ padding: '0px', margin: '0px' }}>
                                                                <Chip
                                                                    label={item}
                                                                    onDelete={handleDeleteCustom(item, index, field.id)}
                                                                    sx={{
                                                                        border: '1px solid #D1D1D1',
                                                                        backgroundColor: '#FFFFFF',
                                                                        color: '#021F3F',
                                                                        fontSize: '14px',
                                                                        borderRadius: '8px',
                                                                        textUnderlineOffset: 'none',
                                                                        margin: '0px 8px 0px 0px'
                                                                    }}
                                                                />
                                                            </ListItem>

                                                        ))}
                                                </Box>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => handleVariantDialog(field.id)}
                                                    sx={{
                                                        border: '1px solid #FF6550',
                                                        borderRadius: '8px',
                                                        color: '#FF6550',
                                                        textTransform: 'none',
                                                        '&:hover': {
                                                            border: '1px solid #FF6550',
                                                            color: '#FF6550',
                                                            backgroundColor: '#E8D9D7'
                                                        },
                                                    }}
                                                >
                                                    <AddIcon sx={{ marginRight: '5px', fontSize: '14px' }} />
                                                    Add {field.name}
                                                </Button>
                                            </Box>
                                            <Dialog
                                                fullWidth
                                                open={field.isOpen}
                                                onClose={() => handleVariantDialog(field.id)}
                                            >
                                                <DialogTitle
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography sx={{ fontSize: '22px', color: '#021F3F' }}>
                                                        Add {field.name}
                                                    </Typography>
                                                    <Box
                                                        component="img"
                                                        src={CrossIcon}
                                                        sx={{
                                                            width: '18px',
                                                            height: '18px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => handleVariantDialog(field.id)}
                                                    />
                                                </DialogTitle>
                                                <DialogContent>
                                                    <Box sx={{ marginTop: '5px' }}>
                                                        <CssTextField
                                                            // key={field}
                                                            // required
                                                            fullWidth
                                                            id="outlined-required"
                                                            name='custom-variant'
                                                            type="text"
                                                            // label={`Color ${index + 1}`}
                                                            label='Value'
                                                            placeholder="Ex- Black"
                                                            sx={{ marginBottom: '16px' }}
                                                            // value={field}
                                                            onChange={e => custom = e.target.value}
                                                            InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                                            InputProps={{ sx: { fontFamily: 'Figtree' } }}

                                                        />
                                                        {
                                                            inputError.status &&
                                                            <Box
                                                                sx={{
                                                                    backgroundColor: 'rgba(237, 28, 36, 0.1)',
                                                                    padding: '14px 16px',
                                                                    borderRadius: '8px',
                                                                    marginBottom: '16px',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        color: '#ED1C24',
                                                                        fontSize: '14px',
                                                                    }}
                                                                >
                                                                    {inputError.message}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                        <Button
                                                            variant="outlined"
                                                            type='submit'
                                                            onClick={() => handleSubmitCustomVariant(index, field.id)}
                                                            // onClick={handleAddTextFieldCOlor}
                                                            sx={{
                                                                border: '1px solid #FF6550',
                                                                borderRadius: '8px',
                                                                color: '#FF6550',
                                                                textTransform: 'none',
                                                                '&:hover': {
                                                                    border: '1px solid #FF6550',
                                                                    color: '#FF6550',
                                                                    backgroundColor: '#E8D9D7'
                                                                },
                                                            }}
                                                        >
                                                            {/* <AddIcon sx={{ marginRight: '10px', fontSize: '14px' }} /> */}
                                                            Add {field.name}
                                                        </Button>
                                                    </Box>
                                                </DialogContent>
                                            </Dialog>
                                        </Box>
                                    </Box>
                                    )
                                )
                            }
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: '14px', color: '#021F3F', width: '100px' }}>Custom</Typography>
                                <Box>
                                    <Button
                                        variant="outlined"
                                        onClick={handleAddCustomClick}
                                        sx={{
                                            border: '1px solid #FF6550',
                                            borderRadius: '8px',
                                            color: '#FF6550',
                                            textTransform: 'none',
                                            '&:hover': {
                                                border: '1px solid #FF6550',
                                                color: '#FF6550',
                                                backgroundColor: '#E8D9D7'
                                            },
                                        }}
                                    >
                                        <AddIcon sx={{ marginRight: '5px', fontSize: '14px' }} />
                                        Add Custom
                                    </Button>
                                    <Dialog
                                        fullWidth

                                        open={openAddCustom}
                                        onClose={handleAddCustomClick}
                                    >
                                        <DialogTitle
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography sx={{ fontSize: '22px', color: '#021F3F' }}>
                                                Add Custom Variant
                                            </Typography>
                                            <Box
                                                component="img"
                                                src={CrossIcon}
                                                sx={{
                                                    width: '18px',
                                                    height: '18px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={handleAddCustomClick}
                                            />
                                        </DialogTitle>
                                        <DialogContent>
                                            <Box sx={{ marginTop: '5px' }}>
                                                {/* {
                                        colorInputField.map((field, index) => ( */}

                                                <CssTextField
                                                    // key={field}
                                                    // required
                                                    fullWidth
                                                    id="outlined"
                                                    name='color'
                                                    type="text"
                                                    // label={`Color ${index + 1}`}
                                                    label='Custom Variant'
                                                    placeholder="Ex- Brand or Material"
                                                    sx={{ marginBottom: '16px' }}
                                                    // value={field}
                                                    onChange={e => custom = e.target.value}
                                                    InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                                    InputProps={{ sx: { fontFamily: 'Figtree' } }}

                                                />
                                                {
                                                    inputError.status &&
                                                    <Box
                                                        sx={{
                                                            backgroundColor: 'rgba(237, 28, 36, 0.1)',
                                                            padding: '14px 16px',
                                                            borderRadius: '8px',
                                                            marginBottom: '16px',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: '#ED1C24',
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            {inputError.message}
                                                        </Typography>
                                                    </Box>
                                                }
                                                <Button
                                                    variant="outlined"
                                                    type='submit'
                                                    onClick={handleSubmitCustom}
                                                    // onClick={handleAddTextFieldCOlor}
                                                    sx={{
                                                        border: '1px solid #FF6550',
                                                        height: '32px',
                                                        borderRadius: '8px',
                                                        color: '#FF6550',
                                                        textTransform: 'none',
                                                        '&:hover': {
                                                            border: '1px solid #FF6550',
                                                            color: '#FF6550',
                                                            backgroundColor: '#E8D9D7'
                                                        },
                                                    }}
                                                >
                                                    Add Custom
                                                </Button>
                                            </Box>
                                        </DialogContent>
                                    </Dialog>
                                </Box>
                            </Box>
                        </Box>
                        {
                            variantProducts.length !== 0 && <Box>
                                <Typography sx={{ marginTop: '32px', fontSize: '18px', color: '#021F3F' }}>Preview</Typography>
                                <Grid container spacing={2}>
                                    <Grid item md={6}>
                                        <Typography sx={{ color: '#021F3F', fontSize: '14px', marginBottom: '8px' }}>Variant</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography sx={{ color: '#021F3F', fontSize: '14px', marginBottom: '8px' }}>Per Unit Price</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography sx={{ color: '#021F3F', fontSize: '14px', marginBottom: '8px' }}>In Stock</Typography>
                                    </Grid>

                                </Grid>

                                <hr style={{ marginBottom: '16px' }} />
                                {
                                    variantProducts.map((r, index) =>
                                        <Grid container spacing={2} >
                                            <Grid item md={6}>
                                                <Box sx={{ display: 'flex' }}>
                                                    <div>
                                                        <label className='variantImage'>
                                                            <EditVariantModal selectedImages={selectedImages} index={index} handleVariantImage={handleVariantImage} variantProd={r} handleVariantImageId={handleVariantImageId} />
                                                            <br />

                                                        </label>
                                                        <br />
                                                    </div>
                                                    <Box
                                                        sx={{
                                                            border: '1px solid #95989A',
                                                            borderRadius: '4px',
                                                            marginLeft: '10px',
                                                            height: '40px',
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Typography sx={{ color: '#95989A', fontSize: '14px' }}>{r.title}</Typography>

                                                    </Box>

                                                </Box>
                                            </Grid>
                                            <Grid item md={3}>
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    id="outlined-required"
                                                    name='price'
                                                    type="text"
                                                    placeholder="Enter Price"
                                                    defaultValue={r?.selling_price}
                                                    // value={r?.selling_price}
                                                    onChange={(e) => handleVariantPrice(index, e)}
                                                    InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                                    InputProps={{ sx: { fontFamily: 'Figtree', fontSize: '14px', height: '40px', paddingBottom: '5px' } }}
                                                />
                                            </Grid>
                                            <Grid item md={3}>
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    id="outlined-required"
                                                    type='number'
                                                    name='quantity'
                                                    placeholder="Enter Stock"
                                                    defaultValue={r?.in_stock}
                                                    // value={values.quantity}
                                                    onChange={(e) => handleVariantStock(index, e)}
                                                    InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                                    InputProps={{ sx: { fontFamily: 'Figtree', fontSize: '14px', height: '40px', paddingBottom: '5px' } }}
                                                />

                                            </Grid>
                                        </Grid>)
                                }

                            </Box>
                        }
                    </Box>
                </Grid>
            </Grid>
            <Button
                disableElevation
                disabled={click}
                onClick={(e) => handleUpdate(e)}
                sx={{
                    backgroundColor: '#FF6550',
                    marginLeft: '80%',
                    textTransform: 'none',
                    color: '#FFFFFF',
                    borderRadius: '6px',
                    fontSize: '14px',
                    paddingX: '12px',
                    fontFamily: 'Figtree',
                    '&:hover': { backgroundColor: '#ED5E4A' }
                }}
            >
                {
                    isLoading ?
                        <CircularProgress
                            size={25}
                            sx={{
                                color: '#FFFFFF',
                                padding: '5px 75px',
                            }} />
                        :
                        'Update Product'
                }
            </Button>
        </Box >
    );
};

export default EditProducts;