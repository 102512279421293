import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DashboardIconFilled from '@mui/icons-material/Dashboard';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'; import InventoryIconFilled from '@mui/icons-material/Inventory';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CampaignIcon from '@mui/icons-material/Campaign';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ExtensionIcon from '@mui/icons-material/Extension';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import axios from '../../../Pages/InstanceAxios/InstanceAxios';
import { useContext } from 'react';
import { AuthContext } from '../../../Contexts/AuthContext';
import SideNavList from '../SideNavList/SideNavList';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const sideNavData = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        icon: <DashboardOutlinedIcon sx={{ color: '#FFFFFF', width: '41px' }} />,
        selectedIcon: <DashboardIconFilled sx={{ color: '#FFFFFF', width: '41px' }} />
    },
    {
        title: 'Inventory',
        icon: <Inventory2OutlinedIcon sx={{ color: '#FFFFFF', width: '41px' }} />,
        selectedIcon: <InventoryIconFilled sx={{ color: '#FFFFFF', width: '41px' }} />,
        subNav: [
            {
                title: 'Products',
                path: '/inventory/products',
                dataAccess: 'totalProducts'
            },
            {
                title: 'Collections',
                path: '/inventory/collections',
                dataAccess: 'totalCategories'
            },
            // {
            //     title: 'Featured',
            //     path: '/inventory/featured',
            // },
        ]
    },
    {
        title: 'Online Order',
        path: '/orders',
        icon: <NoteAltOutlinedIcon sx={{ color: '#FFFFFF', width: '41px' }} />,
        selectedIcon: <NoteAltIcon sx={{ color: '#FFFFFF', width: '41px' }} />
    },
    {
        title: 'Customers',
        path: '/customers',
        icon: <PeopleAltOutlinedIcon sx={{ color: '#FFFFFF', width: '41px' }} />,
        selectedIcon: <PeopleAltIcon sx={{ color: '#FFFFFF', width: '41px' }} />
    },
    {
        title: 'Campaigns',
        path: '/campaigns',
        icon: <CampaignOutlinedIcon sx={{ color: '#FFFFFF', width: '41px' }} />,
        selectedIcon: <CampaignIcon sx={{ color: '#FFFFFF', width: '41px' }} />,
    },
    // {
    //     title: 'Report',
    //     path: '/report',
    //     icon: <SummarizeOutlinedIcon sx={{ color: '#FFFFFF', width: '41px' }} />
    // },
    {
        title: 'Plugins',
        path: '/plugins',
        icon: <ExtensionOutlinedIcon sx={{ color: '#FFFFFF', width: '41px' }} />,
        selectedIcon: <ExtensionIcon sx={{ color: '#FFFFFF', width: '41px' }} />
    },
    {
        title: 'Settings',
        path: '/settings/subscription',
        icon: <SettingsOutlinedIcon sx={{ color: '#FFFFFF', width: '41px' }} />,
        selectedIcon: <SettingsIcon sx={{ color: '#FFFFFF', width: '41px' }} />
    },
]

const SideNav = ({ children }) => {
    const [selectedItem, setSelectedItem] = useState(0);
    const { setSubscribedPlan, setPluginsStatus } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleChangeSelectedItem = (index) => {
        setSelectedItem(index);
        if (index === 1) {
            const currentPath = window.location.href.split('/')[3];
            if (currentPath !== 'inventory') {
                navigate('/inventory/products');
            }
        }
    }

    const getSubscribedPlan = async () => {
        try {
            const { merchant_id } = jwtDecode(localStorage.getItem('token'));
            const url = `/subscriptions/getSubscribedplan/${merchant_id}`;
            const res = await axios.get(url);
            setSubscribedPlan(res.data?.subscribed_plan);
        } catch (error) {
            console.log(error);
        }
    }

    const getPluginStatus = async () => {
        const data = jwtDecode(localStorage.getItem('token'));
        const url = `/plugins/getPluginStatusByShopId/${data.shop_id}`;
        const res = await axios.get(url);
        setPluginsStatus(res.data);
    }

    useEffect(() => {
        const currentPath = window.location.href.split('/')[3];
        let index = sideNavData.findIndex(item => item.path?.includes(currentPath));
        if (index === -1) {
            if (currentPath === 'inventory') {
                index = 1;
            }
        }
        setSelectedItem(index);
        getSubscribedPlan();
        getPluginStatus();
    }, []);

    return (
        <div style={{ display: 'flex' }}>
            <Box sx={{ width: '343px', backgroundColor: '#021F3F', height: '89vh' }}>
                <Box sx={{ paddingTop: '38px' }}>
                    {
                        sideNavData?.map((item, index) =>
                            <SideNavList
                                item={item}
                                key={index}
                                index={index}
                                selectedItem={selectedItem}
                                handleChangeSelectedItem={handleChangeSelectedItem}
                            />
                        )
                    }
                </Box>
            </Box>
            <main>{children}</main>
        </div>
    );
};

export default SideNav;