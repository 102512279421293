import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputBase, InputLabel, MenuItem, Paper, Radio, Select, TextField, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import Checkbox from '@mui/material/Checkbox';
import CancelIcon from '@mui/icons-material/Cancel';
import { v4 as uuidv4 } from 'uuid';
import VariantModal from '../VariantModal/VariantModal';
import axios from '../../../InstanceAxios/InstanceAxios'
import SelectCollection from '../SelectCollection/SelectCollection';
import { useNavigate } from 'react-router-dom';
import { getShopInfo } from '../../../../utilities/StoredData';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import mixpanel from 'mixpanel-browser';
import { ADD_PRODUCT, TOTAL_PRODUCTS } from '../../../../utilities/MixpanelConst';
import { NotificationContext } from '../../../../Contexts/NotificationContext';
import { useContext } from 'react';
import { useEffect } from 'react';
import { AuthContext } from '../../../../Contexts/AuthContext';
import CloseIcon from '@mui/icons-material/Close';
import CrossIcon from '../../../../Images/cross.png';
// import CssTextField from '../../../../components/TextField/TextField';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

const getFormValues = () => {
    return {
        product_name: '',
        product_description: '',
        product_collection: 0,
        unit_name: '',
        price: 0,
        quantity: 0,
    }
};

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
    listStyle: 'none'
}));

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#021F3F',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#021F3F',
        },
    },
});

const CssInputLabel = styled(Select)({

    // '& label.Mui-focused': {
    //     color: '#021F3F',
    // },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#021F3F',
    },

});


const AddProducts = () => {
    const { showNotification } = useContext(NotificationContext);

    const { shopDetails } = useContext(AuthContext);
    const navigate = useNavigate()
    const info = getShopInfo()
    // const [click, setClick] = useState(false)
    const [values, setValues] = useState(getFormValues);
    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [openAddColor, setOpenAddColor] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [images, setImages] = useState([]);
    const [collections, setCollections] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [colorInputField, setColorInputField] = useState([])
    const [openAddSize, setOpenAddSize] = useState(false);
    const [sizeInputField, setSizeInputField] = useState([])
    const [openAddCustom, setOpenAddCustom] = useState(false);
    // const [customInputField, setCustomInputField] = useState([])
    const [customInputField, setCustomInputField] = useState([])
    const [validation, setValidation] = useState({
        quantity: false,
        product_description: false,
        product_collection: false,
        price: false,
        unit_name: false,
        product_name: false,
        images: false,
        variantPrice: false,
        variantStock: false,
    });
    const [variants, setVariants] = useState([]);
    const [variantProducts, setVariantProducts] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [inputError, setInputError] = useState({
        status: false,
        message: ''
    });
    let color = '';
    let size = '';
    let custom = '';

    const handleChange = (e) => {
        setValues((previousValues) => ({
            ...previousValues,
            [e.target.name]: e.target.value,
        }));
    }

    const onSelectFile = e => {
        const selectedFiles = e.target.files;
        setImages(e.target.files)
        const selectedFilesArray = Array.from(selectedFiles);
        const imagesArray = selectedFilesArray?.map((file) => {
            return URL.createObjectURL(file);
        });
        setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    }

    const handleCancelImage = (id) => {
        setSelectedImages((img) => img.filter((field, index) => index !== id))
    }

    const handleClickChecked = (event) => {
        setChecked(!checked);
    };

    const handleAddColorClick = () => {
        setOpenAddColor(!openAddColor);
        setInputError({
            status: false,
            message: ''
        });
    }

    const handleSubmit = () => {
        if (colorInputField.includes(color)) {
            setInputError({
                status: true,
                message: `Color ${color} already exists`
            })
            return;
        } else {
            setInputError({
                status: false,
                message: ''
            });
        }
        if (color.length) {
            setColorInputField(current => [...current, color]);
            setOpenAddColor(!openAddColor);
        }
    }

    const handleDelete = (f) => () => {
        const indexF = colorInputField.indexOf(f);
        setColorInputField((colorInputField) => colorInputField.filter((field, index) => index !== indexF))
    };

    //add Size
    const handleAddSizeClick = () => {
        setOpenAddSize(!openAddSize);
        setInputError({
            status: false,
            message: ''
        });
    }

    const handleSubmitSize = () => {
        if (sizeInputField.includes(size)) {
            setInputError({
                status: true,
                message: `Size ${size} already exists`
            });
            return;
        } else {
            setInputError({
                status: false,
                message: ''
            });
        }
        if (size.length) {
            setSizeInputField(current => [...current, size]);
            setOpenAddSize(!openAddSize);
        }
    }

    const handleDeleteSize = (f) => () => {
        const indexF = sizeInputField.indexOf(f);
        setSizeInputField((sizeInputField) => sizeInputField.filter((field, index) => index !== indexF))
    };

    //add Custom
    const handleAddCustomClick = () => {
        setOpenAddCustom(!openAddCustom);
    }

    const handleSubmitCustom = () => {
        if (customInputField.find(a => a.name === custom)) {
            setInputError({
                status: true,
                message: `Custom variant ${custom} already exists`
            });
            return;
        } else {
            setInputError({
                status: false,
                message: ''
            });
        }
        if (custom.length) {
            setCustomInputField(current => [
                ...current,
                { id: uuidv4(), name: custom, values: [] }
            ]);
            setOpenAddCustom(!openAddCustom);
        }
    }

    const handleDeleteCustom = (f, indexField, id) => () => {
        const indexF = customInputField[indexField].values.indexOf(f);
        const updateField = customInputField[indexField].values.filter((field, index) => index !== indexF)
        const newField = [...customInputField];
        const field = newField.find(a => a.id === id)
        field.values = updateField
        setCustomInputField(newField)
    };

    const handleSubmitCustomVariant = (index, id) => {
        const newVariant = customInputField[index].values
        newVariant.push(custom)
        const newField = [...customInputField];
        const field = newField.find(a => a.id === id);
        field.values = newVariant;
        setCustomInputField(newField)
    }

    const handleVariantDialog = (index) => {
        const newField = [...customInputField];
        const field = newField.find(a => a.id === index);
        field.isOpen = !field.isOpen;
        setCustomInputField(newField)
    }
    //collection modal
    const handleClickOpen = () => {
        setOpen(true);
        getCollections()
    };

    const handleClose = () => {
        setOpen(false);
    };

    const updateVariantProducts = () => {
        const allVariants = [
            ...customInputField,
            { name: 'color', values: colorInputField },
            { name: 'size', values: sizeInputField }
        ];
        const tempVariants = allVariants.filter(r => r.values.length !== 0);
        setVariants(tempVariants);
        let result = [];
        tempVariants.forEach((variant, i) => {
            let varTemp = [];
            variant.values.forEach((val) => {
                let valTemp = [];
                if (i === 0) {
                    let variants = []
                    variants.push({ 'name': variant.name, 'value': val })
                    result.push({ 'title': val, 'variants': variants, 'selling_price': 0, 'in_stock': 0, 'image_index': -1 });
                } else {
                    result.forEach((res, idx) => {
                        let variants = [...result[idx].variants];
                        variants.push({ 'name': variant.name, 'value': val })
                        valTemp.push({ 'title': `${res.title} - ${val}`, 'variants': variants, 'selling_price': 0, 'in_stock': 0, 'image_index': -1 });
                    });
                    varTemp.push(...valTemp);
                }
            });
            if (i !== 0) {
                result = [...varTemp];
            }
        })
        setVariantProducts(result);
    }

    const handleVariantPrice = (index, e) => {
        const tempVariant = [...variantProducts];
        tempVariant[index].selling_price = e.target.value;
        setVariantProducts(tempVariant);
    }

    const handleVariantStock = (index, e) => {
        const tempVariant = [...variantProducts];
        tempVariant[index].in_stock = e.target.value;
        setVariantProducts(tempVariant);
    }

    const handleVariantImage = (id, index) => {
        const tempVariant = [...variantProducts];
        tempVariant[index].image_index = id;
    }

    const getCollections = async () => {
        const res = await axios.get(`productCategories/allProductCategories?shop_id=${info.shop_id}`)
        setCollections(res.data.Product_Categories);
    }

    const handleClickCollections = (e, t) => {
        const temp = values
        temp.product_collection = e
        temp.product_collection_name = t
        setValues(temp)
    }

    const ValidationTest = () => {
        if (values.product_name === '') {
            let temp = { ...validation };
            temp.product_name = true
            setValidation(temp)
            return false
        }
        setValidation(validation.product_name = false)
        if (values.product_collection === 0) {
            let temp = { ...validation };
            temp.product_collection = 'Please select a category'
            setValidation(temp)
            return false
        }
        setValidation(validation.product_collection = false)
        if (values.product_description === '') {
            let temp = { ...validation };
            temp.product_description = 'Please enter product description'
            setValidation(temp)
            return false
        }
        setValidation(validation.product_description = false)
        if (parseInt(values.price) < 0) {
            let temp = { ...validation };
            temp.price = true
            setValidation(temp)
            return false
        }
        setValidation(validation.price = false)
        if (parseInt(values.quantity) < 0) {
            let temp = { ...validation };
            temp.quantity = 'Please enter In stock quantity'
            setValidation(temp)
            return false
        }
        setValidation(validation.quantity = false)
        if (values.unit_name === '') {
            let temp = { ...validation };
            temp.unit_name = true
            setValidation(temp)
            return false
        }
        setValidation(validation.unit_name = false)
        if (selectedImages.length === 0) {
            let temp = { ...validation };
            temp.images = 'Select at least one image'
            setValidation(temp)
            return false
        }
        setValidation(validation.images = false)

        let p = 0
        variantProducts.length !== 0 && variantProducts.map((r) => {
            if (r.selling_price === 0) {
                let temp = { ...validation };
                temp.variantPrice = 'Please enter price'
                setValidation(temp)
                return p++
            }
            setValidation(validation.variantPrice = false)
            if (r.in_stock < 0) {
                let temp = { ...validation };
                temp.variantStock = 'Please enter in stock quantity'
                setValidation(temp)
                return p++
            }
            setValidation(validation.variantStock = false)

        });
        if (p > 0) {
            return false
        }
        return true
    }

    const handleVariantSubmit = async (e) => {
        try {
            e.preventDefault();
            const test = ValidationTest();
            if (test === true) {
                setIsLoading(true);
                const formData = new FormData();
                for (const file of images) {
                    formData.append('images', file);
                }
                formData.append('shop_id', info.shop_id)
                formData.append('product_name', values.product_name)
                formData.append('unit_name', values.unit_name)
                formData.append('category_id', values.product_collection)
                formData.append('unit_selling_price', parseInt(values.price))
                formData.append('product_description', values.product_description)
                formData.append('publish_status', checked)
                formData.append('quantity', parseInt(values.quantity))
                formData.append('variants', JSON.stringify(variants))
                formData.append('variant_products', JSON.stringify(variantProducts))

                const url = '/products/addProductV4'
                const res = await axios.post(url, formData);
                setIsLoading(false);
                if (res.data.status === 'S108') {
                    mixpanel.track(ADD_PRODUCT);
                    mixpanel.people.increment(TOTAL_PRODUCTS, 1.0);
                    navigate('/inventory/products');
                    showNotification('success', 'Product added successfully');
                } else {
                    showNotification('error', 'Failed to add product');
                }
            }
        } catch (error) {
            showNotification('error', 'Failed to add product');
        } finally {
            setIsLoading(false);
        }
    }

    const getAllCurrencies = async () => {
        try {
            const currencyList = localStorage.getItem('currencyList');
            if (currencyList) {
                setCurrencyList(JSON.parse(currencyList));
            } else {
                const url = `/currencies/getAllCurrencies`;
                const res = await axios.get(url);
                setCurrencyList(res.data);
                localStorage.setItem('currencyList', JSON.stringify(res.data));
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        updateVariantProducts();
    }, [customInputField, colorInputField, sizeInputField]);



    useEffect(() => {
        getAllCurrencies();
    }, []);

    return (
        <div className='scroll' style={{ overflowY: 'auto', height: '85vh' }}>
            <Grid container sx={{ marginX: '32px' }}>
                <Grid item md={6} sx={{ textAlign: 'left' }}>
                    <Box sx={{ marginRight: '48px', backgroundColor: '#FFFFFF', padding: '24px' }}>
                        <Typography sx={{ marginBottom: '24px', fontSize: '22px', color: '#021F3F' }}>About Product</Typography>
                        <CssTextField
                            required
                            fullWidth
                            id="outlined-required"
                            label="Product Name"
                            name='product_name'
                            type="text"
                            error={validation?.product_name}
                            placeholder="Enter Product Name"
                            sx={{ marginBottom: '16px' }}
                            value={values.product_name}
                            onChange={handleChange}
                            InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                            InputProps={{ sx: { fontFamily: 'Figtree' } }}
                        />
                        {/* collection selection */}

                        <Box sx={{ marginBottom: '16px' }}>
                            <CssTextField
                                required
                                disabled
                                fullWidth
                                id="outlined-required"
                                label=""
                                name='product_collection'
                                type="text"
                                // sx={{ 'input': { '&::placeholder': { color: 'red' } } }}
                                error={validation?.product_collection}
                                placeholder="Select a collection"
                                value={values.product_collection_name}
                                onClick={handleClickOpen}
                                InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                InputProps={{ sx: { fontFamily: 'Figtree' } }}
                            />
                            <Dialog
                                fullWidth
                                open={open}
                                onClose={handleClose}
                            >
                                <DialogTitle>Select Collection</DialogTitle>
                                <DialogContent className='scroll' sx={{ paddingX: '0px', height: '50%' }}>
                                    <DialogContentText sx={{ paddingLeft: '24px' }} onClick={() => navigate('/inventory/collection/add')}>
                                        + Add Collection
                                    </DialogContentText>
                                    <Box onClick={handleClose}>
                                        <SelectCollection collections={collections} handleClickCollections={handleClickCollections} />
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    {/* <Button fullWidth variant='contained' sx={{ backgroundColor: '#FF6550', '&:hover': { backgroundColor: '#FF6550' } }}>Save</Button> */}
                                </DialogActions>
                            </Dialog>
                            {
                                validation?.product_collection && <Typography sx={{ color: '#ba000d', fontSize: '14px', marginTop: '-10px' }}>{validation?.product_collection}</Typography>
                            }
                        </Box>

                        <CssTextField
                            required
                            id="outlined-multiline-static-required"
                            label="Product Description"
                            name="product_description"
                            placeholder='Product Description'
                            error={validation?.product_description}
                            multiline
                            fullWidth
                            type="text"
                            rows={5}
                            value={values.product_description}
                            onChange={handleChange}
                            sx={{ marginBottom: '8px' }}
                            InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                            InputProps={{ sx: { fontFamily: 'Figtree' } }}
                        />
                        <Typography sx={{ fontSize: '12px', color: '#95989A', fontFamily: 'Figtree', marginBottom: '16px' }}>*Description should not exceed 2000 characters</Typography>
                        <Grid container spacing={2}>

                            <Grid item md={3}>
                                <FormControl sx={{ minWidth: 120 }}>
                                    <InputLabel
                                        id="demo-simple-select-required-label"
                                        sx={{
                                            "&.Mui-focused": {
                                                color: "#021F3F"
                                            }
                                        }}>Currency</InputLabel>
                                    <CssInputLabel
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        value={shopDetails?.currencies?.id}
                                        autoWidth
                                        label="Currency"
                                        disabled={true}
                                    >
                                        {
                                            currencyList.map((item, index) =>
                                                <MenuItem key={index} value={item.id}>{item.title}</MenuItem>
                                            )
                                        }
                                    </CssInputLabel>
                                </FormControl>
                            </Grid>
                            <Grid item md={3}>
                                <CssTextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Price"
                                    name='price'
                                    type='number'
                                    error={validation?.price}
                                    placeholder="Enter Price"
                                    sx={{ marginBottom: '16px' }}
                                    value={values.price}
                                    onChange={handleChange}
                                    InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                    InputProps={{ sx: { fontFamily: 'Figtree' } }}
                                />
                            </Grid>

                            <Grid item md={3}>
                                <CssTextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Total In Stock Quantity"
                                    name='quantity'
                                    type='number'
                                    error={validation?.quantity}
                                    placeholder="Enter quantity"
                                    sx={{ marginBottom: '16px' }}
                                    value={variantProducts.length ? variantProducts.reduce((acc, curr) => acc + Number(curr.in_stock), 0) : values.quantity}
                                    onChange={handleChange}
                                    InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                    InputProps={{ sx: { fontFamily: 'Figtree' } }}
                                    disabled={variantProducts.length ? true : false}
                                />
                            </Grid>

                            <Grid item md={3}>
                                <FormControl sx={{ minWidth: 120 }}>
                                    <InputLabel
                                        id="demo-simple-select-required-label"
                                        sx={{
                                            "&.Mui-focused": {
                                                color: "#021F3F"
                                            }
                                        }}
                                    >Unit</InputLabel>
                                    <CssInputLabel
                                        labelId="demo-simple-select-required-label"
                                        // className={classes.select}
                                        id="demo-simple-select-required"
                                        value={values.unit_name}
                                        error={validation?.unit_name}
                                        onChange={handleChange}
                                        autoWidth
                                        label="Unit *"
                                        name='unit_name'
                                    >
                                        <MenuItem value=''></MenuItem>
                                        <MenuItem value='Pcs'>Pcs</MenuItem>
                                        <MenuItem value='KG'>KG</MenuItem>
                                        <MenuItem value='gram'>Gram</MenuItem>
                                        <MenuItem value='Liter'>Liter</MenuItem>
                                        <MenuItem value='ml'>ml</MenuItem>
                                        <MenuItem value='Meter'>Meter</MenuItem>

                                    </CssInputLabel>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Typography sx={{ fontSize: '12px', color: '#95989A', fontFamily: 'Figtree' }}>*Change stock in variant to calculate the total</Typography>
                    </Box >
                    <Box sx={{ marginRight: '48px', backgroundColor: '#FFFFFF', padding: '24px', marginTop: '28px' }}>
                        <Typography sx={{ marginBottom: '24px', fontSize: '22px', color: '#021F3F' }}>Product Image</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            {
                                selectedImages &&
                                selectedImages?.map((image, index) => {
                                    return (
                                        <Box>
                                            <dix key={image}>
                                                <img src={image} height='92px' width='92px' style={{ borderRadius: '4px', marginRight: '24px' }} alt='upload' />
                                            </dix>
                                            <Box sx={{ marginTop: '-98px', marginLeft: '70px' }}>
                                                <CancelIcon onClick={() => { handleCancelImage(index) }} sx={{ color: '#ED1C24', zIndex: '2', cursor: 'pointer' }} />
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                            <div>
                                <label className='addImage'>
                                    <UploadFileOutlinedIcon sx={{ width: '20px', height: '23px', marginBottom: '5px' }} />
                                    Add Image
                                    <br />
                                    <input
                                        type="file"
                                        name="images"
                                        onChange={(e) => onSelectFile(e)}
                                        multiple
                                        accept="image/png , image/jpeg, image/webp"
                                    />
                                </label>
                                <br />
                                <input type="file" multiple />
                            </div>
                        </Box>
                        {
                            validation?.images && <Typography sx={{ color: '#ba000d', fontSize: '14px', marginTop: '-10px' }}>{validation?.images}</Typography>
                        }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '29px' }}>
                        <Checkbox
                            checked={checked}
                            onClick={handleClickChecked}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={{ paddingLeft: '0px', color: '#95989A' }}
                        />
                        <Typography sx={{ fontSize: '18px', color: '#95989A', fontFamily: 'Figtree' }}>Publish to Online Shop</Typography>
                    </Box>
                </Grid >
                <Grid item md={6} sx={{ textAlign: 'left' }}>
                    <Box sx={{ backgroundColor: '#FFFFFF', marginRight: '48px', padding: '24px' }}>
                        <Typography sx={{ marginBottom: '24px', fontSize: '22px', color: '#021F3F' }}>Add Variant</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
                            <Typography sx={{ fontSize: '14px', color: '#021F3F', width: '100px' }}>Color</Typography>
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', height: '32px' }}>
                                    <Paper
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            listStyle: 'none',
                                            boxShadow: 'none',
                                            paddingLeft: '0px',
                                            // marginRight: '16px'
                                        }}
                                        component="ul"
                                    >
                                        {colorInputField?.length !== 0 && (
                                            colorInputField?.map((field) =>
                                                <ListItem key={field} sx={{ padding: '0px', margin: '0px' }}>
                                                    <Chip
                                                        label={field}
                                                        onDelete={handleDelete(field)}
                                                        deleteIcon={<CancelOutlinedIcon />}
                                                        sx={{
                                                            border: '1px solid #D1D1D1',
                                                            backgroundColor: '#FFFFFF',
                                                            color: '#021F3F',
                                                            fontSize: '14px',
                                                            borderRadius: '8px',
                                                            textUnderlineOffset: 'none',
                                                            margin: '0px 8px 0px 0px',
                                                        }}
                                                    />
                                                </ListItem>
                                            ))}
                                    </Paper>
                                    <Button
                                        variant="outlined"
                                        onClick={handleAddColorClick}
                                        sx={{
                                            border: '1px solid #FF6550',
                                            height: '32px',
                                            borderRadius: '8px',
                                            color: '#FF6550',
                                            textTransform: 'none',
                                            '&:hover': {
                                                border: '1px solid #FF6550',
                                                color: '#FF6550',
                                                backgroundColor: '#E8D9D7'
                                            },
                                        }}
                                    >
                                        <AddIcon sx={{ marginRight: '5px', fontSize: '14px' }} />
                                        Add Color
                                    </Button>
                                </Box>
                                <Dialog
                                    fullWidth
                                    open={openAddColor}
                                    onClose={handleAddColorClick}
                                >
                                    <DialogTitle
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '22px', color: '#021F3F' }}>
                                            Add Color
                                        </Typography>
                                        <Box
                                            component="img"
                                            src={CrossIcon}
                                            sx={{
                                                width: '18px',
                                                height: '18px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleAddColorClick}
                                        />
                                    </DialogTitle>
                                    <DialogContent>
                                        <Box sx={{ marginTop: '5px' }}>
                                            {/* {
                                            colorInputField.map((field, index) => ( */}


                                            <CssTextField
                                                // key={field}
                                                // required
                                                fullWidth
                                                id="outlined-required"
                                                name='color'
                                                type="text"
                                                // label={`Color ${index + 1}`}
                                                label='Color'
                                                placeholder="Ex- Black"
                                                sx={{ marginBottom: '16px' }}
                                                // value={field}
                                                onChange={e => color = e.target.value}
                                                InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                                InputProps={{ sx: { fontFamily: 'Figtree' } }}
                                            />
                                            {
                                                inputError.status &&
                                                <Box
                                                    sx={{
                                                        backgroundColor: 'rgba(237, 28, 36, 0.1)',
                                                        padding: '14px 16px',
                                                        borderRadius: '8px',
                                                        marginBottom: '16px',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: '#ED1C24',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {inputError.message}
                                                    </Typography>
                                                </Box>
                                            }
                                            <Button
                                                variant="outlined"
                                                type='submit'
                                                onClick={handleSubmit}
                                                // onClick={handleAddTextFieldCOlor}
                                                sx={{
                                                    border: '1px solid #FF6550',
                                                    borderRadius: '8px',
                                                    color: '#FF6550',
                                                    textTransform: 'none',
                                                    '&:hover': {
                                                        border: '1px solid #FF6550',
                                                        color: '#FF6550',
                                                        backgroundColor: '#E8D9D7'
                                                    },
                                                }}
                                            >
                                                {/* <AddIcon sx={{ marginRight: '10px', fontSize: '14px' }} /> */}
                                                Add Color
                                            </Button>
                                        </Box >
                                    </DialogContent >
                                    <DialogActions>
                                        {/* <Button onClick={handleClose}>Close</Button> */}
                                    </DialogActions>
                                </Dialog >
                            </Box >
                        </Box >
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
                            <Typography sx={{ fontSize: '14px', color: '#021F3F', width: '100px' }}>Size</Typography>
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', height: '32px' }}>
                                    <Paper
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            paddingLeft: '0px',
                                            flexWrap: 'wrap',
                                            listStyle: 'none',
                                            boxShadow: 'none',
                                            // marginRight: '16px'
                                        }}
                                        component="ul"
                                    >
                                        {sizeInputField?.length !== 0 && (
                                            sizeInputField?.map((field) =>
                                                <ListItem key={field} sx={{ padding: '0px', margin: '0px' }}>
                                                    <Chip
                                                        label={field}
                                                        onDelete={handleDeleteSize(field)}
                                                        deleteIcon={<CancelOutlinedIcon />}
                                                        sx={{
                                                            border: '1px solid #D1D1D1',
                                                            backgroundColor: '#FFFFFF',
                                                            color: '#021F3F',
                                                            fontSize: '14px',
                                                            borderRadius: '8px',
                                                            margin: '0px 8px 0px 0px',
                                                        }}
                                                    />
                                                </ListItem>
                                            ))}
                                    </Paper>
                                    <Button
                                        variant="outlined"
                                        onClick={handleAddSizeClick}
                                        sx={{
                                            border: '1px solid #FF6550',
                                            borderRadius: '8px',
                                            color: '#FF6550',
                                            textTransform: 'none',
                                            '&:hover': {
                                                border: '1px solid #FF6550',
                                                color: '#FF6550',
                                                backgroundColor: '#E8D9D7'
                                            },
                                        }}
                                    >
                                        <AddIcon sx={{ marginRight: '5px', fontSize: '14px' }} />
                                        Add Size
                                    </Button>
                                </Box>
                                <Dialog
                                    fullWidth
                                    open={openAddSize}
                                    onClose={handleAddSizeClick}
                                >
                                    <DialogTitle
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '22px',
                                                color: '#021F3F'
                                            }}
                                        >
                                            Add Size
                                        </Typography>
                                        <Box
                                            component="img"
                                            src={CrossIcon}
                                            sx={{
                                                width: '18px',
                                                height: '18px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleAddSizeClick}
                                        />
                                    </DialogTitle>
                                    <DialogContent>
                                        <Box sx={{ marginTop: '5px' }}>
                                            {/* {
                                            colorInputField.map((field, index) => ( */}

                                            <CssTextField
                                                // key={field}
                                                // required
                                                fullWidth
                                                id="outlined-required"
                                                name='color'
                                                type="text"
                                                // label={`Color ${index + 1}`}
                                                label='Size'
                                                placeholder="Ex- 5 or S or small"
                                                sx={{ marginBottom: '16px' }}
                                                // value={field}
                                                onChange={e => size = e.target.value}
                                                InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                                InputProps={{ sx: { fontFamily: 'Figtree' } }}
                                            />
                                            {
                                                inputError.status &&
                                                <Box
                                                    sx={{
                                                        backgroundColor: 'rgba(237, 28, 36, 0.1)',
                                                        padding: '14px 16px',
                                                        borderRadius: '8px',
                                                        marginBottom: '16px',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: '#ED1C24',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {inputError.message}
                                                    </Typography>
                                                </Box>
                                            }
                                            <Button
                                                variant="outlined"
                                                type='submit'
                                                onClick={handleSubmitSize}
                                                // onClick={handleAddTextFieldCOlor}
                                                sx={{
                                                    border: '1px solid #FF6550',
                                                    borderRadius: '8px',
                                                    color: '#FF6550',
                                                    textTransform: 'none',
                                                    '&:hover': {
                                                        border: '1px solid #FF6550',
                                                        color: '#FF6550',
                                                        backgroundColor: '#E8D9D7'
                                                    },
                                                }}
                                            >
                                                {/* <AddIcon sx={{ marginRight: '10px', fontSize: '14px' }} /> */}
                                                Add Size
                                            </Button>
                                        </Box >
                                    </DialogContent >
                                    <DialogActions>
                                        {/* <Button onClick={handleClose}>Close</Button> */}
                                    </DialogActions>
                                </Dialog >
                            </Box >
                        </Box >
                        <Box>
                            {
                                customInputField?.length !== 0 && (
                                    customInputField?.map((field, index) =>
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
                                            <Typography sx={{ fontSize: '14px', color: '#021F3F', width: '100px' }}>{field.name}</Typography>
                                            <Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center', height: '32px' }}>
                                                    {field?.values?.length !== 0 && (
                                                        field?.values?.map((item) =>
                                                            <ListItem key={item} sx={{ padding: '0px', margin: '0px' }}>
                                                                <Chip
                                                                    label={item}
                                                                    onDelete={handleDeleteCustom(item, index, field.id)}
                                                                    deleteIcon={<CancelOutlinedIcon />}
                                                                    sx={{
                                                                        border: '1px solid #D1D1D1',
                                                                        backgroundColor: '#FFFFFF',
                                                                        color: '#021F3F',
                                                                        fontSize: '14px',
                                                                        borderRadius: '8px',
                                                                        margin: '0px 8px 0px 0px',
                                                                    }}
                                                                />
                                                            </ListItem>
                                                        ))}
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => handleVariantDialog(field.id)}
                                                        sx={{
                                                            border: '1px solid #FF6550',
                                                            borderRadius: '8px',
                                                            color: '#FF6550',
                                                            textTransform: 'none',
                                                            '&:hover': {
                                                                border: '1px solid #FF6550',
                                                                color: '#FF6550',
                                                                backgroundColor: '#E8D9D7'
                                                            },
                                                        }}
                                                    >
                                                        <AddIcon sx={{ marginRight: '5px', fontSize: '14px' }} />
                                                        Add {field.name}
                                                    </Button>
                                                </Box>
                                                <Dialog
                                                    fullWidth
                                                    open={field.isOpen}
                                                    onClose={() => handleVariantDialog(field.id)}
                                                >
                                                    <DialogTitle
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: '22px', color: '#021F3F' }}>
                                                            Add {field.name}
                                                        </Typography>
                                                        <Box
                                                            component="img"
                                                            src={CrossIcon}
                                                            sx={{
                                                                width: '18px',
                                                                height: '18px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleVariantDialog(field.id)}
                                                        />
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <Box sx={{ marginTop: '5px' }}>

                                                            <CssTextField
                                                                // key={field}
                                                                // required
                                                                fullWidth
                                                                id="outlined-required"
                                                                name='custom-variant'
                                                                type="text"
                                                                // label={`Color ${index + 1}`}
                                                                label='Value'
                                                                placeholder="Ex- Black"
                                                                sx={{ marginBottom: '16px' }}
                                                                // value={field}
                                                                onChange={e => custom = e.target.value}
                                                                InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                                                InputProps={{ sx: { fontFamily: 'Figtree' } }}
                                                            />
                                                            {
                                                                inputError.status &&
                                                                <Box
                                                                    sx={{
                                                                        backgroundColor: 'rgba(237, 28, 36, 0.1)',
                                                                        padding: '14px 16px',
                                                                        borderRadius: '8px',
                                                                        marginBottom: '16px',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            color: '#ED1C24',
                                                                            fontSize: '14px',
                                                                        }}
                                                                    >
                                                                        {inputError.message}
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                            <Button
                                                                variant="outlined"
                                                                type='submit'
                                                                onClick={() => handleSubmitCustomVariant(index, field.id)}
                                                                // onClick={handleAddTextFieldCOlor}
                                                                sx={{
                                                                    border: '1px solid #FF6550',
                                                                    borderRadius: '8px',
                                                                    color: '#FF6550',
                                                                    textTransform: 'none',
                                                                    '&:hover': {
                                                                        border: '1px solid #FF6550',
                                                                        color: '#FF6550',
                                                                        backgroundColor: '#E8D9D7'
                                                                    },
                                                                }}
                                                            >
                                                                {/* <AddIcon sx={{ marginRight: '10px', fontSize: '14px' }} /> */}
                                                                Add {field.name}
                                                            </Button>
                                                        </Box>
                                                    </DialogContent>
                                                </Dialog>
                                            </Box>
                                        </Box>
                                    )
                                )
                            }
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: '14px', color: '#021F3F', width: '100px' }}>Custom</Typography>
                                <Box>
                                    <Button
                                        variant="outlined"
                                        onClick={handleAddCustomClick}
                                        sx={{
                                            border: '1px solid #FF6550',
                                            borderRadius: '8px',
                                            color: '#FF6550',
                                            textTransform: 'none',
                                            '&:hover': {
                                                border: '1px solid #FF6550',
                                                color: '#FF6550',
                                                backgroundColor: '#E8D9D7'
                                            },
                                        }}
                                    >
                                        <AddIcon sx={{ marginRight: '5px', fontSize: '14px' }} />
                                        Add Custom
                                    </Button>
                                    <Dialog
                                        fullWidth
                                        open={openAddCustom}
                                        onClose={handleAddCustomClick}
                                    >
                                        <DialogTitle
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <span>Add Custom Variant</span>
                                            <CloseIcon
                                                sx={{
                                                    cursor: 'pointer',
                                                    color: '#FF6550',
                                                }}
                                                fontSize='medium'
                                                onClick={() => setOpenAddCustom(false)}
                                            />
                                        </DialogTitle>
                                        <DialogContent>
                                            <Box sx={{ marginTop: '5px' }}>
                                                <CssTextField

                                                    // key={field}
                                                    // required
                                                    fullWidth
                                                    id="outlined"
                                                    name='color'
                                                    type="text"
                                                    // label={`Color ${index + 1}`}
                                                    label='Custom Variant'
                                                    placeholder="Ex- Brand or Material"
                                                    sx={{ marginBottom: '16px' }}
                                                    // value={field}
                                                    onChange={e => custom = e.target.value}
                                                    InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                                    InputProps={{ sx: { fontFamily: 'Figtree' } }}
                                                />
                                                {
                                                    inputError.status &&
                                                    <Box
                                                        sx={{
                                                            backgroundColor: 'rgba(237, 28, 36, 0.1)',
                                                            padding: '14px 16px',
                                                            borderRadius: '8px',
                                                            marginBottom: '16px',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: '#ED1C24',
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            {inputError.message}
                                                        </Typography>
                                                    </Box>
                                                }
                                                <Button
                                                    variant="outlined"
                                                    type='submit'
                                                    onClick={handleSubmitCustom}
                                                    // onClick={handleAddTextFieldCOlor}
                                                    sx={{
                                                        border: '1px solid #FF6550',
                                                        height: '32px',
                                                        borderRadius: '8px',
                                                        color: '#FF6550',
                                                        textTransform: 'none',
                                                        '&:hover': {
                                                            border: '1px solid #FF6550',
                                                            color: '#FF6550',
                                                            backgroundColor: '#E8D9D7'
                                                        },
                                                    }}
                                                >
                                                    {/* <AddIcon sx={{ marginRight: '10px', fontSize: '14px' }} /> */}
                                                    Add Custom
                                                </Button>
                                            </Box>
                                        </DialogContent>
                                    </Dialog>
                                </Box>
                            </Box>
                        </Box>
                        {
                            variantProducts.length !== 0 && <Box>
                                <Typography sx={{ marginTop: '32px', fontSize: '18px', color: '#021F3F' }}>Preview</Typography>
                                <Grid container spacing={2}>
                                    <Grid item md={6}>
                                        <Typography sx={{ color: '#021F3F', fontSize: '14px', marginBottom: '8px' }}>Variant</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography sx={{ color: '#021F3F', fontSize: '14px', marginBottom: '8px' }}>Per Unit Price</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography sx={{ color: '#021F3F', fontSize: '14px', marginBottom: '8px' }}>In Stock</Typography>
                                    </Grid>
                                </Grid>
                                <hr style={{ border: '0.5px solid #D1D1D1', marginBottom: '16px' }} />
                                {
                                    variantProducts.map((r, index) => <Grid container spacing={2} >
                                        <Grid item md={6}>
                                            <Box sx={{ display: 'flex' }}>
                                                <div>
                                                    <label className='variantImage'>
                                                        <VariantModal
                                                            selectedImages={selectedImages}
                                                            index={index}
                                                            handleVariantImage={handleVariantImage}
                                                            variantProd={variantProducts}
                                                        />
                                                        <br />
                                                    </label>
                                                    <br />
                                                </div>
                                                <Box sx={{ border: '1px solid #95989A', borderRadius: '4px', marginLeft: '10px', width: '100%', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Typography sx={{ color: '#95989A', fontSize: '14px' }}>{r.title}</Typography>
                                                </Box>
                                            </Box>
                                            {
                                                validation?.variantPrice && <Typography sx={{ color: '#ba000d', fontSize: '14px', marginTop: '-10px' }}>{validation?.variantPrice}</Typography>
                                            }
                                            {
                                                validation?.variantStock && <Typography sx={{ color: '#ba000d', fontSize: '14px', marginTop: '-10px' }}>{validation?.variantStock}</Typography>
                                            }
                                        </Grid>
                                        <Grid item md={3}>
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="outlined-required"
                                                name='price'
                                                type="text"
                                                placeholder="Enter Price"
                                                defaultValue={r?.selling_price}
                                                // value={r?.selling_price}
                                                onChange={(e) => handleVariantPrice(index, e)}
                                                InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                                InputProps={{ sx: { fontFamily: 'Figtree', fontSize: '14px', height: '40px', paddingBottom: '5px' } }}
                                            />
                                        </Grid>

                                        <Grid item md={3}>
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="outlined-required"
                                                type='number'
                                                name='quantity'
                                                placeholder="Enter Stock"
                                                defaultValue={r?.in_stock}
                                                // value={values.quantity}
                                                onChange={(e) => handleVariantStock(index, e)}
                                                InputLabelProps={{ sx: { fontFamily: 'Figtree' } }}
                                                InputProps={{ sx: { fontFamily: 'Figtree', fontSize: '14px', height: '40px', paddingBottom: '5px' } }}
                                            />
                                        </Grid>
                                    </Grid>
                                    )
                                }
                            </Box>
                        }
                    </Box >
                </Grid >
            </Grid >
            <Button
                // disabled={click}
                variant="contained"
                disableElevation
                onClick={(e) => handleVariantSubmit(e)}
                sx={{
                    backgroundColor: '#FF6550',
                    marginLeft: '80%',
                    textTransform: 'none',
                    color: '#FFFFFF',
                    fontSize: '14px',
                    fontFamily: 'Figtree',
                    borderRadius: '6px',
                    marginY: '48px',
                    '&:hover': { backgroundColor: '#FF6550' },
                }}
            >
                {
                    isLoading ?
                        <CircularProgress
                            size={25}
                            sx={{
                                color: '#FFFFFF',
                                padding: '5px 75px',
                            }} />
                        :
                        <>
                            <AddIcon sx={{ marginRight: '10px', fontSize: '18px' }} />
                            Add to Inventory
                        </>
                }
            </Button>
        </div >
    );
};

export default AddProducts;