import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import { AuthContext } from '../../Contexts/AuthContext';

const TableHeader = ({ plan, handleUpgrade, isYearly }) => {
    const { shopDetails } = useContext(AuthContext);

    return (
        <td
            style={{
                minWidth: "173px",
                maxWidth: "173px"
            }}
        >
            <Typography
                sx={{
                    color: "#95989A",
                    fontSize: "18px",
                    padding: "24px 0 8px 0"
                }}
            >
                {plan.title}
            </Typography>
            {
                plan.isUsd ?
                    <Typography
                        sx={{
                            color: "#021F3F",
                            fontSize: "36px",
                        }}
                    >
                        ${isYearly ? plan.yearly_price_usd : plan.monthly_price_usd}
                    </Typography>
                    :
                    <Typography
                        sx={{
                            color: "#021F3F",
                            fontSize: "36px",
                        }}
                    >
                        ৳{isYearly ? plan.yearly_price : plan.monthly_price}
                    </Typography>
            }
            {/* //remove 47 no line to show upgrade button */}
            {
                shopDetails?.shop_link === 'demo' &&
                <Box
                    sx={{
                        padding: "20px 0 28px 0"
                    }}
                >
                    <Button
                        disableElevation
                        variant="contained"
                        sx={{
                            textTransform: 'none',
                            borderRadius: "6px",

                            backgroundColor: '#FF6550',
                            '&:hover': {
                                backgroundColor: '#ED5E4A'
                            }

                        }}
                        onClick={() => handleUpgrade(plan.id)}
                    >
                        Upgrade Plan
                    </Button>
                </Box>
            }
        </td>
    );
};

export default TableHeader;