import { Button, Checkbox, FormControl, FormControlLabel, Grid, OutlinedInput, styled, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import BonikLogo from '../../Images/bonik-logo.png';
import OnboardSlider from '../Shared/OnboardSlider/OnboardSlider';
import axios from '../InstanceAxios/InstanceAxios';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../Contexts/AuthContext';
import LoginHooks from '../../components/LoginHooks/LoginHooks';
import { ErrorContext } from '../../Contexts/ErrorContext';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#021F3F',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#021F3F',
        },
    },
});

const Login = () => {
    const { login } = useContext(AuthContext);
    const { error, setError } = useContext(ErrorContext);
    const [mobileNumber, setMobileNumber] = useState("");
    const [pin, setPin] = useState("");
    const [checkBox, setCheckBox] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (checkBox) {
            const userData = {
                merchant_id: mobileNumber,
                password: pin,
            }
            localStorage.setItem("loginData", JSON.stringify(userData));
        } else {
            localStorage.removeItem("loginData");
        }
        setError((prev) => ({ ...prev, loginPageError: '' }));
        login({ mobileNumber, pin });
    }

    const handleCheckBox = (e) => {
        setCheckBox(e.target.checked);
    }

    const retrieveLocalData = async () => {
        if (localStorage.getItem("loginData")) {
            const userData = await JSON.parse(localStorage.getItem("loginData"));
            setMobileNumber(userData.merchant_id);
            setPin(userData.password);
            setCheckBox(true);
        }
    }

    useEffect(() => {
        if (mobileNumber?.length && pin?.length === 5) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [mobileNumber, pin]);

    useEffect(() => {
        retrieveLocalData();
    }, []);

    return (
        <Grid container>
            <Grid item lg={5}>
                <Box sx={{ margin: { md: "0 112px", xs: "0 35px" } }}>
                    <Box sx={{ marginTop: "50px" }}>
                        <img src={BonikLogo} alt="Bonik Logo" height={"40px"} />
                    </Box>
                    <Box sx={{ margin: "35px 0" }}>
                        <Typography variant="body1" sx={{ fontSize: "24px", fontFamily: "Figtree" }}>
                            Log In
                        </Typography>
                    </Box>
                    {
                        error?.loginPageError &&
                        <Box
                            sx={{
                                backgroundColor: 'rgba(237, 28, 36, 0.1)',
                                margin: '8px 0',
                                padding: "14px 16px",
                                borderRadius: "8px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontFamily: "Figtree",
                                    color: "#ED1C24",
                                }}
                            >
                                {error?.loginPageError}
                            </Typography>
                        </Box>
                    }
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <FormControl sx={{ width: '100%' }}>
                            <CssTextField
                                placeholder="Your Phone Number"
                                sx={{ margin: "8px 0" }}
                                aria-describedby="my-helper-text"
                                value={mobileNumber}
                                onChange={(e) => setMobileNumber(e.target.value)}
                            />
                            {/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
                        </FormControl>
                        <FormControl sx={{ width: '100%' }}>
                            <CssTextField
                                placeholder="Pincode"
                                sx={{ margin: "8px 0" }}
                                type="password"
                                value={pin}
                                onChange={(e) => setPin(e.target.value)}
                            />
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox
                                checked={checkBox}
                                onChange={(e) => handleCheckBox(e)}
                            />}
                            sx={{
                                margin: "8px 0",
                                color: "#95989A",
                                padding: "0",
                                '& .MuiCheckbox-root': {
                                    padding: "0",
                                    marginRight: "8px",
                                }
                            }}
                            label="Remember Me"
                        />
                        <Button
                            type='submit'
                            variant="contained"
                            disableElevation
                            disabled={isDisabled}
                            sx={{
                                width: '100%',
                                backgroundColor: "#FF6550",
                                marginTop: "24px",
                                textTransform: 'none',
                                height: "48px",
                                fontSize: "16px",
                                '&:hover': { backgroundColor: '#FF6550' }
                            }}>
                            Log In
                        </Button>
                        <LoginHooks />
                        <Typography variant="body1" sx={{ fontSize: "14px", fontFamily: "Figtree", margin: "24px 0 50px 0", textAlign: "center", color: "#FF6550" }}>
                            <span onClick={() => navigate('/forgotPincode')}>Forgot Pin?</span>
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={7} sx={{ display: { xs: 'none', lg: 'block' } }}>
                <OnboardSlider />
            </Grid>
        </Grid>
    );
};

export default Login;
