import jwtDecode from 'jwt-decode';
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../Pages/InstanceAxios/InstanceAxios';
import mixpanel from 'mixpanel-browser';
import { LOG_IN } from '../utilities/MixpanelConst';
import jwt_decode from 'jwt-decode';
import { NotificationContext } from './NotificationContext';
import { ErrorContext } from './ErrorContext';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [subscribedPlan, setSubscribedPlan] = useState({});
    const [pluginsStatus, setPluginsStatus] = useState({});
    const [shopDetails, setShopDetails] = useState({});
    const [navbarData, setNavbarData] = useState({
        totalProducts: 0,
        totalCategories: 0,
    });
    const { showNotification } = useContext(NotificationContext);
    const { setError } = useContext(ErrorContext);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard";

    const getShopDetails = async () => {
        try {
            const { shop_link } = jwtDecode(localStorage.getItem('token'));
            const url = `/merchants/shopV4/${shop_link}`;
            const res = await axios.get(url);
            setShopDetails(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    const login = async (user) => {
        try {
            setIsLoading(true);
            const response = await axios.post("/merchants/login", {
                merchant_id: user.mobileNumber,
                password: user.pin
            });
            if (response?.data?.status === "S125") {
                setToken(response.data.accessToken);
                localStorage.setItem('token', response.data.accessToken);
                setIsLoading(false);

                //mixpanel
                mixpanel.alias(jwt_decode(response.data.accessToken).merchant_id, `${Number(user.mobileNumber)}`)
                mixpanel.identify(jwt_decode(response.data.accessToken).merchant_id)
                mixpanel.track(LOG_IN)

                navigate(from, { replace: true });
            } else if (response?.data?.status === "E125") {
                setIsLoading(false);
                setError({ loginPageError: 'The phone number or password you entered is incorrect.'});
            }
        } catch (e) {
            setIsLoading(false);
            setError({ loginPageError: 'Something went wrong, please try again later'});
            console.log(e);
        }
    };

    const googleLogin = async (id_token) => {
        try {
            setIsLoading(true);
            const response = await axios.post("/auth/google-login", {
                id_token
            });
            if (response?.data?.status === "S125") {
                setToken(response.data.accessToken);
                localStorage.setItem('token', response.data.accessToken);
                setIsLoading(false);
                navigate(from, { replace: true });
                if (jwtDecode(response.data.accessToken)?.shop_id == null) {
                    navigate('createStore');
                } else {
                    navigate(from, { replace: true });
                }
            }
        } catch (e) {
            setIsLoading(false);
            console.log(e);
        }
    };

    const logout = async () => {
        try {
            setIsLoading(true);
            await axios.get("/merchants/logout");
            localStorage.removeItem('token');
            setToken(null);

            //mixpanel
            mixpanel.track("Log Out")
            mixpanel.reset()

            setIsLoading(false);
            navigate('/signup');
        }
        catch (e) {
            console.log(e);
        }
    };

    const checkAuth = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            if (token) {
                setToken(token);
            }
            setIsLoading(false);
        }
        catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    };


    useEffect(() => {
        checkAuth();
    }, []);

    const authInfo = {
        token,
        setToken,
        login,
        logout,
        checkAuth,
        isLoading,
        setIsLoading,
        subscribedPlan,
        setSubscribedPlan,
        pluginsStatus,
        setPluginsStatus,
        googleLogin,
        shopDetails,
        getShopDetails,
        navbarData,
        setNavbarData
    };

    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;