import { Button, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useState } from 'react';
import './StoreInformation.css';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import { useEffect } from 'react';
import axios from '../InstanceAxios/InstanceAxios';
import jwt from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../../Contexts/AuthContext';
import InfoIcon from '@mui/icons-material/Info';
import Editor from '../../components/Editor/Editor';
import SwitchEnabledIcon from '../../Images/switch_enabled.svg';
import SwitchDisabledIcon from '../../Images/switch_disabled.svg';
import { NotificationContext } from '../../Contexts/NotificationContext';


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#021F3F',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#021F3F',
        },
    },
});

const CssInputLabel = styled(Select)({

    '& label.Mui-focused': {
        color: '#021F3F',
    },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#021F3F',
    },

});

const StoreInformation = () => {
    const [values, setValues] = useState({
        name: "",
        address: "",
        phone: "",
        facebook_link: "",
        instagram_link: "",
        popup_title: "",
        popup_positive_text: "",
        popup_negative_text: "",
        popup_show_status: false,
    });
    const [image, setImage] = useState(null);
    const [shopBannerImage, setShopBannerImage] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const { shopDetails, getShopDetails } = useContext(AuthContext);
    const { showNotification } = useContext(NotificationContext);
    const [currencyList, setCurrencyList] = useState([]);
    const [aboutUsData, setAboutUsData] = useState('');
    const [termsData, setTermsData] = useState('');
    const trafficEligibilityRef = React.useRef(null);

    const executeScroll = () => trafficEligibilityRef.current.scrollIntoView({ behavior: 'smooth' });


    const handleSubmit = async () => {
        if (
            values.popup_show_status && (
                values.popup_title === '' ||
                values.popup_positive_text === '' ||
                values.popup_negative_text === ''
            )
        ) {
            executeScroll();
            return;
        }
        const formData = new FormData();
        formData.append('shop_name', values.name);
        formData.append('shop_address', values.address);
        formData.append('phone', values.phone);
        if (values.facebook_link)
            formData.append('facebook_link', values.facebook_link);
        if (values.instagram_link)
            formData.append('instagram_link', values.instagram_link);
        formData.append('image', image);
        formData.append('about_us', aboutUsData);
        formData.append('terms_and_conditions', termsData);
        formData.append('popup_title', values.popup_title);
        formData.append('popup_positive_text', values.popup_positive_text);
        formData.append('popup_negative_text', values.popup_negative_text);
        formData.append('popup_show_status', values.popup_show_status);
        const data = jwt(localStorage.getItem('token'));
        const url = `/merchants/updateShop/${data.shop_id}`;
        setIsDisabled(true);
        try {
            //banner form data
            if (typeof shopBannerImage != 'string') {
                const bannerFormData = new FormData();
                bannerFormData.append('homepage_banner', shopBannerImage);
                const bannerRes = await axios.put(`/merchants/updateShopBanners/${data.shop_id}`, bannerFormData);
                if (bannerRes.data.status === 'S119') {
                    showNotification('success', 'Your store banner updated');
                } else {
                    showNotification('error', 'Your store banner failed to update.');
                }
            }
            const res = await axios.put(url, formData);
            if (res.data.status === 'S116') {
                showNotification('success', 'Your store information updated');
            } else {
                showNotification('error', 'Your store information failed to update.');
            }
        } catch (error) {
            showNotification('error', 'Your store information failed to update.');
        } finally {
            setValues({
                name: "",
                address: "",
                phone: "",
                facebook_link: "",
                instagram_link: "",
                popup_title: "",
                popup_positive_text: "",
                popup_negative_text: "",
                popup_show_status: false,
            });
            setAboutUsData('');
            setTermsData('');
            setImage(null);
            setShopBannerImage(null);
            getShopDetails();
            getShopBanners();
        }
    }

    const onSelectShopBannerFile = e => {
        setShopBannerImage(e.target.files[0]);
    }

    const onSelectFile = e => {
        setImage(e.target.files[0]);
    }

    const handleChange = (event) => {
        const oldValues = { ...values };
        oldValues[event.target.name] = event.target.value;
        setValues({ ...oldValues });
    }

    const getShopBanners = async () => {
        const { shop_id } = jwt(localStorage.getItem('token'));
        const url = `/merchants/getShopBanners/${shop_id}`;
        try {
            const res = await axios.get(url);
            setShopBannerImage(res.data.homepage_banner);
        } catch (error) {
            console.log(error);
        }
    }

    const getAllCurrencies = async () => {
        try {
            const currencyList = localStorage.getItem('currencyList');
            if (currencyList) {
                setCurrencyList(JSON.parse(currencyList));
            } else {
                const url = `/currencies/getAllCurrencies`;
                const res = await axios.get(url);
                setCurrencyList(res.data);
                localStorage.setItem('currencyList', JSON.stringify(res.data));
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (values.name?.length) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [values.name]);

    useEffect(() => {
        getShopBanners();
        getAllCurrencies();
    }, []);

    useEffect(() => {
        setValues({
            name: shopDetails.shop_name || "",
            address: shopDetails.shop_address || "",
            phone: shopDetails.phone || "",
            facebook_link: shopDetails.facebook_link || "",
            instagram_link: shopDetails.instagram_link || "",
            popup_title: shopDetails.popup_title || "",
            popup_positive_text: shopDetails.popup_positive_text || "",
            popup_negative_text: shopDetails.popup_negative_text || "",
            popup_show_status: shopDetails.popup_show_status || false,
        });
        setAboutUsData(shopDetails.about_us || "");
        setTermsData(shopDetails.terms_and_conditions || "");
        setImage(shopDetails.shop_image);
    }, [shopDetails]);


    return (
        <Box className='bg scroll' sx={{ overflowY: 'auto', height: '77.5vh' }}>
            <ToastContainer
                autoClose={5000}
                closeOnClick />
            <Grid container>
                <Grid item lg={8}>
                    <Typography sx={{ margin: "12px 0", color: "#021F3F" }}>
                        About Store
                    </Typography>
                    <CssTextField
                        sx={{ margin: "6px 0" }}
                        onChange={(e) => handleChange(e)}
                        name='name'
                        value={values.name}
                        id="outlined-basic"
                        label="Store Name*"
                        variant="outlined"
                        placeholder='Enter your store name'
                        fullWidth />
                    <CssTextField
                        sx={{ margin: "6px 0" }}
                        onChange={(e) => handleChange(e)}
                        name='address'
                        value={values.address}
                        id="outlined-basic"
                        label="Store Address (if available)"
                        variant="outlined"
                        placeholder='Enter your store address'
                        fullWidth />
                    <CssTextField
                        sx={{ margin: "6px 0" }}
                        onChange={(e) => handleChange(e)}
                        name='phone'
                        value={values.phone}
                        id="outlined-basic"
                        label="Store Phone Number"
                        variant="outlined"
                        placeholder='Enter your store phone number'
                        fullWidth />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <FormControl
                            sx={{
                                margin: "6px 0",
                                minWidth: '120px',
                            }}
                        >
                            <InputLabel
                                id="demo-simple-select-required-label"
                                sx={{
                                    "&.Mui-focused": {
                                        color: "#021F3F"
                                    }
                                }}
                            >Currency</InputLabel>
                            <CssInputLabel
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={shopDetails?.currencies?.id}
                                autoWidth
                                label="Currency"
                                disabled={true}
                            >
                                {
                                    currencyList.map((item, index) =>
                                        <MenuItem key={index} value={item.id}>{item.title}</MenuItem>
                                    )
                                }
                            </CssInputLabel>
                        </FormControl>
                        <Tooltip
                            title="Currency cannot be changed, you have product(s) in other currency.">
                            <InfoIcon
                                fontSize='small'
                                sx={{
                                    margin: "5px",
                                    color: '#021F3F'
                                }}
                            />
                        </Tooltip>
                    </Box>
                    <Box
                        sx={{
                            margin: "24px 0"
                        }}
                        ref={trafficEligibilityRef}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "24px"
                            }}
                        >
                            <Typography
                                sx={{
                                    color: "#021F3F",
                                    textAlign: "left",
                                }}
                            >
                                Configure Store Popup
                            </Typography>
                            <Box
                                component="img"
                                src={values.popup_show_status ? SwitchEnabledIcon : SwitchDisabledIcon}
                                sx={{
                                    cursor: "pointer",
                                }}
                                onClick={(e) => {
                                    setValues({
                                        ...values,
                                        popup_show_status: !values.popup_show_status
                                    });
                                }}
                            />
                        </Box>
                        {
                            values.popup_show_status &&
                            <Box>
                                <CssTextField
                                    sx={{ margin: "6px 0" }}
                                    onChange={(e) => handleChange(e)}
                                    name='popup_title'
                                    value={values.popup_title}
                                    id="outlined-basic"
                                    label="Popup Title"
                                    variant="outlined"
                                    placeholder='Popup Title'
                                    fullWidth
                                    error={!values.popup_title?.length}
                                    helperText={!values.popup_title?.length && 'Popup title is required'}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <CssTextField
                                        sx={{ margin: "6px 24px 6px 0" }}
                                        onChange={(e) => handleChange(e)}
                                        name='popup_positive_text'
                                        value={values.popup_positive_text}
                                        id="outlined-basic"
                                        label="Positive button"
                                        variant="outlined"
                                        placeholder='Positive button'
                                        fullWidth
                                        error={!values.popup_positive_text?.length}
                                        helperText={!values.popup_positive_text?.length && 'Positive button text is required'}
                                    />
                                    <CssTextField
                                        sx={{ margin: "6px 0" }}
                                        onChange={(e) => handleChange(e)}
                                        name='popup_negative_text'
                                        value={values.popup_negative_text}
                                        id="outlined-basic"
                                        label="Negative Button"
                                        variant="outlined"
                                        placeholder='Negative Button'
                                        fullWidth
                                        error={!values.popup_negative_text?.length}
                                        helperText={!values.popup_negative_text?.length && 'Negative button text is required'}
                                    />
                                </Box>
                            </Box>
                        }
                    </Box>
                    <Typography sx={{ margin: "12px 0", color: "#021F3F" }}>
                        Add Social Link
                    </Typography>
                    <CssTextField
                        sx={{ margin: "6px 0" }}
                        onChange={(e) => handleChange(e)}
                        name='facebook_link'
                        value={values.facebook_link}
                        id="outlined-basic"
                        label="Facebook page link"
                        variant="outlined"
                        placeholder='Paste your facebook page link'
                        fullWidth />
                    <CssTextField
                        sx={{ margin: "6px 0" }}
                        onChange={(e) => handleChange(e)}
                        name='instagram_link'
                        value={values.instagram_link}
                        id="outlined-basic"
                        label="Instagram page link"
                        variant="outlined"
                        placeholder='Paste your instagram page link'
                        fullWidth />
                    <Grid container>
                        <Grid item md={4}>
                            <Typography sx={{ margin: "12px 0", color: "#021F3F" }}>
                                Store Logo
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                {image ?
                                    <Box>
                                        <div>
                                            {
                                                typeof image == 'string' ?
                                                    <img
                                                        src={image}
                                                        width='160px'
                                                        height='180px'
                                                        style={{
                                                            borderRadius: '4px',
                                                            marginRight: '24px',
                                                            objectFit: 'cover'
                                                        }}
                                                        alt='upload' />
                                                    :
                                                    <img
                                                        src={URL.createObjectURL(image)}
                                                        height='160px'
                                                        width='180px'
                                                        style={{
                                                            borderRadius: '4px',
                                                            marginRight: '24px',
                                                            objectFit: 'cover'
                                                        }}
                                                        alt='upload' />
                                            }
                                        </div>
                                    </Box>
                                    : <div>
                                        <label className='addImage-div'>
                                            <UploadFileOutlinedIcon sx={{ width: '20px', height: '23px', marginBottom: '5px' }} />
                                            <p>Tap to upload a store logo <br /> (120 x 120) pixel</p>
                                            <br />
                                            <input
                                                type="file"
                                                name="image"
                                                onChange={(e) => onSelectFile(e)}
                                                accept="image/png , image/jpeg, image/webp"
                                            />
                                        </label>
                                    </div>
                                }
                            </Box>
                            <Button
                                disableElevation
                                variant="outlined"
                                sx={{
                                    textTransform: 'none',
                                    color: "#FF6550",
                                    borderColor: "#FF6550",
                                    '&:hover': {
                                        border: '1px solid #FF6550',
                                        color: '#FF6550',
                                        backgroundColor: '#E8D9D7'
                                    },
                                    borderRadius: "6px",
                                    margin: "10px 0",
                                    width: "160px",
                                }}
                                onClick={() => setImage(null)}
                            >
                                <CachedIcon fontSize='small' sx={{ marginRight: "10px" }} /> Change Logo
                            </Button>
                        </Grid>
                        <Grid item md={8}>
                            <Typography sx={{ margin: "12px 0", color: "#021F3F" }}>
                                Store Banner
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                {shopBannerImage ?
                                    <Box>
                                        <div>
                                            {
                                                typeof shopBannerImage == 'string' ?
                                                    <img
                                                        src={shopBannerImage}
                                                        width='360px'
                                                        height='180px'
                                                        style={{
                                                            borderRadius: '4px',
                                                            marginRight: '24px',
                                                            objectFit: 'cover'
                                                        }}
                                                        alt='upload' />
                                                    :
                                                    <img
                                                        src={URL.createObjectURL(shopBannerImage)}
                                                        height='180px'
                                                        width='360px'
                                                        style={{
                                                            borderRadius: '4px',
                                                            marginRight: '24px',
                                                            objectFit: 'cover'
                                                        }}
                                                        alt='upload' />
                                            }
                                        </div>
                                    </Box>
                                    : <div>
                                        <label className='addImage-div-two'>
                                            <UploadFileOutlinedIcon sx={{ width: '20px', height: '23px', marginBottom: '5px' }} />
                                            <p>Tap to upload a store banner <br /> (1920 x 500) pixel</p>
                                            <br />
                                            <input
                                                type="file"
                                                name="image"
                                                onChange={(e) => onSelectShopBannerFile(e)}
                                                accept="image/png , image/jpeg, image/webp"
                                            />
                                        </label>
                                    </div>
                                }
                            </Box>
                            <Button
                                disableElevation
                                variant="outlined"
                                sx={{
                                    textTransform: 'none',
                                    color: "#FF6550",
                                    borderColor: "#FF6550",
                                    '&:hover': {
                                        border: '1px solid #FF6550',
                                        color: '#FF6550',
                                        backgroundColor: '#E8D9D7'
                                    },
                                    borderRadius: "6px",
                                    margin: "10px 0",
                                    width: '100%'
                                }}
                                onClick={() => setShopBannerImage(null)}
                            >
                                <CachedIcon fontSize='small' sx={{ marginRight: "10px" }} /> Change Banner
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ width: '100%', margin: '20px 0' }} />
            <Box
                sx={{
                    border: '1px solid #BDBDBD',
                    borderRadius: '6px',
                    padding: '0px 10px 5px 10px',
                    margin: '12px 0 24px 0',
                    minHeight: '400px'
                }}
            >
                <Typography sx={{ margin: "12px 0", color: "#021F3F" }}>
                    About us section
                </Typography>
                <Editor
                    value={aboutUsData}
                    setValue={setAboutUsData}
                    editorHeight='300px'
                />
            </Box>
            <Box
                sx={{
                    border: '1px solid #BDBDBD',
                    borderRadius: '6px',
                    padding: '0px 10px 5px 10px',
                    margin: '12px 0',
                    minHeight: '400px'
                }}
            >
                <Typography sx={{ margin: "12px 0", color: "#021F3F" }}>
                    Terms and Condition
                </Typography>
                <Editor
                    value={termsData}
                    setValue={setTermsData}
                    editorHeight='300px'
                />
            </Box>
            <div className='botton-div'>
                <Button
                    disableElevation
                    variant="contained"
                    sx={{
                        backgroundColor: '#FF6550',
                        marginLeft: '80%',
                        borderRadius: '6px',
                        textTransform: 'none',
                        fontSize: '14px',
                        '&:hover': {
                            // borderColor: '# ED5E4A',
                            backgroundColor: '#ED5E4A'
                        }
                    }}
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    <AddIcon fontSize='small' sx={{ marginRight: "8px" }} /> Update
                </Button>
            </div>
        </Box >
    );
};

export default StoreInformation;