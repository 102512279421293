import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import './Subscription.css';
import Switch from '../../Images/switch.png';
import RotatedSwitch from '../../Images/rotated_switch.png';
import { useState } from 'react';
import TableHeader from '../../components/TableHeader/TableHeader';
import { useEffect } from 'react';
import axios from '../InstanceAxios/InstanceAxios';
import jwtDecode from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import { useContext } from 'react';
import { AuthContext } from '../../Contexts/AuthContext';
import { useSearchParams } from 'react-router-dom';

const Subscription = () => {
    const [isYearly, setIsYearly] = useState(false);
    const [plans, setPlans] = useState([]);
    const { subscribedPlan } = useContext(AuthContext);
    const [remainingDays, setRemainingDays] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();


    const getDifferenceInDays = (date1, date2) => {
        const diffTime = new Date(date1) - new Date(date2);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    const handleUpgrade = async (selectedId) => {
        try {
            const { merchant_id } = jwtDecode(localStorage.getItem('token'));
            const selectedPlan = plans.find((plan) => plan.id === selectedId);
            if (selectedPlan.isUsd) {
                const url = '/stripe/create-checkout-session';
                const response = await axios.post(url, {
                    merchant_id,
                    subscription_type_id: selectedPlan.id,
                    is_yearly: isYearly,
                    lookup_key:
                        isYearly ? selectedPlan.stripe_lookup_key_yearly : selectedPlan.stripe_lookup_key_monthly,
                });
                if (response.data.url) {
                    window.location.replace(response.data.url);
                } else {
                    toast.error('Something went wrong, Please try again', {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            } else {
                const res = await axios.post('/sslcommerz/subscription-payment-init', {
                    total_amount:
                        selectedPlan.isUsd
                            ? (isYearly ? selectedPlan.yearly_price_usd : selectedPlan.monthly_price_usd)
                            : (isYearly ? selectedPlan.yearly_price : selectedPlan.monthly_price),
                    merchant_id,
                    subscription_type_id: selectedPlan.id,
                    is_yearly: isYearly,
                    isUsd: selectedPlan.isUsd
                });
                if (res.data.paymentLink) {
                    window.location.replace(`${res.data.paymentLink}`);
                } else {
                    toast.error('Something went wrong, Please try again', {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong, Please try again', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }


    const getAllSubscription = async () => {
        try {
            const res = await axios.get('/subscriptions/getAllSubscriptionsV2');
            setPlans(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getAllSubscription();
    }, []);

    useEffect(() => {
        if (subscribedPlan?.subscription_log) {
            const daysLeft = getDifferenceInDays(subscribedPlan?.subscription_log[0]?.expiry_date, new Date());
            setRemainingDays(daysLeft < 0 ? 0 : daysLeft);
        }
    }, [subscribedPlan?.subscription_log]);

    useEffect(() => {
        if (searchParams.get("success")) {
            toast.success('Payment Successful', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
            });
        }
        if (searchParams.get("cancelled")) {
            toast.error('Payment Cancelled', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
            });
        }

    }, [searchParams]);


    return (
        <Box className='scroll' sx={{ overflowY: 'auto', height: '83vh' }}>
            <Box
                sx={{
                    backgroundColor: "#F8F8F8",
                    paddingBottom: "24px"
                }}
            >
                {
                    subscribedPlan?.title ?
                        <Box
                            sx={{
                                backgroundColor: "#F9E9E7",
                                padding: "24px",
                                textAlign: "left"
                            }}
                        >
                            <Box
                                sx={{
                                    color: "#021F3F",
                                    fontSize: "20px",
                                    fontWeight: "500",
                                    marginBottom: "8px"
                                }}
                            >
                                {
                                    `${remainingDays < 8 && remainingDays !== 0 ? 'Only' : ''} ${remainingDays} day(s) left!`
                                }
                            </Box>
                            <Box
                                sx={{
                                    color: "#79747E",
                                    fontSize: "14px",
                                    marginBottom: "16px"
                                }}
                            >
                                {
                                    remainingDays < 8 ?
                                        `You almost reached the limits of your plan! Please upgrade to stay in touch and get more stuff.`
                                        : ''
                                }
                            </Box>
                            <Box
                                sx={{
                                    color: "#021F3F",
                                    fontSize: "24px",
                                    fontWeight: "500",
                                    marginBottom: "8px"
                                }}
                            >
                                <Box
                                    sx={{
                                        color: "#79747E",
                                        fontSize: "24px",
                                        display: "inline"
                                    }}
                                >
                                    Current Plan:
                                </Box>
                                {` ${subscribedPlan?.title}`}
                            </Box>
                        </Box>
                        :
                        <Box
                            sx={{
                                backgroundColor: "#F9E9E7",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "150px"
                            }}
                        >
                            <CircularProgress sx={{ color: '#FF6550' }} />
                        </Box>
                }
                <Box
                    sx={{
                        backgroundColor: "#FFFFFF",
                        marginTop: "24px",
                        padding: "24px",
                        textAlign: "left"
                    }}
                >
                    <Typography
                        sx={{
                            color: "#021F3F",
                            fontSize: "24px",
                            fontWeight: "500",
                            marginBottom: "8px",
                            fontFamily: 'figtree'
                        }}
                    >
                        HOW TO BUY SUBSCRIPTION
                    </Typography>
                    <Box
                        sx={{
                            color: "#79747E",
                            fontSize: "14px",
                            marginBottom: "16px",
                            fontFamily: 'figtree'
                        }}
                    >
                        <Typography>
                            1. Pay the subscription fee through bKash at 01620-258549 (Send Money)
                        </Typography>
                        <Typography>
                            2. Put the phone number you used to sign up Bonik in the bKash reference field
                        </Typography>
                        <Typography>
                            3. Our team will verify your payment and activate your package
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box className='subscription-container'>
                <ToastContainer
                    autoClose={5000}
                    closeOnClick />
                {/* <Box
                    sx={{
                        borderBottom: "0.5px solid #D1D1D1"
                    }}>
                    <Box
                        sx={{
                            color: "#021F3F",
                            fontSize: "22px"
                        }}
                        fontWeight={500}
                    >
                        HOW TO BUY SUBSCRIPTION
                    </Box>
                    <Box
                        sx={{
                            color: "#95989A",
                            fontSize: "16px",
                            margin: "12px "
                        }}
                    >
                        <Typography>
                            1. Pay the subscription fee through bKash at 01620-258549 (Send Money)
                        </Typography>
                        <Typography>
                            2. Put the phone number you used to sign up Bonik in the bKash reference field
                        </Typography>
                        <Typography>
                            3. Our team will verify your payment and activate your package
                        </Typography>
                    </Box>
                </Box> */}
                {/* Yearly off will show below */}
                <Box>
                    <Box>
                        <Box
                            sx={{
                                color: "#021F3F",
                                fontSize: "22px",
                                textAlign: "center",
                                margin: "24px 0 16px 0",
                                fontFamily: 'figtree'
                            }}
                            fontWeight={500}
                        >
                            Choose your plan
                        </Box>
                        <Typography
                            sx={{
                                color: "#95989A",
                                fontSize: "16px",
                                textAlign: "center",
                                fontFamily: 'figtree'
                            }}
                        >
                            You almost reached the limits of your plan! Please upgrade how to stay in touch and get more stuff.
                        </Typography>
                        <Box
                            sx={{
                                color: "#021F3F",
                                fontSize: "16px",
                                textAlign: "center",
                                margin: "48px 0 32px 0",
                                display: "flex",
                                justifyContent: "center",
                                fontFamily: 'figtree'
                            }}
                            fontWeight={500}
                        >
                            <Box>
                                Monthly
                            </Box>
                            <Box
                                onClick={() => setIsYearly((currentValue) => !currentValue)}
                            >
                                {
                                    isYearly ?
                                        <img src={RotatedSwitch} alt='' height='24px' style={{ margin: "0 20px" }} />
                                        :
                                        <img src={Switch} alt='' height='24px' style={{ margin: "0 20px" }} />
                                }
                            </Box>
                            <Box>
                                Yearly
                                <span style={{ color: "#95989A", fontSize: "16px", fontWeight: "500" }}> (20% Off)</span>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                textAlign: "center"
                            }}
                            className='subscription-table'
                        >
                            <table width='100%'>
                                <thead>
                                    <tr>
                                        <td
                                            rowSpan='3'
                                            style={{
                                                backgroundColor: "#F8F8F8",
                                                color: "#021F3F",
                                                fontSize: "36px",
                                                padding: "43px 68px 43px 24px",
                                                fontFamily: 'figtree'
                                            }}
                                        >
                                            Features
                                        </td>
                                        {
                                            plans.map((plan, index) =>
                                                <TableHeader
                                                    key={index}
                                                    plan={plan}
                                                    handleUpgrade={handleUpgrade}
                                                    isYearly={isYearly}
                                                />
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody
                                    style={{
                                        fontSize: "16px",
                                        color: "#95989A"
                                    }}
                                >
                                    <tr>
                                        <td className='property-td'>Product Upload</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {plan.product_upload}
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>Cloud Hosting & Website Traffic</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {plan.hosting_traffic}
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>Customer List</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {plan.customer_list}
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>Create Discount and Campaign</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {plan.discount_campaign}
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>On-page SEO</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {plan.seo}
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>Free SSL Certificate</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {plan.free_ssl}
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>Custom Domain</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {plan.custom_domain ? 'Available' : 'N/A'}
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>Website Management</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {plan.website_management}
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>SMS Notification on Order</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {plan.order_sms_notification}
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>Payment Gateway</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {
                                                        plan.gateways.length === 1 &&
                                                            plan.gateways[0].id === 1 ? 'N/A' : 'Available'
                                                    }
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>Facebook Pixel Integration</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {
                                                        plan.analytics.findIndex((analytic) => analytic.id === 1) >= 0 ?
                                                            'Available' : 'N/A'
                                                    }
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>Google Analytics Integration</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {
                                                        plan.analytics.findIndex((analytic) => analytic.id === 2) >= 0 ?
                                                            'Available' : 'N/A'
                                                    }
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>Report & Analytics</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {plan.report_analytics}
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>Delivery Service Integration</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {plan.delivery_service}
                                                </td>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <td className='property-td'>Customer Support</td>
                                        {
                                            plans.map((plan, index) =>
                                                <td key={index} className='value-td'>
                                                    {plan.customer_support}
                                                </td>
                                            )
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Subscription;