import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cross from '../../Images/cross.png';
import { useState } from 'react';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import axios from '../InstanceAxios/InstanceAxios'
import { toast, ToastContainer } from 'react-toastify';
import { styled } from '@mui/system';
import { NotificationContext } from '../../Contexts/NotificationContext';
import { InputAdornment } from '@mui/material';

export default function WhatsappDialougue({ isOpenedDialouge, setIsOpenedDialouge, data }) {
    const { showNotification } = React.useContext(NotificationContext);
    const [formData, setFormData] = useState({
        whatsapp_number: "",
    });
    const [isDisabled, setIsDisabled] = useState(true);

    const handleChange = (e) => {
        const oldData = { ...formData };
        oldData[e.target.name] = e.target.value;
        setFormData({ ...oldData });
    }

    const handleClose = () => {
        setIsOpenedDialouge(false);
    };

    const handleSubmit = async () => {
        try {
            setIsDisabled(true);
            const decoded = jwtDecode(localStorage.getItem('token'));
            const url = `/merchants/updateWhatsappNumber/${decoded.shop_id}`;
            const response = await axios.put(url, {
                whatsapp_number: formData.whatsapp_number,
            });
            if (response.data.status === 'S116') {
                showNotification('success', 'Successfully saved whatsapp credentials');
                setIsOpenedDialouge(false);
            } else {
                showNotification('error', 'Failed to save whatsapp credentials');
            }
        } catch (error) {
            console.log(error);
            showNotification('error', 'Failed to save whatsapp credentials');
        } finally {
            setFormData({
                whatsapp_number: ""
            });
        }
    }

    useEffect(() => {
        if (formData.whatsapp_number.length) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [formData.whatsapp_number]);

    useEffect(() => {
        if (data.hasOwnProperty('whatsapp_number') && data.whatsapp_number != null) {
            setFormData(data);
        } else {
            setFormData({
                whatsapp_number: ""
            });
        }
    }, [data]);

    // const CssTextField = styled(TextField)({
    //     '& label.Mui-focused': {
    //         color: '#021F3F',
    //     },
    //     '& .MuiOutlinedInput-root': {
    //         '&.Mui-focused fieldset': {
    //             borderColor: '#021F3F',
    //         },
    //     },
    // });

    return (
        <div>
            <Dialog open={isOpenedDialouge} onClose={handleClose}>
                <DialogTitle className='ssl-dialogue-header'>
                    <span>
                        Connect with WhatsApp
                    </span>
                    <img src={Cross} alt='' height='20px' width='20px' onClick={handleClose} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="whatsapp_number"
                        name='whatsapp_number'
                        label="WhatsApp Number"
                        placeholder='Enter WhatsApp Number (880XXXXXXXXXX)'
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.whatsapp_number}
                        onChange={(e) => handleChange(e)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+</InputAdornment>,
                        }}
                    />
                </DialogContent>
                <div className='ssl-dialogue-bottom'>
                    <Button
                        disableElevation='false'
                        variant="contained"
                        sx={{
                            padding: "8px 16px",
                            backgroundColor: '#FF6550',
                            borderRadius: "6px",
                            textTransform: 'none',
                            fontFamily: 'figtree',
                            '&:hover': {
                                backgroundColor: '#ED5E4A',

                            }
                        }}
                        onClick={handleSubmit}
                        disabled={isDisabled}
                    >
                        Save
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}