import React, { useEffect, useState } from 'react';
import axios from '../../InstanceAxios/InstanceAxios'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { getShopInfo } from '../../../utilities/StoredData';
import pending from '../../../Images/add_image.png'
import accepted from '../../../Images/accepted.png'
import in_transit from '../../../Images/In_transit.png'
import delivered from '../../../Images/Delivered.png'
import picked_up from '../../../Images/picked.png'
import returned from '../../../Images/returned.png'
import cancelled from '../../../Images/Cancelled.png'
import { useContext } from 'react';
import { AuthContext } from '../../../Contexts/AuthContext';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const StatusChip = styled(Typography)(({ theme }) => ({
    marginRight: '24px',
    border: '1px solid #021F3F',
    borderRadius: '6px',
    padding: '8px',
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    fontFamily: 'Figtree',
    fontWeight: 500,
    fontSize: '16px'

    // [theme.breakpoints.down('md')]: {
    //     fontSize: '5.353vw',
    //     fontWeight: 400
    // },

}));

const OnlineOrder = () => {
    const info = getShopInfo()
    const [orders, setOrders] = useState([])
    const [chips, setChips] = useState({})
    const [selected, setSelected] = useState(1)
    const [render, setRender] = useState(0)
    const [display, setDisplay] = useState(true)
    // const [display, setDisplay] = useState('none')
    const navigate = useNavigate();
    const { shopDetails } = useContext(AuthContext);

    useEffect(() => {
        const url = `/sales/getAllSalesv4/${info?.shop_id}?status=${selected}`
        const fetchData = async () => {
            const res = await axios.get(url)
            const tempOrders = res.data?.data.map(sale =>
                ({ ...sale, total_amount: `${shopDetails?.currencies?.title} ${sale.total_amount}` })
            )
            setOrders(tempOrders);
            setChips(res.data);
            setDisplay(false)
        }
        fetchData()
    }, [render, selected]);

    const rows = orders

    const columns = [
        { field: 'serial', headerName: '#Order ID', width: 146 },
        { field: 'formated_datetime', headerName: 'Date & Time', width: 236 },

        {
            field: 'total_amount',
            headerName: 'Total Amount',
            width: 246,
        },
        {
            field: 'payment_method',
            headerName: 'Payment Method',
            sortable: false,
            width: 236,
        },
        {
            field: 'action',
            headerName: 'Status',
            sortable: false,
            width: 210,
            renderCell: (params) => {
                const urlAccept = `/sales/acceptOrder/${params.row.sales_id}`
                const urlCancel = `/sales/updateOrderStatus/${params.row.sales_id}`
                const onClickAccept = async e => {
                    e.stopPropagation();
                    const res = await axios.put(urlAccept,
                        {
                            "delivery_partner": params.row.delivery_partner
                        })
                    setSelected(1)
                    setRender(params.row.sales_id)

                }
                const onClickCancel = async e => {
                    e.stopPropagation();
                    const res = await axios.put(urlCancel,
                        {
                            order_status: 7
                        })
                    setSelected(1)
                    setRender(params.row.sales_id)
                }


                return <>
                    {
                        selected === 1 && <Box>
                            <Button
                                onClick={onClickAccept}
                                variant="contained"
                                disableElevation
                                sx={{
                                    marginRight: '8px',
                                    width: '96px',
                                    fontFamily: 'Figtree',
                                    fontWeight: 500,
                                    borderRadius: '6px',
                                    backgroundColor: '#00DD64',
                                    textTransform: 'none',
                                    '&:hover': { backgroundColor: '#00B552' }
                                }}>
                                Accept</Button>
                            <Button
                                onClick={onClickCancel}
                                variant="outlined"
                                sx={{
                                    width: '96px',
                                    fontFamily: 'Figtree',
                                    fontWeight: 500,
                                    color: '#ED1C24',
                                    borderRadius: '6px',
                                    border: '1px solid #ED1C24',
                                    textTransform: 'none',
                                    '&:hover': { border: '1px solid #ED1C24', backgroundColor: '#E7D2D3' }
                                }} >
                                Cancel</Button>
                        </Box >
                    }
                    {
                        selected === 2 && <Box>
                            <Button
                                // variant="contained"
                                sx={{
                                    marginRight: '8px',
                                    width: '96px',
                                    fontFamily: 'Figtree',
                                    fontWeight: 500,
                                    borderRadius: '6px',
                                    backgroundColor: '#FFFFFF',
                                    border: '1px solid #021F3F',
                                    textTransform: 'none',
                                    color: '#021F3F',
                                    '&:hover': { border: '1px solid #021F3F', backgroundColor: '#FFFFFF', color: '#021F3F' }
                                }}>
                                Accepted
                                {/* <KeyboardArrowDownIcon
                                    onClick={e => {
                                        e.stopPropagation()
                                        display === 'none' ? (setDisplay('block')) : (setDisplay('none'))

                                    }} */}
                                {/* /> */}
                            </Button>
                            {/* <Box
                                sx={{ display: { display }, width: '96px', zIndex: 9999, height: '180px', position: 'absolute', backgroundColor: '#FFFFFF', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)' }}>

                                <Typography>Picked Up</Typography>
                                <Typography>Returned</Typography>
                                <Typography>Delivered</Typography>
                                <Typography>Returned</Typography>
                                <Typography>Cancelled</Typography>

                            </Box> */}
                        </Box>
                    }
                    {
                        selected === 3 && <Box>
                            <Button
                                // variant="contained"
                                sx={{
                                    marginRight: '8px',
                                    width: '96px',
                                    fontFamily: 'Figtree',
                                    fontWeight: 500,
                                    borderRadius: '6px',
                                    backgroundColor: '#FFFFFF',
                                    border: '1px solid #021F3F',
                                    textTransform: 'none',
                                    color: '#021F3F',
                                    '&:hover': { border: '1px solid #021F3F', backgroundColor: '#FFFFFF', color: '#021F3F' }
                                }}>
                                Picked Up
                                {/* <KeyboardArrowDownIcon /> */}
                            </Button>
                        </Box>
                    }
                    {
                        selected === 4 && <Box>
                            <Button
                                // variant="contained"
                                sx={{ marginRight: '8px', width: '96px', fontFamily: 'Figtree', fontWeight: 500, borderRadius: '6px', backgroundColor: '#FFFFFF', border: '1px solid #021F3F', textTransform: 'none', color: '#021F3F', '&:hover': { border: '1px solid #021F3F', backgroundColor: '#FFFFFF', color: '#021F3F' } }}>
                                In Transit
                                {/* <KeyboardArrowDownIcon /> */}
                            </Button>
                        </Box>
                    }
                    {
                        selected === 5 && <Box>
                            <Button
                                // variant="contained"
                                sx={{ marginRight: '8px', width: '96px', fontFamily: 'Figtree', fontWeight: 500, borderRadius: '6px', backgroundColor: '#FFFFFF', border: '1px solid #021F3F', textTransform: 'none', color: '#021F3F', '&:hover': { border: '1px solid #021F3F', backgroundColor: '#FFFFFF', color: '#021F3F' } }}>
                                Delivered
                                {/* <KeyboardArrowDownIcon /> */}
                            </Button>
                        </Box>
                    }
                    {
                        selected === 6 && <Box>
                            <Button
                                // variant="contained"
                                sx={{ marginRight: '8px', width: '96px', borderRadius: '6px', fontFamily: 'Figtree', fontWeight: 500, backgroundColor: '#FFFFFF', border: '1px solid #021F3F', textTransform: 'none', color: '#021F3F', '&:hover': { border: '1px solid #021F3F', backgroundColor: '#FFFFFF', color: '#021F3F' } }}>
                                Returned

                            </Button>
                        </Box>
                    }
                    {
                        selected === 7 && <Box>
                            <Button
                                // variant="contained"
                                disabled
                                sx={{ marginRight: '8px', width: '96px', borderRadius: '6px', fontFamily: 'Figtree', fontWeight: 500, textTransform: 'none' }}>
                                Cancelled

                            </Button>
                        </Box>
                    }

                </>
            }


        },
    ];

    const handleRowClick = (params) => {
        navigate(`/orders/${params.row.sales_id}`)
    };



    return (
        <>

            <Box sx={{ display: 'flex', marginLeft: '30px', maxHeight: '40px' }}>
                <StatusChip onClick={() => setSelected(1)} sx={selected === 1 && { backgroundColor: "#021F3F", color: '#FFFFFF' }}>Pending ({chips.pending})</StatusChip>
                <StatusChip onClick={() => setSelected(2)} sx={selected === 2 && { backgroundColor: "#021F3F", color: '#FFFFFF' }}>Accepted ({chips.accepted})</StatusChip>
                <StatusChip onClick={() => setSelected(3)} sx={selected === 3 && { backgroundColor: "#021F3F", color: '#FFFFFF' }}>Picked up ({chips.picked_up})</StatusChip>
                <StatusChip onClick={() => setSelected(4)} sx={selected === 4 && { backgroundColor: "#021F3F", color: '#FFFFFF' }}>In Transit ({chips.in_transit})</StatusChip>
                <StatusChip onClick={() => setSelected(5)} sx={selected === 5 && { backgroundColor: "#021F3F", color: '#FFFFFF' }}>Delivered ({chips.delivered})</StatusChip>
                <StatusChip onClick={() => setSelected(6)} sx={selected === 6 && { backgroundColor: "#021F3F", color: '#FFFFFF' }}>Returned ({chips.returned})</StatusChip>
                <StatusChip onClick={() => setSelected(7)} sx={selected === 7 && { backgroundColor: "#021F3F", color: '#FFFFFF' }}>Cancelled ({chips.cancelled})</StatusChip>
                {/* <StatusChip>All</StatusChip> */}
            </Box>
            {
                display && <Box>
                    <CircularProgress sx={{ marginTop: '25%', color: '#FF6550' }} />
                </Box>
            }


            {
                !display && (orders.length !== 0 ? <div style={{ height: '70vh', width: '95%', marginTop: '32px', marginLeft: '30px' }}>
                    <DataGrid
                        getRowId={(row) => row.sales_id}
                        rowHeight={64}
                        rows={rows}
                        columns={columns}
                        pageSize={6}
                        rowsPerPageOptions={[6]}
                        sx={{ backgroundColor: '#FFFFFF', fontFamily: 'Figtree', fontWeight: 500, border: 'none' }}
                        onRowClick={handleRowClick}
                    // checkboxSelection
                    />
                </div> : <Box>
                    {
                        selected === 1 && <Box>
                            <img height='207px' width='207px' style={{ marginTop: '15%' }} src={pending} alt='pending' />
                            <Typography sx={{ color: '#95989A' }}>Your order list is empty. Share your store link with customer to get orders.</Typography>
                        </Box>
                    }
                    {
                        selected === 2 && <Box>
                            <img height='207px' width='207px' style={{ marginTop: '15%' }} src={accepted} alt='Accepted' />
                            <Typography sx={{ color: '#95989A' }}>You have not accepted any orders yet.</Typography>
                        </Box>
                    }
                    {
                        selected === 3 && <Box>
                            <img height='207px' width='207px' style={{ marginTop: '15%' }} src={picked_up} alt='Picked up' />
                            <Typography sx={{ color: '#95989A' }}>No orders have been picked up yet.</Typography>
                        </Box>
                    }
                    {
                        selected === 4 && <Box><img height='207px' width='207px' style={{ marginTop: '15%' }} src={in_transit} alt='In Transit' />
                            <Typography sx={{ color: '#95989A' }}>No orders are in transit yet.</Typography>
                        </Box>
                    }
                    {
                        selected === 5 && <Box><img height='207px' width='207px' style={{ marginTop: '15%' }} src={delivered} alt='Delivered' />
                            <Typography sx={{ color: '#95989A' }}>No orders have been delivered yet.</Typography>
                        </Box>
                    }
                    {
                        selected === 6 && <Box><img height='207px' width='207px' style={{ marginTop: '15%' }} src={returned} alt='Returned' />
                            <Typography sx={{ color: '#95989A' }}>No orders have requested for return yet.</Typography>
                        </Box>
                    }
                    {
                        selected === 7 && <Box><img height='207px' width='207px' style={{ marginTop: '15%' }} sx={{ marginTop: '25%' }} src={cancelled} alt='Cancelled' />
                            <Typography sx={{ color: '#95989A' }}>No orders have been cancelled/rejected yet.</Typography>
                        </Box>
                    }

                </Box>)
            }

        </>
    );
};

export default OnlineOrder;