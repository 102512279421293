import React from 'react';

const WhatsappFeature = () => {
    return (
        <>
            {/* whatsapp features will be added here */}
        </>
    );
};

export default WhatsappFeature;