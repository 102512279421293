import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import axios from '../../InstanceAxios/InstanceAxios'

const UpdateStatusDialog = ({ oid, status, setStatus }) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState();


    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSave = async () => {
        const url = `/sales/updateOrderStatus/${oid}`
        const res = await axios.put(url,
            {
                order_status: value
            })
        console.log(res.data);
        res.data.status === 'S107' && setStatus(parseInt(value))

    }
    return (
        <div>
            <Button
                onClick={handleClickOpen}
                disableElevation
                variant="contained"
                sx={{
                    marginRight: '8px',
                    fontFamily: 'Figtree',
                    width: '145px',
                    height: '36px',
                    borderRadius: '6px',
                    backgroundColor: '#FF6550',
                    textTransform: 'none',
                    '&:hover': { backgroundColor: '#ED5E4A', color: '#FFFFFF' }
                }}>
                Update Status</Button>
            <Dialog
                fullWidth

                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Update Status</DialogTitle>
                <DialogContent className='scroll' sx={{ paddingX: '0px', height: '50%' }}>
                    <DialogContentText sx={{ paddingLeft: '24px' }} >
                        {
                            status === 2 && <Typography>Current Status: Accepted</Typography>
                        }
                        {
                            status === 3 && <Typography>Current Status: Picked up</Typography>
                        }
                        {
                            status === 4 && <Typography>Current Status: In Transit</Typography>
                        }
                        {
                            status === 5 && <Typography>Current Status: Delivered</Typography>
                        }
                        {
                            status === 6 && <Typography>Current Status: Returned</Typography>
                        }


                        <hr />
                    </DialogContentText>

                    <Typography sx={{ color: '#021F3F', fontSize: '16px', marginLeft: '24px' }}>Mark orders as</Typography>

                    <FormControl sx={{ margin: '24px', paddingLeft: '17px' }}>

                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="3" control={<Radio />} label="Picked up" />
                            <FormControlLabel value="4" control={<Radio />} label="In Transit" />
                            <FormControlLabel value="5" control={<Radio />} label="Delivered" />
                            <FormControlLabel value="6" control={<Radio />} label="Returned" />

                        </RadioGroup>
                    </FormControl>






                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleSave}
                        fullWidth
                        variant='contained'
                        disableElevation
                        sx={{
                            backgroundColor: '#FF6550',
                            textTransform: "none",
                            fontFamily: 'figtree',
                            borderRadius: '6px',
                            '&:hover': { backgroundColor: '#ED5E4A', color: '#FFFFFF' }
                        }}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UpdateStatusDialog;