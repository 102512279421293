import { Button } from '@mui/material';
import React from 'react';

const LightButton = ({ title, handleOnClick, sx, icon, isChip = false }) => {
    return (
        <Button
            variant="contained"
            disableElevation={isChip}
            sx={{
                backgroundColor: '#FFFFFF',
                color: '#021F3F',
                borderRadius: '6px',
                padding: '8px 16px',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: '400',
                textTransform: 'none',
                fontFamily: 'Figtree',
                border: '1px solid #021F3F',
                '&:hover': {
                    backgroundColor: '#FFFFFF',
                    color: '#021F3F',
                },
                ...sx
            }}
            onClick={handleOnClick}
        >
            {icon}
            {title}
        </Button>
    );
};

export default LightButton;