import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cross from '../../Images/cross.png';
import { useState } from 'react';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import axios from '../InstanceAxios/InstanceAxios'
import { toast, ToastContainer } from 'react-toastify';
import { styled } from '@mui/system';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#021F3F',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#021F3F',
        },
    },
});

export default function MessengerDialougue({ isOpenedDialouge, setIsOpenedDialouge, data }) {
    const [formData, setFormData] = useState({
        facebook_page_id: "",
    });
    const [isDisabled, setIsDisabled] = useState(true);

    const handleChange = (e) => {
        const oldData = { ...formData };
        oldData[e.target.name] = e.target.value;
        setFormData({ ...oldData });
    }

    const handleClose = () => {
        setIsOpenedDialouge(false);
    };

    const handleSubmit = async () => {
        try {
            setIsDisabled(true);
            const decoded = jwtDecode(localStorage.getItem('token'));
            const url = `/merchants/updateFacebookPageId/${decoded.shop_id}`;
            const response = await axios.put(url, {
                facebook_page_id: formData.facebook_page_id,
            });
            if (response.data.status === 'S116') {
                toast.success('Messenger Bubble credentials saved', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
                setIsOpenedDialouge(false);
            } else {
                toast.error('Failed to save Messenger Bubble credentials', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
            console.log(error);
            toast.error('Failed to save Messenger Bubble credentials', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            setFormData({
                facebook_page_id: ""
            });
        }
    }

    useEffect(() => {
        if (formData.facebook_page_id.length) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [formData.facebook_page_id]);

    useEffect(() => {
        if (data.hasOwnProperty('facebook_page_id') && data.facebook_page_id != null) {
            setFormData(data);
        } else {
            setFormData({
                facebook_page_id: ""
            });
        }
    }, [data]);



    return (
        <div>
            <ToastContainer
                autoClose={5000}
                closeOnClick />
            <Dialog open={isOpenedDialouge} onClose={handleClose}>
                <DialogTitle className='ssl-dialogue-header'>
                    <span>
                        Connect with Messenger Chat Bubble
                    </span>
                    <img src={Cross} alt='' height='20px' width='20px' onClick={handleClose} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <CssTextField
                        autoFocus
                        margin="dense"
                        id="facebook_page_id"
                        name='facebook_page_id'
                        label="Facebook Page ID"
                        placeholder='Enter Facebook Page ID'
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.facebook_page_id}
                        onChange={(e) => handleChange(e)}
                    />
                </DialogContent>
                <div className='ssl-dialogue-bottom'>
                    <Button
                        disableElevation
                        variant="contained"
                        sx={{
                            padding: "8px 16px",
                            backgroundColor: '#FF6550',
                            borderRadius: "6px",
                            textTransform: 'none',
                            fontFamily: 'figtree',
                            '&:hover': {
                                backgroundColor: '#ED5E4A',

                            }
                        }}
                        onClick={handleSubmit}
                        disabled={isDisabled}
                    >
                        Save
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}