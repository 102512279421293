const VIEW_SIGNUP_PAGE="View Signup Page"
const SIGN_UP="Sign Up"
const IS_VERIFIED="Is Verified"
const REFERRAL_CODE = "Referral Code"
const LOG_IN = "Log In"
const PRICING_PLAN = "Pricing Plan"
const NAME = "$" + "name"
const PHONE = "$" + "phone"
const SIGNUP_METHOD = "Sign Up Method"
const SHOP_CREATION = "Shop Creation"
const ADD_PRODUCT = "Product Added"
const ADD_FEATURED_PRODUCT = "Featured Product Added"
const ADD_COLLECTION = "Collection Added"
const CAMPAIGN_ACTIVITY = "Campaign Activity"

const TOTAL_PRODUCTS = "Total Products"
const TOTAL_COLLECTIONS = "Total Collections"
const TOTAL_CAMPAIGNS = "Total Campaigns"
const TOTAL_ORDERS = "Total Orders"
const TOTAL_DELIVERED_ORDERS = "Total Delivered Orders"
const STORE_LOGO = "Store Logo"
const SOCIAL_LINKS = "Social Links"
const STORE_ADDRESS = "Store Address"
const STORE_BANNER = "Store Banner"

export { 
    VIEW_SIGNUP_PAGE,
    SIGN_UP,
    IS_VERIFIED,
    REFERRAL_CODE,
    TOTAL_PRODUCTS,
    TOTAL_COLLECTIONS,
    TOTAL_CAMPAIGNS,
    TOTAL_ORDERS,
    TOTAL_DELIVERED_ORDERS,
    STORE_LOGO,
    SOCIAL_LINKS,
    STORE_ADDRESS,
    LOG_IN,
    PRICING_PLAN,
    NAME,
    PHONE,
    SIGNUP_METHOD,
    SHOP_CREATION,
    ADD_PRODUCT,
    ADD_FEATURED_PRODUCT,
    ADD_COLLECTION,
    CAMPAIGN_ACTIVITY,
    STORE_BANNER
}