import { Avatar, Box, Button, CircularProgress, Container, Grid, IconButton, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getShopInfo } from '../../utilities/StoredData';
import axios from '../InstanceAxios/InstanceAxios'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DashboardCard from '../../components/DashboardCard/DashboardCard';
import jwtDecode from 'jwt-decode';
import DashboardProductTable from '../../components/DashboardProductTable/DashboardProductTable';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Contexts/AuthContext';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseIcon from '@mui/icons-material/Close';
import OrangeButton from '../../components/Buttons/OrangeButton/OrangeButton';

const Dashboard = () => {
    const [sale, setSale] = useState([]);
    const info = getShopInfo()
    const textAreaRef = useRef(null);
    const [dashboardStatistics, setDashboardStatistics] = useState({});
    const [isDashboardStatisticsLoading, setIsDashboardStatisticsLoading] = useState(false);
    const navigate = useNavigate();
    const { shopDetails, subscribedPlan } = useContext(AuthContext);
    const [remainingDays, setRemainingDays] = useState(0);
    const [isShow, setIsShow] = useState(true);

    const getDifferenceInDays = (date1, date2) => {
        const diffTime = new Date(date1) - new Date(date2);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    const getSaleData = async () => {
        try {
            const { shop_id } = jwtDecode(localStorage.getItem('token'));
            const res = await axios.get(`/sales/getAllSalesv4/${shop_id}?status=1`);
            setSale(res.data.data);
        } catch (err) {
            console.log(err)
        }
    }

    const getDashboardStatistics = async () => {
        try {
            setIsDashboardStatisticsLoading(true);
            const { shop_id } = jwtDecode(localStorage.getItem('token'));
            const res = await axios.get(`/merchants/getDashboardStatistics/${shop_id}`);
            setDashboardStatistics(res.data);
            setIsDashboardStatisticsLoading(false);
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (subscribedPlan?.subscription_log) {
            const daysLeft = getDifferenceInDays(subscribedPlan?.subscription_log[0]?.expiry_date, new Date());
            setRemainingDays(daysLeft < 0 ? 0 : daysLeft);
        }
    }, [subscribedPlan?.subscription_log]);

    useEffect(() => {
        getDashboardStatistics();
        getSaleData();
    }, []);

    const handleClick = () => {
        shopDetails?.shop_domain ? window.open(`//www.${shopDetails?.shop_domain}`)
            : window.open(`//bonik.store/${shopDetails?.shop_link}`, "_blank")
    }

    return (
        <>
            {
                isShow && remainingDays < 8 &&
                <Box
                    sx={{
                        backgroundColor: "#F8F8F8",
                        paddingBottom: "24px"
                    }}
                >
                    {
                        subscribedPlan?.title ?
                            <Box
                                sx={{
                                    backgroundColor: "#F9E9E7",
                                    padding: "24px",
                                    textAlign: "left",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: "#021F3F",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                            marginBottom: "8px"
                                        }}
                                    >
                                        {
                                            `${remainingDays < 8 && remainingDays !== 0 ? 'Only' : ''} ${remainingDays} day(s) left!`
                                        }
                                    </Box>
                                    <Box
                                        sx={{
                                            color: "#79747E",
                                            fontSize: "14px",
                                            marginBottom: "16px"
                                        }}
                                    >
                                        {
                                            remainingDays < 8 ?
                                                remainingDays === 0 ?
                                                    `You reached the limits of your plan! Please upgrade to stay in touch and get more stuff.`
                                                    :
                                                    `You almost reached the limits of your plan! Please upgrade to stay in touch and get more stuff.`
                                                : ''
                                        }
                                    </Box>
                                    <Box>
                                        <OrangeButton
                                            title="Upgrade Plan"
                                            handleOnClick={() => navigate('/settings/subscription')}
                                        />
                                    </Box>
                                </Box>
                                <>
                                    <CloseIcon
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => setIsShow(false)}
                                    />
                                </>
                            </Box>
                            :
                            <Box
                                sx={{
                                    backgroundColor: "#F9E9E7",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "150px"
                                }}
                            >
                                <CircularProgress />
                            </Box>
                    }
                </Box>
            }

            <Typography
                sx={{ fontSize: '22px', fontWeight: 500, textAlign: 'left', fontFamily: 'Figtree', marginBottom: '24px' }}
            >
                Store Overview
            </Typography>
            <Grid
                spacing={2}
                container
            >
                <Grid item xs={12} md={8}>
                    {
                        isDashboardStatisticsLoading ?
                            <Grid
                                container
                                spacing={2}
                                justifyContent='center'
                                alignItems='center'
                                height="150px"
                            >
                                <CircularProgress sx={{ color: '#FF6550' }} />
                            </Grid>
                            :
                            <Box sx={{ display: 'flex' }}>
                                {/* <Grid item xs={12} md={3}>
                                    <DashboardCard
                                        title='Store Views'
                                        value='32'
                                        percentage='0'
                                    />
                                </Grid> */}
                                <Box sx={{ marginRight: '24px' }}>
                                    <Box
                                        sx={{ backgroundColor: '#FFFFFF', display: 'flex', padding: '16px' }}
                                    >
                                        <IconButton
                                            sx={{
                                                p: 0,
                                                mr: '16px',
                                                border: '1px solid #021F3F',
                                                width: '48px',
                                                height: '48px',
                                                display: { xs: 'none', md: 'flex', }
                                            }}
                                        >
                                            <Avatar
                                                alt="Shop Name"
                                                src={shopDetails?.shop_image}
                                                sx={{ width: '48px', height: '48px' }}
                                            />
                                        </IconButton>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: '#95989A',
                                                    fontSize: '16px',
                                                    lineHeight: '28px',
                                                    fontFamily: 'Figtree',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                Your store’s website link
                                            </Typography>
                                            <Box sx={{ display: 'flex' }}>
                                                {
                                                    shopDetails?.shop_domain ?
                                                        <a href={`//www.${shopDetails?.shop_domain}`}
                                                            rel="noreferrer" target='_blank'
                                                            style={{
                                                                color: '#000000',
                                                                textDecoration: 'none',
                                                                fontSize: '16px',
                                                                fontWeight: 500,
                                                                textAlign: 'left',
                                                                marginRight: '8px',
                                                                fontFamily: 'Figtree',
                                                                '&:hover': { textDecoration: 'underline' }
                                                            }}
                                                        >
                                                            <Typography
                                                                ref={textAreaRef}
                                                                sx={{ fontFamily: 'Figtree' }}
                                                            >
                                                                www.{shopDetails?.shop_domain}
                                                            </Typography>
                                                        </a>
                                                        :
                                                        <a
                                                            href={`//bonik.store/${shopDetails?.shop_link}`}
                                                            rel="noreferrer"
                                                            target='_blank'
                                                            style={{
                                                                color: '#000000',
                                                                textDecoration: 'none',
                                                                fontSize: '16px',
                                                                fontWeight: 500,
                                                                textAlign: 'left',
                                                                fontFamily: 'Figtree',
                                                                marginRight: '8px',
                                                                '&:hover': { textDecoration: 'underline' }
                                                            }}
                                                        >
                                                            https://bonik.store/{shopDetails?.shop_link}
                                                        </a>
                                                }
                                                <ContentCopyIcon
                                                    sx={{
                                                        color: '#FF6550',
                                                        '&:hover': {
                                                            cursor: 'pointer'
                                                        }
                                                    }}
                                                    onClick={() => navigator.clipboard.writeText(textAreaRef.current.innerText)} />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}>
                                                <Button
                                                    disableElevation
                                                    variant='contained'
                                                    onClick={handleClick}
                                                    sx={{
                                                        backgroundColor: '#FF6550',
                                                        marginTop: '20px',
                                                        borderRadius: "6px",
                                                        textTransform: 'none',
                                                        fontFamily: 'figtree',

                                                        '&:hover': {

                                                            backgroundColor: '#ED5E4A'
                                                        }
                                                    }}
                                                >
                                                    <VisibilityOutlinedIcon sx={{ marginRight: '10px', width: 18 }} />
                                                    View Store</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box sx={{ marginRight: '24px', minWidth: '221px' }}>
                                    <DashboardCard
                                        title='Total Orders'
                                        value={dashboardStatistics?.totalOrders?.thisMonth || '0'}
                                        percentage={dashboardStatistics?.totalOrders?.differenceInPercentage || '0'}
                                    />
                                </Box>
                                {/* <Grid item xs={12} md={3}>
                                    <DashboardCard
                                        title='Product Views'
                                        value='90'
                                        percentage='0'
                                    />
                                </Grid> */}
                                <Box item xs={12} md={3} sx={{ marginRight: '24px', minWidth: '221px' }}>
                                    <DashboardCard
                                        title='Total Sales Value'
                                        value={`${shopDetails?.currencies?.title} ${dashboardStatistics?.totalSalesValue?.thisMonth}` || '৳0'}
                                        percentage={`${dashboardStatistics?.totalSalesValue?.differenceInPercentage}` || '0'}
                                    />
                                </Box>

                            </Box>
                    }

                </Grid>
                <Grid
                    sx={{ margin: '24px 16px' }}
                    container
                >
                    <Box>
                        <DashboardProductTable sale={sale} />
                        <Button
                            sx={{
                                textTransform: 'none',
                                fontSize: '14px',
                                color: '#F7634D',
                                float: 'right',
                                fontFamily: 'figtree',
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                            onClick={() => navigate('/orders')}
                        >
                            View All Orders
                        </Button>
                    </Box>
                </Grid>

                {/* <Grid item xs={12} md={4}> */}
                {/* <Grid item xs={12} md={12}> */}

                {/* </Grid> */}
                {/* </Grid> */}
            </Grid>
        </>
    );
};

export default Dashboard;
