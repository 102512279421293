import { Button } from '@mui/material';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PixelLogo from '../../Images/pixel_logo.png';
import axios from '../InstanceAxios/InstanceAxios';
import PixelDialogue from './PixelDialogue';
import PixelFeatures from './PixelFeatures';
import PixelInstructions from './PixelInstructions';

const PluginPixel = () => {
    const [isShowDetails, setIsShowDetails] = useState(true);
    const [isOpenedDialouge, setIsOpenedDialouge] = useState(false);
    const [data, setData] = useState({});
    const navigate = useNavigate();

    const selected = {
        padding: "8px 16px",
        backgroundColor: '#021F3F',
        border: '1px solid #021F3F',
        borderRadius: "6px",
        textTransform: 'none',
        fontFamily: 'figtree',
        '&:hover': {
            border: '1px solid #021F3F',
            borderColor: '#021F3F',
            backgroundColor: '#021F3F'
        }
    };
    const notSelected = {
        padding: "8px 16px",
        backgroundColor: 'white',
        border: '1px solid #021F3F',
        borderRadius: "6px",
        color: "#021F3F",
        textTransform: 'none',
        fontFamily: 'figtree',
        '&:hover': {
            border: '1px solid #021F3F',
            backgroundColor: 'white',
            color: "#021F3F"
        }
    };

    const handleInstall = () => {
        setIsOpenedDialouge(true);
    }

    const handleUninstall = async () => {
        try {
            const { shop_id } = jwtDecode(localStorage.getItem('token'));
            const updatedData = {};
            for (let key in data) {
                updatedData[key] = null;
            }
            const res = await axios.put(`/merchants/updateFacebookPixelId/${shop_id}`, updatedData);
            getData();
        } catch (error) {
            console.log(error);
        }
    }

    const getData = async () => {
        try {
            const { shop_id } = jwtDecode(localStorage.getItem('token'));
            const res = await axios.get(`/merchants/getFacebookPixelId/${shop_id}`);
            setData(res.data);
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getData();
    }, []);


    useEffect(() => {
        if (!isOpenedDialouge) {
            getData();
        }
    }, [isOpenedDialouge]);

    return (
        <div className='single-plugin-container scroll' style={{ overflowY: 'auto', height: '70vh' }}>
            <PixelDialogue
                isOpenedDialouge={isOpenedDialouge}
                setIsOpenedDialouge={setIsOpenedDialouge}
                data={data}
            />
            <div className='single-plugin-header'>

                <div>
                    <img src={PixelLogo} height='120px' width='120px' alt="" />
                </div>
                <div className='single-plugin-header-right'>
                    <p className='single-plugin-title'> Facebook Pixel</p>
                    {Object.keys(data).length == 0 || Object.values(data)[0] === null ?
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{
                                padding: "8px 16px",
                                backgroundColor: '#FF6550',
                                borderRadius: "6px",
                                fontFamily: 'figtree',
                                textTransform: 'none',
                                '&:hover': {
                                    // borderColor: '# ED5E4A',
                                    backgroundColor: '# ED5E4A'
                                }
                            }}
                            onClick={handleInstall}
                        >
                            Install
                        </Button>
                        :
                        <span>
                            <Button
                                disableElevation
                                variant="contained"
                                sx={{
                                    padding: "8px 16px",
                                    marginRight: "16px",
                                    backgroundColor: '#FF6550',
                                    borderRadius: "6px",
                                    fontFamily: 'figtree',
                                    textTransform: 'none',
                                    '&:hover': {
                                        // borderColor: '#FF6550',
                                        backgroundColor: '#ED5E4A'
                                    }
                                }}
                                onClick={handleInstall}
                            >
                                Setting
                            </Button><Button
                                disableElevation
                                variant="outlined"
                                sx={{
                                    padding: "8px 16px",
                                    color: '#FF6550',
                                    border: '1px solid #FF6550',
                                    borderRadius: "6px",
                                    fontFamily: 'figtree',
                                    textTransform: 'none',
                                    '&:hover': {
                                        border: '1px solid #FF6550',
                                        color: '#FF6550',
                                        backgroundColor: '#E8D9D7'
                                    }
                                }}
                                onClick={handleUninstall}
                            >
                                Uninstall
                            </Button>
                        </span>}
                </div>
            </div>
            <div className='single-plugin-btn-div'>
                <Button
                    disableElevation
                    variant="contained"
                    sx={isShowDetails ? selected : notSelected}
                    onClick={() => setIsShowDetails(true)}
                >
                    Details
                </Button>
                <Button
                    disableElevation
                    variant="contained"

                    sx={isShowDetails ? { marginLeft: "24px", ...notSelected } : { marginLeft: "24px", ...selected }}
                    onClick={() => setIsShowDetails(false)}
                >
                    Instruction
                </Button>
            </div>
            <div className='single-plugin-description'>
                {isShowDetails ? <PixelFeatures /> : <PixelInstructions />}
            </div>
        </div>
    );
};

export default PluginPixel;