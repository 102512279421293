import React from 'react';

const PaperflyInstrauctions = () => {
    return (
        <>
            <ul className='single-plugin-ul'>
                <li className='single-plugin-li'>
                    Connect your Paperfly account after [registering as merchant](https://go.paperfly.com.bd/identity/register)
                </li>
                <li className='single-plugin-li'>
                    Put required information on plugin settings box
                </li>
                <li className='single-plugin-li'>
                    Enable Paperfly and compelte installation
                </li>
            </ul>
        </>
    );
};

export default PaperflyInstrauctions;