import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useContext } from 'react';
import { AuthContext } from '../../Contexts/AuthContext';

const headCells = [
    {
        id: '1',
        numeric: false,
        disablePadding: true,
        label: 'Order Id',
    },
    {
        id: '2',
        numeric: true,
        disablePadding: false,
        label: 'Date & Time',
    },
    {
        id: '3',
        numeric: true,
        disablePadding: false,
        label: 'Total Amount',
    },
    {
        id: '4',
        numeric: true,
        disablePadding: false,
        label: 'Payment Method',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } =
        props;

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sx={{ fontFamily: 'Figtree' }}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function DashboardProductTable({ sale }) {
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(4);
    const { shopDetails } = useContext(AuthContext);


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sale.length) : 0;

    return (
        <Box sx={{ width: '100%', fontFamily: 'Figtree' }}>
            <Paper elevation={0} sx={{ width: '100%', mb: 2, fontFamily: 'Figtree', }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750, fontFamily: 'Figtree' }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            rowCount={sale.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                            {sale
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((sale, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={sale.sales_id}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="sale"
                                                padding="none"
                                                sx={{ fontFamily: 'Figtree' }}
                                            >
                                                {sale.sales_id}
                                            </TableCell>
                                            <TableCell>{sale.formated_datetime}</TableCell>
                                            <TableCell>{shopDetails?.currencies?.title} {sale.total_amount}</TableCell>
                                            <TableCell>{sale.payment_method}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}