import { Button, Grid, styled, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import DarkButton from '../../components/Buttons/DarkButton/DarkButton';
import LightButton from '../../components/Buttons/LightButton/LightButton';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import OrangeButton from '../../components/Buttons/OrangeButton/OrangeButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import axios from '../InstanceAxios/InstanceAxios';
import jwt_decode from 'jwt-decode';
import SelectCampaignProducts from '../../components/SelectCampaignProducts/SelectCampaignProducts';
import SelectCampaignCategories from '../../components/SelectCampaignCategories/SelectCampaignCategories';
import { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#021F3F',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#021F3F',
        },
    },
});

const CreateCampaign = () => {
    const [isFixedAmount, setIsFixedAmount] = useState(true);
    const [selectedOfferType, setSelectedOfferType] = useState(0);
    const [image, setImage] = useState(null);
    const [open, setOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [values, setValues] = useState({
        title: "",
        description: "",
        start_date: dayjs(new Date()),
        expiry_date: dayjs(new Date()),
        amount: "",
        percentage: "",
    });

    const navigate = useNavigate();

    const handleAddCampaign = async () => {
        try {
            const { shop_id } = jwt_decode(localStorage.getItem('token'));
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('description', values.description);
            formData.append('start_date', values.start_date);
            formData.append('expiry_date', values.expiry_date);
            formData.append('offer_type_id', selectedOfferType);
            formData.append('amount', isFixedAmount ? values.amount : null);
            formData.append('percentage', !isFixedAmount ? values.percentage : null);
            formData.append('banner_template_id', '');
            formData.append('image', image);
            formData.append('shop_id', shop_id);
            if (selectedOfferType === 2) {
                formData.append('category_id', selectedData[0]);
            }
            if (selectedOfferType === 3) {
                formData.append('product_ids', selectedData.toString());
            }
            const res = await axios.post('/campaigns/addCampaign', formData);
            if (res.data.status === 'S110') {
                toast.success('Campaign created successfully', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
                navigate('/campaigns');
            } else {
                toast.error('Failed to create campaign', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (err) {
            console.log(err);
            toast.error('Failed to create campaign', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    const handleClickedProduct = (id) => {
        if (selectedData.includes(id)) {
            setSelectedData(selectedData.filter(item => item !== id));
        } else {
            setSelectedData([...selectedData, id]);
        }
    }

    const handleClickedCategory = (id) => {
        setSelectedData([id]);
    }

    const handleOfferType = (id) => {
        setSelectedOfferType(id);
        if (id !== 1) {
            if (id === 2 && categories.length === 0) {
                getCategories();
            } else if (id === 3 && products.length === 0) {
                getProducts();
            }
            setOpen(true);
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleStartDateChange = (newValue) => {
        setValues({
            ...values,
            start_date: newValue,
        });
    };

    const handleExpiryDateChange = (newValue) => {
        setValues({
            ...values,
            expiry_date: newValue,
        });
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const onSelectFile = e => {
        setImage(e.target.files[0]);
    }

    const getProducts = async () => {
        try {
            const { shop_id } = jwt_decode(localStorage.getItem('token'));
            const res = await axios.get(`/products/getProducts/${shop_id}`);
            setProducts(res.data);
        } catch (err) {
            console.log(err);
        }
    }

    const getCategories = async () => {
        try {
            const { shop_id } = jwt_decode(localStorage.getItem('token'));
            const res = await axios.get(`/categories/allCategories?shop_id=${shop_id}`);
            setCategories(res.data.Product_Categories);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        setSelectedData([]);
    }, [selectedOfferType]);

    useEffect(() => {
        if (values.title
            && values.start_date
            && values.expiry_date
            && image
            && selectedOfferType
        ) {
            if (isFixedAmount) {
                if (values.amount) {
                    setIsDisabled(false);
                } else {
                    setIsDisabled(true);
                }
            } else {
                if (values.percentage) {
                    setIsDisabled(false);
                } else {
                    setIsDisabled(true);
                }
            }
            if (selectedOfferType !== 1) {
                if (selectedData.length > 0) {
                    setIsDisabled(false);
                } else {
                    setIsDisabled(true);
                }
            }
        } else {
            setIsDisabled(true);
        }
    }, [
        values.title,
        values.amount,
        values.percentage,
        values.start_date,
        values.expiry_date,
        image,
        selectedOfferType,
    ]);



    return (
        <Grid
            container
            className='scroll'
            spacing={4}
            sx={{
                padding: '24px',
                overflowY: 'auto',
                height: '88vh'
            }}
        >
            <ToastContainer
                autoClose={5000}
                closeOnClick />
            <Grid
                item
                xs={12}
                md={6}
            >
                <Box
                    sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '24px',
                        textAlign: 'left',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '22px',
                            color: '#021F3F',
                            mb: '8px',
                            fontFamily: 'figtree',
                        }}
                    >
                        About Campaign
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: '#95989A',
                            margin: "16px 0",
                            fontFamily: 'figtree',
                        }}
                    >
                        Discount Type
                    </Typography>
                    <Box
                        sx={{
                            mb: '6px',
                        }}
                    >
                        {
                            isFixedAmount ?
                                <>
                                    <DarkButton
                                        title="Fixed Amount"
                                        isChip={true}
                                        sx={{
                                            padding: '6px 16px',
                                            fontSize: '14px',
                                            marginRight: '24px',
                                            fontFamily: 'figtree',
                                        }}
                                    />
                                    <LightButton
                                        title="Percentage"
                                        isChip={true}
                                        sx={{
                                            padding: '6px 16px',
                                            fontSize: '14px',
                                            fontFamily: 'figtree',
                                        }}
                                        handleOnClick={() => setIsFixedAmount(false)}
                                    />
                                </>
                                :
                                <>
                                    <LightButton
                                        title="Fixed Amount"
                                        isChip={true}
                                        sx={{
                                            padding: '6px 16px',
                                            fontSize: '14px',
                                            marginRight: '24px',
                                            fontFamily: 'figtree',
                                        }}
                                        handleOnClick={() => setIsFixedAmount(true)}
                                    />
                                    <DarkButton
                                        title="Percentage"
                                        isChip={true}
                                        sx={{
                                            padding: '6px 16px',
                                            fontSize: '14px',
                                            fontFamily: 'figtree',
                                        }}
                                    />
                                </>
                        }
                    </Box>
                    <CssTextField
                        sx={{ margin: "10px 0", fontFamily: 'figtree' }}
                        onChange={(e) => handleChange(e)}
                        value={values.title}
                        name="title"
                        id="outlined-basic"
                        label="Campaign tittle*"
                        placeholder='Enter Campaign tittle* '
                        variant="outlined"
                        fullWidth />
                    <CssTextField
                        sx={{ fontFamily: 'figtree', margin: "10px 0" }}
                        onChange={(e) => handleChange(e)}
                        value={isFixedAmount ? values.amount : values.percentage}
                        name={isFixedAmount ? "amount" : "percentage"}
                        id="outlined-basic"
                        label={isFixedAmount ? "Fixed Amount*" : "Percentage*"}
                        placeholder={isFixedAmount ? "Enter Fixed Amount*" : "Enter Percentage*"}
                        variant="outlined"
                        fullWidth />
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                    >
                        <DateTimePicker
                            label="Set Start Date* "
                            value={values.start_date}
                            onChange={handleStartDateChange}
                            renderInput={(params) => <CssTextField {...params} sx={{ fontFamily: 'figtree', margin: "10px 0", width: "100%" }} />}
                        />
                        <DateTimePicker
                            label="Set Expiry Date* "
                            value={values.expiry_date}
                            onChange={handleExpiryDateChange}
                            renderInput={(params) => <CssTextField {...params} sx={{ fontFamily: 'figtree', margin: "10px 0", width: "100%" }} />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box
                    sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '24px',
                        margin: '24px 0',
                        textAlign: 'left',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '22px',
                            color: '#021F3F',
                            mb: '24px',
                            fontFamily: 'figtree',
                        }}
                    >
                        Offer Valid for
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {
                            selectedOfferType === 1 ?
                                <DarkButton
                                    title="All Products"
                                    isChip={true}
                                    sx={{

                                        fontSize: '16px',
                                        fontFamily: 'figtree',
                                        height: '80px',
                                        marginRight: '16px',
                                        flexGrow: '2'
                                    }}
                                    handleOnClick={() => handleOfferType(1)}
                                />
                                :
                                <LightButton
                                    title="All Products"
                                    isChip={true}
                                    sx={{

                                        fontSize: '16px',
                                        height: '80px',
                                        marginRight: '16px',
                                        fontFamily: 'figtree',
                                        flexGrow: '2'

                                    }}
                                    handleOnClick={() => handleOfferType(1)}
                                />
                        }
                        {
                            selectedOfferType === 2 ?
                                <DarkButton
                                    title="Specific Category"
                                    isChip={true}
                                    sx={{

                                        fontSize: '16px',
                                        height: '80px',
                                        fontFamily: 'figtree',
                                        marginRight: '16px',
                                        flexGrow: '2'
                                    }}
                                    handleOnClick={() => handleOfferType(2)}
                                />
                                :
                                <LightButton
                                    title="Specific Category"
                                    isChip={true}
                                    sx={{
                                        fontSize: '16px',
                                        height: '80px',
                                        fontFamily: 'figtree',
                                        marginRight: '16px',
                                        flexGrow: '2'
                                    }}
                                    handleOnClick={() => handleOfferType(2)}
                                />
                        }
                        {
                            selectedOfferType === 3 ?
                                <DarkButton
                                    title="Specific Products"
                                    isChip={true}
                                    sx={{
                                        fontSize: '16px',
                                        fontFamily: 'figtree',
                                        height: '80px',
                                        flexGrow: '2'
                                    }}
                                    handleOnClick={() => handleOfferType(3)}
                                />
                                :
                                <LightButton
                                    title="Specific Products"
                                    isChip={true}
                                    sx={{


                                        fontSize: '16px',
                                        fontFamily: 'figtree',
                                        height: '80px',
                                        flexGrow: '2'
                                    }}
                                    handleOnClick={() => handleOfferType(3)}
                                />
                        }
                    </Box>
                    <Box
                        sx={{
                            fontSize: '22px',
                            color: '#021F3F',
                            mt: '24px',
                        }}
                    >
                        {
                            selectedOfferType === 1 ?
                                <Typography sx={{ fontFamily: 'figtree', }}>
                                    All Products Added
                                </Typography>
                                :
                                selectedOfferType === 2 ?
                                    <Typography sx={{ fontFamily: 'figtree', }}>
                                        {
                                            `${selectedData.length} Category(s) Added`

                                        }
                                    </Typography>
                                    :
                                    selectedOfferType === 3 ?
                                        <Typography sx={{ fontFamily: 'figtree', }}>
                                            {
                                                `${selectedData.length} Product(s) Added`
                                            }
                                        </Typography>
                                        :
                                        <></>
                        }
                    </Box>
                    <Dialog
                        fullWidth
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle sx={{ fontFamily: 'figtree', }}>
                            {
                                selectedOfferType === 2 ?
                                    "Select Categories"
                                    :
                                    "Select Products"
                            }
                        </DialogTitle>
                        <DialogContent className='scroll' sx={{ paddingX: '0px', height: '50%', fontFamily: 'figtree', }}>
                            <Box>
                                {
                                    selectedOfferType === 2 ?
                                        <SelectCampaignCategories
                                            categories={categories}
                                            selectedCategories={selectedData}
                                            handleClickedCategory={handleClickedCategory}
                                            onClose={handleClose}
                                        />
                                        :
                                        <SelectCampaignProducts
                                            products={products}
                                            selectedProducts={selectedData}
                                            handleClickedProduct={handleClickedProduct}
                                            onClose={handleClose}
                                        />
                                }
                            </Box>
                        </DialogContent>
                    </Dialog>
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
            >
                <Box
                    sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '24px',
                        textAlign: 'left',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '22px',
                            fontFamily: 'figtree',
                            color: '#021F3F',
                            mb: '24px'
                        }}
                    >
                        Campaign Templates
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {image ?
                            <Box>
                                <div>
                                    {
                                        typeof image == 'string' ?
                                            <img src={image} width='380px' height='196px' style={{ borderRadius: '4px', marginRight: '24px' }} alt='upload' />
                                            :
                                            <img src={URL.createObjectURL(image)} height='196px' width='380px' style={{ borderRadius: '4px', marginRight: '24px' }} alt='upload' />
                                    }
                                </div>
                            </Box>
                            : <div>
                                <label className='add-campaign-addImage-div'>
                                    <UploadFileOutlinedIcon sx={{ width: '20px', height: '23px', marginBottom: '5px' }} />
                                    <p style={{ fontFamily: 'figtree', }}>Tap to upload a Campaign Template <br /> (1556 x 400) pixel</p>
                                    <br />
                                    <input
                                        type="file"
                                        name="image"
                                        onChange={(e) => onSelectFile(e)}
                                        accept="image/png , image/jpeg, image/webp"
                                    />
                                </label>
                            </div>
                        }
                    </Box>
                    <Button
                        disableElevation
                        variant="outlined"
                        sx={{
                            textTransform: 'none',
                            fontFamily: 'figtree',
                            color: "#FF6550",
                            borderColor: "#FF6550",
                            '&:hover': {
                                border: '1px solid #FF6550',
                                color: '#FF6550',
                                backgroundColor: '#E8D9D7'
                            },
                            borderRadius: "6px",
                            margin: "10px 0",
                            width: "382px",
                        }}
                        onClick={() => setImage(null)}
                    >
                        <CachedIcon fontSize='small' sx={{ marginRight: "10px" }} /> Change Template
                    </Button>
                </Box>
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '48px 32px',
                    width: '100%',
                }}
            >
                <Button
                    sx={{
                        color: '#021F3F',
                        fontSize: '16px',
                        fontFamily: 'figtree',
                    }}
                    onClick={() => navigate(-1)}
                >
                    <KeyboardArrowLeftIcon />
                    Back
                </Button>
                <OrangeButton
                    title="Add Campaign"
                    icon={<AddIcon sx={{ marginRight: '10px', width: 12 }} />}
                    isDisabled={isDisabled}
                    sx={{
                        fontSize: '14px',
                        fontWeight: '500',
                        fontFamily: 'figtree',
                    }}
                    handleOnClick={() => handleAddCampaign()}
                />
            </Box>
        </Grid >
    );
};

export default CreateCampaign;