import React, { useEffect, useState } from 'react';
import OtpComponent from '../Shared/OtpComponent/OtpComponent';

const MobileVerification = () => {
    const [phone, setPhone] = useState("");

    useEffect(() => {
        if (localStorage.getItem("mobileVerificationPhone")) {
            setPhone(localStorage.getItem("mobileVerificationPhone"));
        }
    }, []);

    return (
        <div>
            <OtpComponent
                title="Mobile Verification"
                phone={phone}
                link={`/createStore`}
            />
        </div>
    );
};

export default MobileVerification;