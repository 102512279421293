import React from 'react';
import './PaperflySetup.css';
import { Autocomplete, Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, styled, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from '../../Pages/InstanceAxios/InstanceAxios';
import AddIcon from '@mui/icons-material/Add';
import jwtDecode from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#021F3F',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#021F3F',
        },
    },
});


const PaperflySetup = () => {
    const [checkBox, setCheckBox] = useState("");
    const [formData, setFormData] = useState({
        account_name: "",
        account_number: "",
        bank_name: "",
        bank_branch: "",
        routing_number: "",
        payment_mode: "bkash"
    });
    const [isDisabled, setIsDisabled] = useState(true);
    const [isOnlyShow, setIsOnlyShow] = useState(false);

    const handleChange = (e) => {
        const oldData = { ...formData };
        oldData[e.target.name] = e.target.value;
        setFormData({ ...oldData });
    }

    const handleSubmit = async () => {
        const decoded = jwtDecode(localStorage.getItem('token'));
        const url = `/paperfly/signup`;
        setIsDisabled(true);
        try {
            const res = await axios.post(url, { ...formData, shop_id: decoded.shop_id });
            if (res.data.status === 'S127') {
                const url = `/delivery/updateDeliveryPartner/${decoded.shop_id}`;
                const response = await axios.put(url, {
                    delivery_partner_id: 'd1'
                });
                toast.success('Paperfly payment method added successfully', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.error('Failed to add paperfly payment method', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
            toast.error('Failed to add paperfly payment method', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            setFormData({
                account_name: "",
                account_number: "",
                bank_name: "",
                bank_branch: "",
                routing_number: "",
                payment_mode: "bkash"
            });
            setCheckBox("");
        }
    }

    const getPaperflyDetails = async () => {
        try {
            const decoded = jwtDecode(localStorage.getItem('token'));
            const url = `/paperfly/getPaperflyDetails/${decoded.shop_id}`;
            const response = await axios.get(url);
            if (response.data) {
                setIsOnlyShow(true);
                setFormData(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (checkBox.length) {
            if (formData.payment_mode === 'beftn') {
                if (
                    formData.account_name.length && formData.account_number.length
                    && formData.bank_name.length && formData.bank_branch.length &&
                    formData.routing_number
                ) {
                    setIsDisabled(false);
                } else {
                    setIsDisabled(true);
                }
            } else {
                if (formData.account_name.length && formData.account_number.length) {
                    setIsDisabled(false);
                } else {
                    setIsDisabled(true);
                }
            }
        } else {
            setIsDisabled(true);
        }
    }, [
        formData.account_name,
        formData.account_number,
        formData.bank_name,
        formData.bank_branch,
        formData.routing_number,
        formData.payment_mode,
        checkBox
    ]);

    useEffect(() => {
        getPaperflyDetails();
    }, []);


    return (
        <>
            <div className='paperfly-setup-container'>
                <div className='paperfly-setup-div'>
                    <ToastContainer
                        autoClose={5000}
                        closeOnClick />
                    <div className="pickup-adrs-form">
                        <p className='delivery-title'>Payment Method</p>
                        {
                            isOnlyShow &&
                            <p style={{ color: "#95989A" }}>
                                {
                                    formData.payment_mode === 'bkash' ?
                                        `bKash (One hour payment applicable)`
                                        : formData.payment_mode === 'rocket' ?
                                            'Rocket' : 'Bank Transfer'
                                }
                            </p>
                        }
                        {!isOnlyShow &&
                            <FormControl>
                                <FormLabel
                                    id="demo-radio-buttons-group-label"
                                >
                                    Select a Payment Method:
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="payment_mode"
                                    value={formData.payment_mode}
                                    onChange={(e) => handleChange(e)}
                                >
                                    <FormControlLabel value="bkash" control={<Radio />} label="bKash (One hour payment applicable)" />
                                    <FormControlLabel value="rocket" control={<Radio />} label="Rocket" />
                                    <FormControlLabel value="beftn" control={<Radio />} label="Bank Transfer" />
                                </RadioGroup>
                            </FormControl>
                        }
                        <CssTextField
                            sx={{ margin: "10px 0" }}
                            onChange={(e) => handleChange(e)}
                            value={formData.account_name}
                            disabled={isOnlyShow}
                            name="account_name"
                            id="outlined-basic"
                            label="Account Name"
                            placeholder='Enter Account Name'
                            variant="outlined"
                            fullWidth />
                        <CssTextField
                            sx={{ margin: "10px 0" }}
                            onChange={(e) => handleChange(e)}
                            value={formData.account_number}
                            disabled={isOnlyShow}
                            name="account_number"
                            id="outlined-basic"
                            label="Account Number"
                            placeholder='Enter Account Number'
                            variant="outlined"
                            fullWidth />
                        {
                            formData.payment_mode === 'beftn' ? <>
                                <CssTextField
                                    sx={{ margin: "10px 0" }}
                                    onChange={(e) => handleChange(e)}
                                    value={formData.bank_name}
                                    name="bank_name"
                                    id="outlined-basic"
                                    label="Bank Name"
                                    placeholder='Enter Bank Name'
                                    variant="outlined"
                                    fullWidth />
                                <CssTextField
                                    sx={{ margin: "10px 0" }}
                                    onChange={(e) => handleChange(e)}
                                    value={formData.bank_branch}
                                    name="bank_branch"
                                    id="outlined-basic"
                                    label="Branch"
                                    placeholder='Enter Branch'
                                    variant="outlined"
                                    fullWidth />
                                <CssTextField
                                    sx={{ margin: "10px 0" }}
                                    onChange={(e) => handleChange(e)}
                                    value={formData.routing_number}
                                    name="routing_number"
                                    id="outlined-basic"
                                    label="Routing Number"
                                    placeholder='Enter Routing Number'
                                    variant="outlined"
                                    fullWidth />
                            </> : <></>
                        }
                        {!isOnlyShow ? <Grid container alignItems="center">
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={checkBox}
                                    onChange={(e) => setCheckBox(e.target.value)}
                                >
                                    <FormControlLabel
                                        sx={{ padding: "0", margin: "0" }}
                                        control={<Radio
                                            value="checked"
                                            size='small'
                                        />}
                                    />
                                </RadioGroup>
                            </FormControl>
                            <Typography variant="body1" sx={{ fontSize: "14px", fontFamily: "Figtree", margin: "0", textAlign: "center", display: "inline", padding: "0" }}>
                                I Agree to the terms and conditions
                            </Typography>
                        </Grid> :
                            <p style={{ color: "#ED1C24", fontSize: "12px", margin: "0" }}>
                                Those Information cannot be changed
                            </p>
                        }
                    </div>
                </div>
            </div>
            {
                !isOnlyShow &&
                <div className='paperfly-setup-bottom'>
                    <div className='place-right'>
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{
                                backgroundColor: '#FF6550',
                                textTransform: 'none',
                                fontFamily: 'figtree',
                                '&:hover': {
                                    // borderColor: '# ED5E4A',
                                    backgroundColor: '# ED5E4A'
                                }
                            }}
                            onClick={handleSubmit}
                            disabled={isDisabled}
                        >
                            add
                        </Button>
                    </div>
                </div>
            }
        </>

    );
};

export default PaperflySetup;