import React from 'react';

const SslFeatures = () => {
    return (
        <>
            <p>
                SSLCOMMERZ is the strongest payment gateway in Bangladesh opening doors for merchants to receive payments on the internet via their online stores. Their customers will be able to buy products online using their credit cards as well as bank accounts and MFS wallets. This plugin allows you to accept payments on your Bonik-powered store from customers using Visa Cards, Master cards, American Express and MFS like bKash, Nagad, Upay etc.
            </p>
            <p className='single-plugin-details-title'>
                Key Features -
            </p>
            <ul className='single-plugin-ul'>
                <li className='single-plugin-li'>
                    <span className='single-plugin-bold'>Instant Activation</span>
                    <br />
                    Quick activation to accept transactions. Completely online on-boarding process with minimal documentation.
                </li>
                <li className='single-plugin-li'>
                    <span className='single-plugin-bold'>Easy Integration</span>
                    <br />
                    Plugins available for all major platforms and languages to integrate with SSLCOMMERZ in less than an hour.
                </li>
                <li className='single-plugin-li'>
                    <span className='single-plugin-bold'>PSO Licensed</span>
                    <br />
                    One out of only 3 organizations to be prestigiously awarded with the PSO license by Bangladesh Bank.
                </li>
                <li className='single-plugin-li'>
                    <span className='single-plugin-bold'>30+ Payment Schemes</span>
                    <br />
                    Offer your customers the luxury of all payment modes including Credit/Debit cards, Netbanking, Mobile Banking, E-Wallets etc.
                </li>
                <li className='single-plugin-li'>
                    <span className='single-plugin-bold'>PCI DSS Compliant</span>
                    <br />
                    PCI DSS Level 1 compliant and highly secured solution which eliminates your burden of regulatory compliance.
                </li>
            </ul>
        </>
    );
};

export default SslFeatures;