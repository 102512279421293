import { Box } from '@mui/system';
import React, { useState } from 'react';
import DarkButton from '../../components/Buttons/DarkButton/DarkButton';
import LightButton from '../../components/Buttons/LightButton/LightButton';
import OrangeButton from '../../components/Buttons/OrangeButton/OrangeButton';
import AddIcon from '@mui/icons-material/Add';
import AddImage from '../../Images/add_image.png';
import { FormControl, InputAdornment, styled, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from 'react';
import axios from '../InstanceAxios/InstanceAxios';
import jwt_decode from 'jwt-decode';
import CampaignTable from '../../components/CampaignTable/CampaignTable';
import { toast, ToastContainer } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#021F3F',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#021F3F',
        },
    },
});

const Campaign = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataUpdating, setIsDataUpdating] = useState(false);
    const [isActiveSelected, setIsActiveSelected] = useState(true);
    const [filteredCampaigns, setFilteredCampaigns] = useState([]);
    const navigate = useNavigate();

    const getAllCampaigns = async () => {
        try {
            setIsLoading(true);
            const { shop_id } = jwt_decode(localStorage.getItem('token'));
            const response = await axios.get(`/campaigns/allCampaigns?shop_id=${shop_id}`);
            setCampaigns(response.data.Campaigns);
        }
        catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    }

    const deactivateCampaign = async (campaign_id) => {
        try {
            setIsDataUpdating(true);
            const res = await axios.patch(`/campaigns/deactivateCampaign/${campaign_id}`);
            if (res.data.status === 'S114') {
                toast.success('Campaign deactivated Successfully', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
                setCampaigns([]);
                getAllCampaigns();
            } else {
                toast.error('Failed to deactivate campaign', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (err) {
            console.log(err);
            toast.error('Failed to deactivate campaign', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            setIsDataUpdating(false);
        }
    }

    const deleteCampaign = async (campaign_id) => {
        try {
            setIsDataUpdating(true);
            const res = await axios.delete(`/campaigns/deleteCampaign/${campaign_id}`);
            if (res.data.status === 'S144') {
                toast.success('Campaign deleted Successfully', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
                setCampaigns([]);
                getAllCampaigns();
            } else {
                toast.error('Failed to delete campaign', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (err) {
            console.log(err);
            toast.error('Failed to delete campaign', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            setIsDataUpdating(false);
        }
    }

    useEffect(() => {
        getAllCampaigns();
    }, []);

    useEffect(() => {
        if (isActiveSelected) {
            setFilteredCampaigns(campaigns.filter(campaign => campaign.status === 'active'));
        } else {
            setFilteredCampaigns(campaigns.filter(campaign => campaign.status === 'inactive'));
        }
    }, [campaigns, isActiveSelected]);



    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 24px',
            }}
        >
            <ToastContainer
                autoClose={5000}
                closeOnClick />
            {
                isLoading || isDataUpdating ?
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '60vh',
                        }}
                    >
                        <CircularProgress sx={{ color: '#FF6550' }} />
                    </Box>
                    :
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginBottom: '24px',
                            }}
                        >
                            {
                                isActiveSelected ?
                                    <>
                                        <DarkButton
                                            title={`Active(${filteredCampaigns.length})`}
                                            isChip={true}
                                            sx={{
                                                marginRight: '24px',
                                            }}
                                        />
                                        <LightButton
                                            title={`Inactive(${campaigns.length - filteredCampaigns.length})`}
                                            isChip={true}
                                            handleOnClick={() => setIsActiveSelected(false)}
                                        />
                                    </>
                                    :
                                    <>
                                        <LightButton
                                            title={`Active(${campaigns.length - filteredCampaigns.length})`}
                                            isChip={true}
                                            sx={{
                                                marginRight: '24px',
                                            }}
                                            handleOnClick={() => setIsActiveSelected(true)}
                                        />
                                        <DarkButton
                                            title={`Inactive(${filteredCampaigns.length})`}
                                            isChip={true}
                                        />
                                    </>
                            }
                        </Box>
                        {/* white area starts here */}
                        <Box
                            sx={{
                                backgroundColor: '#FFFFFF',
                                padding: '24px',
                                textAlign: 'left',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <FormControl>
                                    <CssTextField
                                        placeholder="Search"
                                        variant="outlined"
                                        aria-describedby="number-text"
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment
                                                    position="start"
                                                >
                                                    <SearchIcon />
                                                </InputAdornment>,
                                        }}
                                        inputProps={{
                                            style: {
                                                padding: '10px 14px',
                                                borderRadius: '8px',
                                            }
                                        }}
                                    />
                                </FormControl>

                                {
                                    isActiveSelected &&
                                    <OrangeButton
                                        title="Add New Campaign"
                                        icon={<AddIcon sx={{ marginRight: '10px', width: 18 }} />}
                                        sx={{
                                            fontSize: '14px',
                                            fontFamily: 'figtree',
                                        }}
                                        handleOnClick={() => navigate('/campaigns/create')}
                                    />
                                }
                            </Box>
                            {/* content goes here */}
                            {
                                filteredCampaigns.length > 0 ?
                                    <Box
                                        sx={{
                                            marginTop: '24px',
                                        }}
                                    >
                                        <CampaignTable
                                            campaigns={filteredCampaigns}
                                            deactivateCampaign={deactivateCampaign}
                                            deleteCampaign={deleteCampaign}
                                        />
                                    </Box>
                                    :
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '60vh',
                                        }}
                                    >
                                        <Box>
                                            <img
                                                src={AddImage}
                                                alt=""
                                                width="230px"
                                                height="207px"
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: '18px',
                                                    color: "#95989A",
                                                    marginTop: '16px',
                                                    fontFamily: 'figtree',
                                                }}
                                            >
                                                You have no active campaign now
                                            </Typography>
                                        </Box>
                                    </Box>
                            }
                        </Box>

                    </>
            }
        </Box>
    );
};

export default Campaign;
