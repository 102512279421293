import React, { useContext } from 'react';
import { useGoogleLogin } from 'react-google-login';
import axios from '../../Pages/InstanceAxios/InstanceAxios';

// refresh token
import { refreshTokenSetup } from '../../utilities/refreshToken';
import GoogleIcon from '../../Images/google.svg';
import { AuthContext } from '../../Contexts/AuthContext';

const clientId = '93192965205-rdq2ht42j8b346qemt5g1rp4sq2qikqc.apps.googleusercontent.com';

function LoginHooks() {
  const { googleLogin } = useContext(AuthContext);
  const onSuccess = async (res) => {
    refreshTokenSetup(res);
    googleLogin(res.tokenObj.id_token)
  };

  const onFailure = (res) => {
    console.log('Login failed: res:', res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: 'offline',
    // responseType: 'code',
    // prompt: 'consent',
  });

  return (
    <div className="google-login">
      <button onClick={signIn} className="button">
        <img src={GoogleIcon} alt="google login" className="icon"></img>
        <span className="buttonText">Sign Up with Google</span>
      </button>
    </div>
  );
}

export default LoginHooks;
