import React from 'react';

const PixelInstructions = () => {
    return (
        <>
            <p>
                In order to start capturing audience data through Pixel, you need to setup pixels for each of your advertising platforms. In order to find your Pixel ID for Facebook, follow the instructions below:
            </p>
            <ul className='single-plugin-ul'>
                <li className='single-plugin-li'>
                    To start using Facebook Pixel, log in to your Facebook Ads Manager/Business Manager
                </li>
                <li className='single-plugin-li'>
                    Once logged in, click on
                    <span className='single-plugin-bold'> All Tools </span>
                    located in the left navigation bar
                </li>
                <li className='single-plugin-li'>
                    Select  <span className='single-plugin-bold'> Events Manager </span>
                </li>
                <li className='single-plugin-li'>
                    Copy the
                    <span className='single-plugin-bold'> Pixel ID </span>
                    from the dashboard
                </li>
                <li className='single-plugin-li'>
                    Now, Install the Facebook Pixel plugin on Bonik
                </li>
                <li className='single-plugin-li'>
                    Click on Settings and it’ll ask for Facebook Pixel ID
                </li>
                <li className='single-plugin-li'>
                    Paste the Pixel ID here to complete the installation
                </li>
            </ul>
        </>
    );
};

export default PixelInstructions;