import React, { useState } from 'react';
import { Box, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import { useEffect } from 'react';


const SelectProduct = ({ products, selectedProduct, handleClickProduct }) => {
    const [checked, setChecked] = useState([]);

    const handleClick = (index) => {
        let temp = [...checked];
        temp[index] = !temp[index];
        setChecked(temp);
        handleClickProduct(index);
    }

    useEffect(() => {
        setChecked(products.map((product) =>
            selectedProduct === product.product_id ? true : false
        ));
    }, [products, selectedProduct]);

    return (
        <div>
            {
                products.length ?
                    products.map((product, index) =>
                        <Box key={index}>
                            <Box
                                onClick={() => handleClick(index)}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    paddingX: '24px',
                                    marginY: '16px'
                                }}
                            >
                                <Box sx={{ display: 'flex' }}>
                                    <img
                                        width='48px'
                                        style={{ marginRight: '16px' }}
                                        src={product.product_images[0]?.image_url}
                                        alt='product img'
                                    />
                                    <Box>
                                        <Typography
                                            sx={{ fontSize: '16px', color: '#021F3F' }}
                                        >
                                            {product.product_name}
                                        </Typography>
                                        <Typography
                                            sx={{ fontSize: '12px', color: '#95989A' }
                                            }>In Stock: {product.stock[0].number_of_stocks} Items
                                        </Typography>
                                    </Box>
                                </Box>
                                {
                                    products.length ?
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="payment_mode"
                                            value={checked[index] || ''}
                                            onChange={(e) => handleClick(index)}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                            />
                                        </RadioGroup>
                                        :
                                        <></>
                                }
                            </Box>
                            {
                                products.length - 1 !== index ?
                                    <hr style={{ border: '2px solid #F2F2F2', margin: "0 20px" }} />
                                    :
                                    <></>
                            }
                        </Box>
                    )
                    :
                    <></>
            }
        </div>
    );
};

export default SelectProduct;