import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, MenuItem, Select, styled, TablePagination } from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from '../../Contexts/AuthContext';


const CssInputLabel = styled(Select)({

    '& label.Mui-focused': {
        color: '#021F3F',
    },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#021F3F',
    },

});

const headCells = [
    {
        id: '1',
        disablePadding: true,
        label: 'Campaign Id',
    },
    {
        id: '2',
        disablePadding: false,
        label: 'Start Date',
    },
    {
        id: '3',
        disablePadding: false,
        label: 'Valid Till',
    },
    {
        id: '4',
        disablePadding: false,
        label: 'Campaign Title',
    },
    {
        id: '5',
        disablePadding: false,
        label: 'Offer',
    },
    {
        id: '6',
        disablePadding: false,
        label: 'Status',
    }
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } =
        props;

    return (
        <TableHead
            sx={{
                backgroundColor: '#F8F8F8',
            }}
        >
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sx={{ fontFamily: 'figtree' }}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function CampaignTable({ campaigns, deactivateCampaign, deleteCampaign }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { shopDetails } = useContext(AuthContext);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - campaigns.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'small'}
                    >
                        <EnhancedTableHead
                            rowCount={campaigns.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                            {campaigns
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((campaign, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={campaign.id}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="sale"
                                                padding="none"
                                            >
                                                {campaign.id}
                                            </TableCell>
                                            <TableCell>
                                                {new Date(campaign.start_date).toLocaleString()}
                                            </TableCell>
                                            <TableCell>
                                                {new Date(campaign.expiry_date).toLocaleString()}
                                            </TableCell>
                                            <TableCell>{campaign.title}</TableCell>
                                            <TableCell>
                                                {
                                                    `${campaign.amount ?
                                                        `${shopDetails?.currencies?.title} ${campaign.amount} off on` :
                                                        `${campaign.percentage}% off on`
                                                    }
                                                    ${campaign.products.length} products`
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    campaign.status === 'active' ?
                                                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                            <CssInputLabel
                                                                value={campaign.status}
                                                                onChange={() => deactivateCampaign(campaign.id)}
                                                                displayEmpty
                                                                sx={{
                                                                    '& .MuiSelect-outlined': {
                                                                        padding: '5px',
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem
                                                                    value='active'
                                                                >
                                                                    Active
                                                                </MenuItem>
                                                                <MenuItem value='inactive'>Inactive</MenuItem>
                                                            </CssInputLabel>
                                                        </FormControl>
                                                        :
                                                        <>Inactive</>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={campaigns.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}