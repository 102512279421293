import React from 'react';

const SslInstructions = () => {
    return (
        <>
            <ul className='single-plugin-ul'>
                <li className='single-plugin-li'>
                    Login into Stripe & go to Dashboard.
                </li>
                <li className='single-plugin-li'>
                    Click on <span className='single-plugin-bold'>'Developers'</span> button on the top-right corner.
                </li>
                <li className='single-plugin-li'>
                    Click on <span className='single-plugin-bold'>'API Keys'</span> from the left panel.
                    <br />
                    1. Publishable key & Secret key are present there.
                </li>
                <li className='single-plugin-li'>
                    Go back and Click on <span className='single-plugin-bold'>'Webhooks'</span>
                    <br />
                    1. Click <span className='single-plugin-bold'>'Add Endpoint' </span>
                    & enter <span className='single-plugin-bold'>
                        "https://api.bonikapp.com/stripe/webhook"
                    </span> as Endpoint URL
                </li>
                <li className='single-plugin-li'>
                    Scroll down & click <span className='single-plugin-bold'>'Select events'</span> button
                </li>
                <li className='single-plugin-li'>
                    Search <span className='single-plugin-bold'>'payment_intent'</span>
                    and select all the events which has the prefix <span className='single-plugin-bold'>'payment_intent'</span> & 
                    click <span className='single-plugin-bold'>'Add Events'</span>
                </li>
                <li className='single-plugin-li'>
                    Finally Click <span className='single-plugin-bold'>'Add Endpoint'</span>
                </li>
            </ul>
        </>
    );
};

export default SslInstructions;