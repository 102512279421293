import React, { useEffect, useState } from 'react';
import OtpComponent from '../Shared/OtpComponent/OtpComponent';

const ForgotPincodeOtp = () => {
    const [phone, setPhone] = useState("");

    useEffect(() => {
        if (localStorage.getItem("forgetPinPhone")) {
            setPhone(localStorage.getItem("forgetPinPhone"));
        }
    }, []);

    return (
        <div>
            <OtpComponent title="Forgot pincode?" phone={phone} link="/resetPincode" />
        </div>
    );
};

export default ForgotPincodeOtp;