import jwtDecode from 'jwt-decode';
import React from 'react';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../Contexts/AuthContext';

const PrivateRoute = ({ children }) => {
    const { token, isLoading } = useContext(AuthContext);
    const location = useLocation();

    if (isLoading) {
        return;
    }

    if (token) {
        if (jwtDecode(token)?.shop_id == null) {
            return <Navigate to="/createStore" state={{ from: location }} replace></Navigate>
        }
        return children;
    }
    
    return <Navigate to="/signup" state={{ from: location }} replace></Navigate>
};

export default PrivateRoute;